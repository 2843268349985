import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import {
  ArrowDropDown,
  ArrowDropUp,
  CallSplit,
  Check,
  Language,
} from "@mui/icons-material";
import { ButtonBase, Popover, Stack, Typography } from "@mui/material";
import styled, { css } from "styled-components";
import { SkeletonIfLoading } from "../shared/components/Skeleton";
import {
  SWITCHER_ALL_SCENARIOS_DESCRIPTION,
  SWITCHER_ALL_SCENARIOS_TITLE,
  SWITCHER_BUTTON_TEST_ID,
  SWITCHER_GLOBAL_WORKSPACE_DESCRIPTION,
  SWITCHER_GLOBAL_WORKSPACE_TITLE,
  SWITCHER_LAST_SCENARIO_ID_STORAGE_KEY,
  SWITCHER_OPTION_TEST_ID,
  SWITCHER_SCENARIO_CURRENTLY_EDITING,
  SWITCHER_SCENARIO_NOT_EDITING,
} from "./constants";
import {
  action,
  common,
  paperBorder,
  text,
  tooltipDelayProps,
} from "../shared/theme";
import ScenarioIcon from "../shared/components/icons/Scenario-Icon";
import { ellipsis } from "../shared/utils/styles";
import TooltipOrFragment from "../shared/components/Tooltip-Or-Fragment";
import { CIP_PATH, SCENARIOS_PATH } from "../shared/constants/routes";
import useScenario from "../shared/api/use-scenario-rtk";
import usePopover from "../shared/hooks/use-popover";

const backgroundColor = "rgba(255, 255, 255, 0.2)";
const hoverBackgroundColor = "rgba(255, 255, 255, .35)";

const switcherButtonSpacing = 1.5;

const widthAndMaxWidth = css`
  max-width: 500px;
  width: 100%;
`;

const StyledSkeletonIfLoading = styled(SkeletonIfLoading)`
  ${widthAndMaxWidth}
`;

const SwitcherButton = styled(Stack).attrs(() => ({
  alignItems: "center",
  component: ButtonBase,
  direction: "row",
  justifyContent: "space-between",
  spacing: switcherButtonSpacing,
}))`
  ${widthAndMaxWidth}
  background-color: ${backgroundColor};
  border-radius: 4px;
  color: ${common.white};
  height: 40px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 4px;

  &:hover {
    background-color: ${hoverBackgroundColor};
  }

  ${({ $isPopoverOpen }) =>
    $isPopoverOpen && `background-color: ${hoverBackgroundColor};`}
`;

const iconSize = 24;

const SwitcherButtonIconAndTextStack = styled(Stack).attrs(() => ({
  alignItems: "center",
  direction: "row",
  spacing: switcherButtonSpacing,
}))`
  ${ellipsis}
  max-width: calc(100% - ${iconSize + switcherButtonSpacing * 8}px);
`;

const SwitcherButtonText = styled(Typography).attrs(() => ({
  variant: "h6",
}))`
  ${ellipsis}
`;

const OptionTitle = styled(Typography).attrs(() => ({ variant: "h6" }))`
  color: ${text.primary};
  line-height: 130%;
  ${ellipsis}
`;
const OptionDescription = styled(Typography).attrs(() => ({
  variant: "caption",
}))`
  color: ${text.secondary};
  ${ellipsis}
`;

const OptionText = styled(Stack)`
  ${ellipsis}
  text-align: left;
`;

const innerSpacing = 2;

const OptionInner = styled(Stack).attrs(() => ({
  alignItems: "center",
  direction: "row",
  justifyContent: "space-between",
  spacing: innerSpacing,
}))`
  max-width: 100%;
  width: 100%;
`;

const IconAndTextStack = styled(Stack).attrs(() => ({
  alignItems: "center",
  direction: "row",
  spacing: innerSpacing,
}))`
  max-width: calc(100% - ${iconSize + innerSpacing * 8}px);
`;

const Option = styled(ButtonBase)`
  ${({ $isLast }) => !$isLast && `border-bottom: ${paperBorder};`}
  ${({ $isSelected }) => $isSelected && "pointer-events: none;"}

  &:hover {
    background-color: ${action.hover};
  }

  justify-content: flex-start;
  padding: 12px;

  max-width: 100%;
`;

const Options = styled(Stack).attrs(() => ({ justifyContent: "flex-start" }))`
  width: 500px;
`;

const DelayedTooltip = styled(TooltipOrFragment).attrs(() => ({
  ...tooltipDelayProps,
  disableInteractive: true,
}))``;

const Switcher = ({ scenarioId }) => {
  const {
    handleClosePopover: handleCloseSwitcher,
    handleClickOpenPopover: handleClickSwitcher,
    isPopoverOpen,
    popoverAnchor,
  } = usePopover();

  const history = useHistory();

  const [lastScenarioId, setLastScenarioId] = useState();

  useEffect(() => {
    const scenarioIdFromSessionStorage = sessionStorage.getItem(
      SWITCHER_LAST_SCENARIO_ID_STORAGE_KEY
    );

    const scenarioIdFromLocalStorage = localStorage.getItem(
      SWITCHER_LAST_SCENARIO_ID_STORAGE_KEY
    );

    if (scenarioId && scenarioId !== scenarioIdFromSessionStorage) {
      sessionStorage.setItem(SWITCHER_LAST_SCENARIO_ID_STORAGE_KEY, scenarioId);
    }

    if (scenarioId && scenarioId !== scenarioIdFromLocalStorage) {
      localStorage.setItem(SWITCHER_LAST_SCENARIO_ID_STORAGE_KEY, scenarioId);
    }

    setLastScenarioId(
      scenarioIdFromSessionStorage || scenarioIdFromLocalStorage
    );
  }, [scenarioId]);

  const scenarioIdToFetch = scenarioId || lastScenarioId;

  const { data: scenario, isLoading } = useScenario({
    scenarioId: scenarioIdToFetch,
  });

  const createClickHandler = (path) => () => {
    handleCloseSwitcher();

    history.push(path);
  };

  const scenarioOption = {
    description: scenarioId
      ? SWITCHER_SCENARIO_CURRENTLY_EDITING
      : SWITCHER_SCENARIO_NOT_EDITING,
    isSelected: scenarioId,
    onClick: createClickHandler(`/${scenario?.id}${CIP_PATH}`),
    scenarioIcon: scenario?.icon,
    title: scenario?.name,
    tooltip: scenario?.name,
  };

  const allScenariosOption = {
    description: SWITCHER_ALL_SCENARIOS_DESCRIPTION,
    Icon: CallSplit,
    onClick: createClickHandler(SCENARIOS_PATH),
    title: SWITCHER_ALL_SCENARIOS_TITLE,
  };

  const globalWorkspaceOption = {
    description: SWITCHER_GLOBAL_WORKSPACE_DESCRIPTION,
    isSelected: !scenarioId,
    Icon: Language,
    onClick: createClickHandler(SCENARIOS_PATH),
    title: SWITCHER_GLOBAL_WORKSPACE_TITLE,
  };

  const options = [
    scenario ? scenarioOption : allScenariosOption,
    globalWorkspaceOption,
  ];

  const currentOption = scenarioId ? scenarioOption : globalWorkspaceOption;

  const CurrentIcon = currentOption?.Icon;

  return (
    <StyledSkeletonIfLoading isLoading={isLoading}>
      <SwitcherButton
        $isPopoverOpen={isPopoverOpen}
        data-testid={SWITCHER_BUTTON_TEST_ID}
        onClick={handleClickSwitcher}
      >
        <SwitcherButtonIconAndTextStack>
          {currentOption?.scenarioIcon ? (
            <ScenarioIcon
              color={common.white}
              name={currentOption?.scenarioIcon}
            />
          ) : (
            CurrentIcon && <CurrentIcon />
          )}
          <DelayedTooltip title={currentOption?.title}>
            <SwitcherButtonText>{currentOption?.title}</SwitcherButtonText>
          </DelayedTooltip>
        </SwitcherButtonIconAndTextStack>
        {isPopoverOpen ? <ArrowDropUp /> : <ArrowDropDown />}
      </SwitcherButton>
      <Popover
        anchorEl={popoverAnchor}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        onClose={handleCloseSwitcher}
        open={isPopoverOpen}
      >
        <Options>
          {options?.map(
            (
              {
                description,
                Icon,
                isSelected,
                onClick,
                scenarioIcon,
                title,
                tooltip,
              },
              index
            ) => (
              <Option
                $isLast={options?.length - 1 === index}
                $isSelected={isSelected}
                data-testid={SWITCHER_OPTION_TEST_ID}
                key={title}
                onClick={onClick}
              >
                <OptionInner>
                  <IconAndTextStack>
                    {scenarioIcon ? (
                      <ScenarioIcon name={scenarioIcon} />
                    ) : (
                      <Icon color="action" />
                    )}
                    <OptionText>
                      <DelayedTooltip title={tooltip}>
                        <OptionTitle>{title}</OptionTitle>
                      </DelayedTooltip>
                      <OptionDescription>{description}</OptionDescription>
                    </OptionText>
                  </IconAndTextStack>
                  {isSelected && <Check color="action" />}
                </OptionInner>
              </Option>
            )
          )}
        </Options>
      </Popover>
    </StyledSkeletonIfLoading>
  );
};

Switcher.propTypes = {
  scenarioId: PropTypes.string,
};

Switcher.defaultProps = {
  scenarioId: undefined,
};

export default Switcher;
