import { useState } from "react";
import { snackbarAutoHideDuration } from "../theme";

const useSuccessSnackbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState();

  const handleCloseSuccessSnackbar = () => setIsOpen(false);
  const handleOpenSuccessSnackbar = () => setIsOpen(true);
  const handleOpenSuccessSnackbarWithMessage = (newMessage) => {
    setMessage(newMessage);
    handleOpenSuccessSnackbar();
  };

  return {
    handleOpenSuccessSnackbarWithMessage,
    successSnackbarProps: {
      autoHideDuration: snackbarAutoHideDuration,
      message,
      onClose: handleCloseSuccessSnackbar,
      open: isOpen,
    },
  };
};

export default useSuccessSnackbar;
