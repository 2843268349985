const useRTKDependentRequests = (requestResults = []) => {
  const calculateDependentProperty = (property) =>
    !!requestResults?.reduce((acc, current) => acc || current[property], false);

  const isError = calculateDependentProperty("isError");
  const isFetching = calculateDependentProperty("isFetching");

  const isSuccess = !!requestResults?.reduce(
    (acc, current) => acc && current?.isSuccess,
    true
  );

  const isNoCurrentData = requestResults?.reduce(
    (acc, { currentData, data }) => acc || (!currentData && data),
    false
  );

  const isLoading = !!(
    calculateDependentProperty("isLoading") ||
    (isNoCurrentData && isFetching)
  );

  const refetch = () => {
    requestResults.forEach(
      ({ isError: isErrorCurrent, refetch: refetchCurrent }) => {
        if (isErrorCurrent) {
          refetchCurrent();
        }
      }
    );
  };

  return {
    isError,
    isFetching,
    isLoading,
    isSuccess,
    refetch,
  };
};

export default useRTKDependentRequests;
