import { JSON_API_ACCEPT } from "utils";
import cipApi from "../../cip-api";
import jsonApiDeserialize from "../utils/json-api-deserialize";

const extendedApi = cipApi.injectEndpoints({
  endpoints: (build) => ({
    drivers: build.query({
      query: () => ({
        headers: {
          Accept: JSON_API_ACCEPT,
        },
        url: `/admin/drivers`,
      }),
      transformResponse: (response) => jsonApiDeserialize(response),
    }),
  }),
});

export const { useDriversQuery } = extendedApi;

export const driversSelector = extendedApi.endpoints.drivers.select;
