/* eslint-disable no-template-curly-in-string */

export const createRequiredError = (label) => `${label} is required`;
export const TRY_AGAIN_TEXT = "TRY AGAIN";

export const createMinimumErrorText = (min) => `Minimum is ${min}`;

export const PHONE_NUMBER_ERROR_TEXT = "A phone number should be 10 digits";

export const validatePhoneNumber = (value) => {
  const phoneLength = value?.toString()?.length;

  if (phoneLength === 10 || !phoneLength) {
    return true;
  }

  return PHONE_NUMBER_ERROR_TEXT;
};

export const EMAIL_ERROR_TEXT = "Must be a valid e-mail";

export const validateEmail = (value) =>
  !/^\S+@\S+\.\S+$/.test(value) ? EMAIL_ERROR_TEXT : undefined;

export const MINIMUM_ERROR_TEXT = "Minimum is ${min}";
export const MAXIMUM_ERROR_TEXT = "Maximum is ${max}";
export const INTEGER_ERROR_TEXT = "Must be an integer";
