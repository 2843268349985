import { AppBar, Container, Stack } from "@mui/material";
import styled from "styled-components";
import {
  appBarHeight,
  appBarPadding,
  grey,
  maxContainerWidth,
  minContainerWidth,
} from "../theme";

export const StyledContainer = styled(Container).attrs(() => ({
  maxWidth: false,
}))`
  height: calc(100% - ${appBarHeight});
  max-width: ${maxContainerWidth};
  min-width: ${minContainerWidth};
  padding-left: 0;
  padding-right: 0;
`;

export const StyledAppBarContainer = styled(StyledContainer)`
  height: 100%;

  padding-left: ${appBarPadding};
  padding-right: ${appBarPadding};
`;

export const StyledAppBar = styled(AppBar)`
  height: ${appBarHeight};
  min-width: ${minContainerWidth};

  ${({ $isGlobalWorkspace }) =>
    $isGlobalWorkspace && `background-color: ${grey[800]};`}
`;

export const InnerAppBarStack = styled(Stack).attrs(() => ({
  alignItems: "center",
  direction: "row",
  spacing: 2,
}))`
  height: 100%;
`;
