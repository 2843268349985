import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Stack, Typography } from "@mui/material";
import { Refresh, WebAssetOff } from "@mui/icons-material";
import styled from "styled-components";
import { grey, text } from "./shared/theme";

const ErrorMessage = styled(Typography).attrs(() => ({ variant: "body1" }))`
  color: ${text.secondary};
  text-align: center;
  max-width: 466px;
`;

const ErrorTitle = styled(Typography).attrs(() => ({ variant: "h4" }))`
  color: ${text.disabled};
  text-align: center;
`;

const Container = styled(Stack).attrs(() => ({
  alignItems: "center",
  justifyContent: "center",
  spacing: 4,
}))`
  margin-top: 128px;
`;

const ErrorIcon = styled(WebAssetOff).attrs(() => ({
  fontSize: "inherit",
}))`
  color: ${grey[300]};
  font-size: 156px;
`;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Container>
          <ErrorIcon />
          <Stack spacing={3}>
            <ErrorTitle>Couldn’t load page</ErrorTitle>
            <Stack alignItems="center" justifyContent="center" spacing={5}>
              <ErrorMessage>
                An unexpected error occurred and we’re not able to load this
                page right now. Refresh to try again. If the issue persists,
                contact your account team to troubleshoot.
              </ErrorMessage>
              <Button
                onClick={() => window.location.reload()}
                startIcon={<Refresh />}
                variant="contained"
              >
                REFRESH
              </Button>
            </Stack>
          </Stack>
        </Container>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
