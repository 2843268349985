export const SKELETON_LOADER_TEST_ID = "skeleton-loader";

export const HIDE_DETAILS_BUTTON_TEXT = "HIDE DETAILS";
export const SHOW_DETAILS_BUTTON_TEXT = "SHOW DETAILS";

export const RETRY_BUTTON_TEXT = "RETRY";

export const CANCEL_BUTTON_TEXT = "CANCEL";

export const LEAVE_AND_DISCARD_BUTTON_TEXT = "LEAVE AND DISCARD";

export const EMPTY_TABLE_ARROW_TEST_ID = "empty-table-arrow";

export const HIDE_SIDE_NAV = "Hide Navigation";

export const TOGGLE_SIDE_NAV_TEST_ID = "toggle-side-nav";
