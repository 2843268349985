export const PROJECT_STATE_ACTIVE = "ACTIVE";
export const PROJECT_STATE_INACTIVE = "INACTIVE";

export const PROJECT_COST_TYPE_MANUAL = "MANUAL";
export const PROJECT_COST_TYPE_S_CURVE = "S_CURVE";

export const PROJECT_SYNC_STATUS_TYPE_UP_TO_DATE = "UP_TO_DATE";

export const PROJECT_APPROVAL_APPROVED = "Approved";
export const PROJECT_APPROVAL_OTHER = "Other";

export const NAME_COLUMN_ID = "name";
export const PROJECT_ID_COLUMN_ID = "key";
export const PROJECT_TOTAL_COST_COLUMN_ID = "totalCost";
export const PROJECT_TOTAL_COST_ESCALATED_COLUMN_ID = "totalCostEscalated";
export const PROJECT_DURATION_COLUMN_ID = "duration";
export const PROJECT_START_YEAR_COLUMN_ID = "startYear";
export const PROJECT_END_YEAR_COLUMN_ID = "endYear";
export const PROJECT_MAX_END_YEAR_COLUMN_ID = "maximumEndYear";
export const PROJECT_UTILITY_ID_COLUMN_ID = "utilityId";
export const PROJECT_APPROVAL_COLUMN_ID = "approval";
export const PROJECT_INSTANCES_COLUMN_ID = "instances";
export const PROJECT_NOTES_COLUMN_ID = "notes";
export const PROJECT_MODIFIED_AT_COLUMN_ID = "modifiedAt";
export const PROJECT_TODAYS_COST_COLUMN_ID = "todaysCost";
export const PROJECT_ENABLED_COLUMN_ID = "enabled";
export const PROJECT_SYNC_STATUS_COLUMN_ID = "syncStatus";
