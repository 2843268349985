import React from "react";
import PropTypes from "prop-types";
import { SvgIcon } from "@mui/material";
import { nameToScenarioIconMap } from "utils";

const ScenarioIcon = ({ color, disabled, name, ...props }) => {
  const { color: iconMapColor, paths } = nameToScenarioIconMap[name] || {};

  if (!paths) {
    return null;
  }

  return (
    <SvgIcon
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      style={{
        color: color || iconMapColor,
        opacity: disabled ? 0.38 : undefined,
      }}
    >
      {paths?.map((current, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <path d={current} key={index} />
      ))}
    </SvgIcon>
  );
};

ScenarioIcon.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
};

ScenarioIcon.defaultProps = {
  color: undefined,
  disabled: undefined,
  name: undefined,
};

export default ScenarioIcon;
