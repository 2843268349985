import {
  alpha,
  createTheme,
  getContrastRatio,
  rgbToHex,
} from "@mui/material/styles";

export const baseFontSizeMap = {
  h1: 96,
  h2: 60,
  h3: 48,
  h4: 34,
  h5: 24,
  h6: 20,
  subtitle1: 16,
  subtitle2: 14,
  body1: 16,
  body2: 14,
  caption: 12,
  overline: 12,
  avatarLetter: 20,
  inputLabel: 12,
  helperText: 12,
  inputText: 16,
  tooltip: 10,
};

const textBase = "#000000";

const themeOptions = {
  palette: {
    primary: {
      main: "#3F51B5",
      dark: "#303F9F",
      light: "#7986CB",
    },
    secondary: {
      main: "#F50057",
      dark: "#C51162",
      light: "#FF4081",
    },
    info: {
      main: "#2196F3",
      dark: "#0B79D0",
      light: "#64B6F7",
    },
    text: {
      primary: alpha(textBase, 0.87),
      secondary: alpha(textBase, 0.6),
      disabled: alpha(textBase, 0.38),
      main: alpha(textBase, 0.87),
      light: alpha(textBase, 0.5),
      dark: alpha(textBase, 0.9),
      contrastText: getContrastRatio(textBase, "#fff") > 4.5 ? "#fff" : "#111",
    },
  },
  typography: {
    buttonLarge: {
      fontWeight: 500,
      fontSize: ".9375rem",
      lineHeight: 1.75,
      letterSpacing: ".02857em",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-root": {
            "box-shadow": "unset",
          },
          "&.MuiButton-sizeSmall": {
            "line-height": "unset",
            "padding-top": "5px",
            "padding-bottom": "5px",
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            "background-color": "#e2e4f3",
          },
          "&.MuiTableRow-hover:hover": {
            "background-color": "#e8e8e8",
          },
          "&.Mui-selected:hover": {
            "background-color": "#d4d7eb",
          },
        },
      },
    },
  },
};

const {
  typography: { pxToRem },
} = createTheme(themeOptions);

export const materialTheme = createTheme({
  ...themeOptions,
  typography: {
    ...themeOptions.typography,
    ...Object.entries(baseFontSizeMap).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: {
          fontSize: pxToRem(value),
        },
      }),
      {}
    ),
  },
});

const {
  palette: { action, grey, primary, secondary, success, text, warning },
} = materialTheme;

export const iconColors = {
  green: success.dark,
  darkBlue: primary.main,
  darkRed: "#AB2F26",
  lightBlue: "#1769AA",
  lightRed: secondary.dark,
  orange: warning.main,
  yellow: warning.light,
};

export const outlinedBorderColor = "rgba(0,0,0,.23)";

const getHexFromRgb = (rgb) => rgbToHex(rgb).slice(0, 7);
const getOpacityFromRgb = (rgb) =>
  parseFloat(rgb.slice(rgb.lastIndexOf(",") + 1, rgb.lastIndexOf(")"))) || 1;

const createTextPropsFromRgb = (rgb) => ({
  textRgb: rgb,
  textColor: getHexFromRgb(rgb),
  textOpacity: getOpacityFromRgb(rgb),
});

export const outlinedBorderPdf = createTextPropsFromRgb(outlinedBorderColor);
export const textPrimaryPdf = createTextPropsFromRgb(text.primary);
export const textSecondaryPdf = createTextPropsFromRgb(text.secondary);
export const textDisabledPdf = createTextPropsFromRgb(text.disabled);
export const actionActivePdf = createTextPropsFromRgb(action.active);

const darkDriverTextProps = createTextPropsFromRgb(text.primary);
const lightDriverTextProps = createTextPropsFromRgb(primary.contrastText);

export const lightMagenta = "#FA7BA8";
export const aqua = "#82C8F8";
export const chillLime = "#BBDE58";

const driverColors = [
  {
    color: "#601971",
    ...lightDriverTextProps,
  },
  {
    color: "#5B78DF",
    ...lightDriverTextProps,
  },
  {
    color: lightMagenta,
    ...darkDriverTextProps,
  },
  {
    color: "#F1D859",
    ...darkDriverTextProps,
  },
  {
    color: "#5249BB",
    ...lightDriverTextProps,
  },
  {
    color: chillLime,
    ...darkDriverTextProps,
  },
  {
    color: aqua,
    ...darkDriverTextProps,
  },
  {
    color: "#FECB70",
    ...darkDriverTextProps,
  },
];

export const createDriverColorMap = (data) =>
  data?.reduce(
    (acc, { id }, index) => ({
      [id]: driverColors[index],
      ...acc,
    }),
    {}
  ) || {};

export { grey };
