import { APPLICATION_JSON_ACCEPT, JSON_API_ACCEPT } from "utils";
import cipApi, { SCENARIO_TAG_TYPE } from "../../cip-api";
import { REACT_APP_API_BASE_URL } from "../environment";
import jsonApiDeserialize from "../utils/json-api-deserialize";

export const PATCH_SCENARIO_URL = `${REACT_APP_API_BASE_URL}/scenarios/:scenarioId`;

export const FETCH_SCENARIOS_URL = `${REACT_APP_API_BASE_URL}/cip/scenarios`;

export const DELETE_SCENARIO_URL = `${REACT_APP_API_BASE_URL}/scenarios/:scenarioId`;

const extendedApi = cipApi.injectEndpoints({
  endpoints: (build) => ({
    deleteScenario: build.mutation({
      invalidatesTags: [SCENARIO_TAG_TYPE],
      query: ({ scenarioId }) => ({
        url: `/scenarios/${scenarioId}`,
        headers: {
          Accept: JSON_API_ACCEPT,
        },
        method: "DELETE",
      }),
      async onQueryStarted({ scenarioId }, { dispatch, queryFulfilled }) {
        const scenarioPatchResult = dispatch(
          cipApi.util.updateQueryData("scenario", { scenarioId }, () => null)
        );

        const scenariosPatchResult = dispatch(
          cipApi.util.updateQueryData("scenarios", { scenarioId }, (old) =>
            old?.filter(({ id }) => id !== scenarioId)
          )
        );

        try {
          await queryFulfilled;
        } catch {
          scenarioPatchResult.undo();
          scenariosPatchResult.undo();
        }
      },
    }),
    editScenarioDetails: build.mutation({
      invalidatesTags: [SCENARIO_TAG_TYPE],
      query: ({ description, icon, labelIds, name, scenarioId }) => ({
        body: {
          description,
          name,
          icon,
          labelIds,
        },
        headers: {
          Accept: APPLICATION_JSON_ACCEPT,
          "Content-Type": APPLICATION_JSON_ACCEPT,
        },
        method: "PATCH",
        url: `/scenarios/${scenarioId}`,
      }),
      transformResponse: (data, response, { scenarioId, name }) => ({
        editedScenarioId: scenarioId,
        name,
      }),
    }),
    patchScenario: build.mutation({
      invalidatesTags: [SCENARIO_TAG_TYPE],
      query: ({ body, scenarioId }) => ({
        body,
        headers: {
          Accept: APPLICATION_JSON_ACCEPT,
          "Content-Type": APPLICATION_JSON_ACCEPT,
        },
        method: "PATCH",
        url: `/scenarios/${scenarioId}`,
      }),
    }),
    scenario: build.query({
      query: ({ scenarioId }) => ({
        headers: {
          Accept: JSON_API_ACCEPT,
        },
        url: `/cip/scenarios/${scenarioId}?include=labels`,
      }),
      providesTags: [SCENARIO_TAG_TYPE],
      transformResponse: (response) => jsonApiDeserialize(response),
    }),
    scenarios: build.query({
      query: () => ({
        headers: {
          Accept: JSON_API_ACCEPT,
        },
        params: {
          include: "labels",
        },
        url: `/cip/scenarios`,
      }),
      providesTags: [SCENARIO_TAG_TYPE],
      transformResponse: (response) => jsonApiDeserialize(response),
    }),
  }),
});

export const {
  useDeleteScenarioMutation,
  useEditScenarioDetailsMutation,
  usePatchScenarioMutation,
  useScenarioQuery,
  useScenariosQuery,
} = extendedApi;

export const scenarioSelector = extendedApi.endpoints.scenario.select;
