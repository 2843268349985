import { css } from "styled-components";
import { action, secondary, transitions } from "../theme";

/* stylelint-disable property-no-vendor-prefix */
export const noTextHighlight = css`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;
/* stylelint-enable property-no-vendor-prefix */

export const chartHoverStroke = css`
  stroke: ${secondary.main};
  stroke-width: 2px;
`;

export const getProp = (propName) => (props) => props[propName];

export const rotate = css`
  transform: rotate(${({ $rotate }) => ($rotate ? "0deg" : "180deg")});
  transition: transform ${transitions.duration.shortest}ms
    ${transitions.easing.easeInOut} 0ms;
`;

export const ellipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

/* stylelint-disable property-no-vendor-prefix, value-no-vendor-prefix */

export const ellipsisWithOneLine = css`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

export const ellipsisWithTwoLines = css`
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;

  text-overflow: ellipsis;
  overflow: hidden;
`;

/* stylelint-enable property-no-vendor-prefix, value-no-vendor-prefix */

export const widthCss = css`
  box-sizing: border-box;

  ${({ $width }) =>
    $width && `width: ${$width}; min-width: ${$width}; max-width: ${$width};`}
`;

export const focusVisible = css`
  &:focus-visible {
    background-color: ${action.focus};
    outline: none;
  }
`;

export const sentenceCase = css`
  text-transform: lowercase;

  ::first-letter {
    text-transform: uppercase;
  }
`;
