import React, { useState } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  FormControlLabel,
  Popover,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { DragIndicator, VisibilityOff } from "@mui/icons-material";
import {
  COLUMN_CONFIGURATION_DRAGGABLE_COLUMN_TEST_ID,
  COLUMN_CONFIGURATION_DESCRIPTION,
  COLUMN_CONFIGURATION_DRAG_INDICATOR_TEST_ID,
  COLUMN_CONFIGURATION_NON_DRAGGABLE_COLUMN_TEST_ID,
  COLUMN_CONFIGURATION_VISIBILITY_OFF_TEST_ID,
} from "./constants";
import { common, outlinedBorder, text } from "../../theme";

const Column = styled(Stack).attrs(() => ({
  alignItems: "center",
  direction: "row",
}))`
  ${({ $isDragging }) =>
    $isDragging && "border: 1px solid rgba(63, 81, 181, 0.5);"}

  ${({ $shouldRenderBottomBorder }) =>
    $shouldRenderBottomBorder && `border-bottom: ${outlinedBorder};`}

  ${({ $shouldRenderTopBorder }) =>
    $shouldRenderTopBorder && `border-top: ${outlinedBorder};`}
`;

const Description = styled(Typography).attrs(() => ({ variant: "body2" }))`
  color: ${text.secondary};
`;

const DescriptionContainer = styled(Stack).attrs(() => ({ spacing: 1 }))`
  background-color: ${common.white};
  max-width: fit-content;
  padding: 12px 16px;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const WhiteBackground = styled.div`
  background-color: ${common.white};
`;

const DragArea = styled.div`
  padding: 10px 12px;
`;

const DragAreaSpacer = styled.div`
  height: 44px;
  width: 44px;
`;

const DroppableArea = styled.div`
  ${({ $isDraggingOver }) => $isDraggingOver && `background-color: #3F51B514;`}
`;

const Container = styled.div`
  min-width: 290px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-right: 0;

  ${({ $shouldUseSecondaryText }) =>
    $shouldUseSecondaryText && `color: ${text.secondary};`}
`;

const VisibilityOffContainer = styled(Stack).attrs(() => ({
  alignItems: "flex-end",
}))`
  flex: 1;
  margin-left: 8px;
  margin-right: 8px;
`;

const StyledVisibilityOff = styled(VisibilityOff).attrs(() => ({
  "data-testid": COLUMN_CONFIGURATION_VISIBILITY_OFF_TEST_ID,
}))`
  ${({ $shouldHide }) => $shouldHide && "opacity: 0;"}
`;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ColumnConfiguration = ({
  columnConfiguration,
  columns,
  containerWidth,
  extraColumnWidth,
  handleClose,
  isOpen,
  popoverAnchor,
  shouldRenderHiddenColumns,
  title,
  updateColumnConfiguration,
}) => {
  const [draggingIndex, setDraggingIndex] = useState();

  const columnById = columns?.reduce(
    (acc, column) => ({
      ...acc,
      [column.id]: column,
    }),
    {}
  );

  const configuration =
    columnConfiguration ||
    columns.map(({ id }) => ({
      id,
      shouldShow: true,
    }));

  const createSwitchChangeHandler =
    (id) =>
    ({ target: { checked } }) => {
      const column = configuration.find((current) => current.id === id);
      const columnsWithoutColumn = configuration.filter(
        (current) => current.id !== id
      );

      const shouldShowColumns = columnsWithoutColumn.filter(
        ({ shouldShow }) => shouldShow
      );
      const shouldNotShowColumns = columnsWithoutColumn.filter(
        ({ shouldShow }) => !shouldShow
      );

      updateColumnConfiguration([
        ...shouldShowColumns,
        {
          ...column,
          shouldShow: checked,
        },
        ...shouldNotShowColumns,
      ]);
    };

  const handleDragEnd = (result) => {
    setDraggingIndex(null);

    if (!result.destination) {
      return;
    }

    updateColumnConfiguration(
      reorder(configuration, result.source.index, result.destination.index)
    );
  };

  const handleDragStart = ({ source: { index } }) => setDraggingIndex(index);

  const pinnedColumns = configuration.filter(
    ({ id }) => !!columnById[id].isLeftPinned
  );
  const visiblePinnedColumns = pinnedColumns.filter(
    ({ id }) => !columnById[id].shouldHideFromColumnConfiguration
  );

  const otherTemporaryColumns = configuration.filter(
    ({ id }) => !columnById[id].isLeftPinned
  );
  const reorderOffset = pinnedColumns.length;

  let accumulatedWidth =
    extraColumnWidth +
    pinnedColumns.reduce((acc, { id }) => acc + columnById[id].width, 0);

  const lastVisibleIndex =
    otherTemporaryColumns?.findIndex(({ id, shouldShow }) => {
      if (shouldShow) {
        accumulatedWidth += columnById[id]?.width;
      }

      return accumulatedWidth > containerWidth || !shouldShow;
    }) - 1;

  const draggableTemporaryColumns = otherTemporaryColumns.filter(
    ({ shouldShow }) => shouldShow
  );
  const nonDraggableTemporaryColumns = otherTemporaryColumns.filter(
    ({ shouldShow }) => !shouldShow
  );

  return (
    <>
      <Popover
        anchorEl={popoverAnchor}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        open={isOpen}
        onClose={handleClose}
      >
        <DescriptionContainer>
          <Typography variant="subtitle2">{title}</Typography>
          <Description>{COLUMN_CONFIGURATION_DESCRIPTION}</Description>
        </DescriptionContainer>
        <Container>
          <DragDropContext
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
          >
            {visiblePinnedColumns.map(({ id, shouldShow }, index) => (
              <Column
                data-testid={`${COLUMN_CONFIGURATION_DRAGGABLE_COLUMN_TEST_ID}-${id}`}
                $shouldRenderBottomBorder={
                  shouldRenderHiddenColumns &&
                  lastVisibleIndex < 0 &&
                  index === visiblePinnedColumns.length - 1
                }
                key={id}
              >
                <DragAreaSpacer />
                <StyledFormControlLabel
                  control={
                    <Switch
                      checked={shouldShow}
                      disabled
                      onChange={createSwitchChangeHandler(id)}
                      size="small"
                    />
                  }
                  label={columnById[id]?.label}
                />
              </Column>
            ))}
            <Droppable droppableId="columns">
              {(droppableProvided, droppableSnapshot) => (
                <DroppableArea
                  ref={droppableProvided.innerRef}
                  $isDraggingOver={droppableSnapshot.isDraggingOver}
                >
                  {draggableTemporaryColumns?.map(
                    ({ id, shouldShow }, index) => (
                      <Draggable
                        draggableId={id}
                        key={id}
                        index={index + reorderOffset}
                      >
                        {(draggableProvided, { isDragging }) => (
                          <WhiteBackground
                            ref={draggableProvided.innerRef}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...draggableProvided.draggableProps}
                          >
                            <Column
                              $isDragging={isDragging}
                              $shouldRenderBottomBorder={
                                shouldRenderHiddenColumns &&
                                !isDragging &&
                                lastVisibleIndex === index
                              }
                              $shouldRenderTopBorder={
                                shouldRenderHiddenColumns &&
                                draggingIndex === index &&
                                index === lastVisibleIndex + reorderOffset
                              }
                              data-testid={`${COLUMN_CONFIGURATION_DRAGGABLE_COLUMN_TEST_ID}-${id}`}
                            >
                              {shouldShow ? (
                                <DragArea
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...draggableProvided.dragHandleProps}
                                  data-testid={
                                    COLUMN_CONFIGURATION_DRAG_INDICATOR_TEST_ID
                                  }
                                >
                                  <DragIndicator
                                    color={isDragging ? "active" : "disabled"}
                                    fontSize="small"
                                  />
                                </DragArea>
                              ) : (
                                <DragAreaSpacer
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...draggableProvided.dragHandleProps}
                                />
                              )}
                              <StyledFormControlLabel
                                control={
                                  <Switch
                                    checked={shouldShow}
                                    onChange={createSwitchChangeHandler(id)}
                                    size="small"
                                  />
                                }
                                label={columnById[id]?.label}
                                $shouldUseSecondaryText={
                                  shouldRenderHiddenColumns &&
                                  index > lastVisibleIndex &&
                                  !isDragging
                                }
                              />
                              <VisibilityOffContainer>
                                <StyledVisibilityOff
                                  $shouldHide={
                                    !shouldRenderHiddenColumns ||
                                    index <= lastVisibleIndex ||
                                    isDragging
                                  }
                                  color="disabled"
                                  fontSize="small"
                                />
                              </VisibilityOffContainer>
                            </Column>
                          </WhiteBackground>
                        )}
                      </Draggable>
                    )
                  )}
                  {droppableProvided.placeholder}
                </DroppableArea>
              )}
            </Droppable>
            {nonDraggableTemporaryColumns.map(({ id, shouldShow }) => (
              <WhiteBackground key={id}>
                <Column
                  data-testid={`${COLUMN_CONFIGURATION_NON_DRAGGABLE_COLUMN_TEST_ID}-${id}`}
                >
                  <DragAreaSpacer />
                  <StyledFormControlLabel
                    control={
                      <Switch
                        checked={shouldShow}
                        onChange={createSwitchChangeHandler(id)}
                        size="small"
                      />
                    }
                    label={columnById[id].label}
                    $shouldUseSecondaryText
                  />
                  <VisibilityOffContainer>
                    <StyledVisibilityOff color="disabled" fontSize="small" />
                  </VisibilityOffContainer>
                </Column>
              </WhiteBackground>
            ))}
          </DragDropContext>
        </Container>
      </Popover>
    </>
  );
};

ColumnConfiguration.propTypes = {
  columnConfiguration: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      shouldShow: PropTypes.bool.isRequired,
    })
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      width: PropTypes.number.isRequired,
    })
  ),
  containerWidth: PropTypes.number.isRequired,
  extraColumnWidth: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  popoverAnchor: PropTypes.any,
  shouldRenderHiddenColumns: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  updateColumnConfiguration: PropTypes.func.isRequired,
};

ColumnConfiguration.defaultProps = {
  columns: [],
  columnConfiguration: null,
  popoverAnchor: undefined,
};

export default ColumnConfiguration;
