export const USER_ACTIONS_MENU_CANCEL_INVITATION_BUTTON_TEXT =
  "Cancel Invitation";
export const USER_ACTIONS_MENU_EDIT_ACCESS_LEVEL_BUTTON_TEXT =
  "Edit Access Level";
export const USER_ACTIONS_MENU_DISABLE_ACCOUNT_BUTTON_TEXT = "Disable Account";
export const USER_ACTIONS_MENU_RESTORE_ACCOUNT_BUTTON_TEXT = "Restore Account";
export const USER_ACTIONS_MENU_SEND_NEW_INVITATION_BUTTON_TEXT =
  "Send New Invitation";

export const CANCEL_INVITATION_BUTTON_TEXT = "CANCEL INVITATION";
export const CANCEL_INVITATION_ERROR_TEXT = "Failed to cancel invitation";

export const CONFIRM_DEMOTE_SELF_BUTTON_TEXT = "CONFIRM LOWER ACCESS LEVEL";
export const EDIT_ACCESS_LEVEL_BUTTON_TEXT = "SET ACCESS LEVEL";
export const EDIT_ACCESS_LEVEL_BUTTON_TEST_ID =
  "edit-access-level-submit-button";
export const EDIT_ACCESS_LEVEL_ERROR_TEXT = "Failed to edit access level";

export const DISABLE_USER_BUTTON_TEXT = "DISABLE ACCOUNT";
export const DISABLE_USER_ERROR_TEXT = "Failed to disable account";
export const DISABLE_USER_BUTTON_TEST_ID = "disable-user-submit-button";

export const RESTORE_USER_BUTTON_TEXT = "RESTORE ACCOUNT";
export const RESTORE_USER_ERROR_TEXT = "Failed to restore account";

export const SEND_NEW_INVITATION_BUTTON_TEXT = "SEND NEW INVITATION EMAIL";
export const SEND_NEW_INVITATION_ERROR_TEXT = "Failed to send new invitation";

export const USER_POPOVER_ACTIONS_MENU_TEST_ID = "user-popover-actions-menu";

export const USER_POPOVER_VIEW_IN_DIRECTORY_LINK_TEXT = "View in Directory";
