import cipApi, { USER_TAG_TYPE } from "../../cip-api";
import { REACT_APP_API_BASE_URL } from "../../shared/environment";

export const FETCH_USER_PROFILES_URL = `${REACT_APP_API_BASE_URL}/profiles`;

const extendedApi = cipApi.injectEndpoints({
  endpoints: (build) => ({
    getUserProfiles: build.query({
      query: () => ({
        url: FETCH_USER_PROFILES_URL,
      }),
      providesTags: [USER_TAG_TYPE],
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetUserProfilesQuery } = extendedApi;

export const userProfilesSelector =
  extendedApi.endpoints.getUserProfiles.select;
