import { createSelector, createSlice } from "@reduxjs/toolkit";

/* eslint-disable no-param-reassign */

const layoutSlice = createSlice({
  name: "layout",
  initialState: { isOpen: false },
  reducers: {
    closeSnackbar: (state) => {
      state.isOpen = false;
    },
    displaySnackbarMessage: (state, action) => {
      state.message = action.payload;
      state.isOpen = true;
    },
  },
});

export const { closeSnackbar, displaySnackbarMessage } = layoutSlice.actions;

export default layoutSlice;

/* eslint-enable no-param-reassign */

const getLayoutSlice = createSelector(
  (state) => state,
  (state) => state[layoutSlice.name]
);

export const getSnackbarMessage = createSelector(
  getLayoutSlice,
  (slice) => slice.message
);

export const getIsSnackbarOpen = createSelector(
  getLayoutSlice,
  (slice) => slice.isOpen
);
