import { useFlag } from "@unleash/proxy-client-react";
import { useMyProfileQuery } from "../api/profile";
import {
  SUPER_ADMIN_ACCESS_LEVEL,
  accessLevelLabelMap,
} from "../constants/user";

const useHasAccess = (rolesWithAccess) => {
  const isAccessLevelsEnabled = useFlag("accessLevels");

  const { data: myProfile } = useMyProfileQuery(undefined, {
    skip: !isAccessLevelsEnabled,
  });

  if (!isAccessLevelsEnabled) {
    return {
      hasAccess: true,
      noAccess: false,
      noAccessMessage: undefined,
    };
  }

  const accessLevel = myProfile?.accessLevel;

  const hasAccess = rolesWithAccess
    ? rolesWithAccess.includes(accessLevel)
    : true;

  const userVisibleRoleLabels = rolesWithAccess
    ?.filter((role) => role !== SUPER_ADMIN_ACCESS_LEVEL)
    ?.map((role) => accessLevelLabelMap[role]);

  return {
    hasAccess,
    noAccess: !hasAccess,
    noAccessMessage: !hasAccess
      ? `This action requires ${userVisibleRoleLabels.join(
          "/"
        )} access. Your access level is ${
          accessLevelLabelMap[accessLevel]
        }. Contact your administrator if you wish to request a different access level.`
      : undefined,
  };
};

export default useHasAccess;
