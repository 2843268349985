import React from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useAdminSettingsQuery } from "./shared/api/admin";
import LoadingPage from "./shared/components/Loading-Page";
import FetchError from "./shared/components/Fetch-Error";
import { getAccessToken } from "./shared/reducers/base-slice";
import { useDriversQuery } from "./shared/api/drivers";
import useRTKDependentRequests from "./shared/hooks/use-rtk-dependent-requests";
import { useMyProfileQuery } from "./shared/api/profile";

const BlockingRequestsWrapper = ({ children }) => {
  const accessToken = useSelector(getAccessToken);

  // Get a head start on drivers query so we have colors available
  useDriversQuery(undefined, { skip: !accessToken });

  const isAccessLevelsEnabled = useFlag("accessLevels");

  const myProfileResult = useMyProfileQuery(undefined, {
    skip: !accessToken || !isAccessLevelsEnabled,
  });

  const adminSettingsResult = useAdminSettingsQuery(undefined, {
    skip: !accessToken,
  });

  const blockingResults = [adminSettingsResult];

  if (isAccessLevelsEnabled) {
    blockingResults.push(myProfileResult);
  }

  const { isError, isLoading, isSuccess, refetch } =
    useRTKDependentRequests(blockingResults);

  if (isLoading) {
    return <LoadingPage shouldLeaveSpaceForAppBar={false} />;
  }

  if (isSuccess) {
    return children;
  }

  if (isError) {
    return (
      <FetchError
        errorMessage="Failed to fetch essential settings"
        refetch={refetch}
      />
    );
  }

  return null;
};

BlockingRequestsWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BlockingRequestsWrapper;
