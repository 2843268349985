import { formatDistanceToNowStrict } from "date-fns";
import { calculateCurrentFYEYear } from "utils";

export const parseMonthYearFromDate = (dateString) => {
  const date = new Date(dateString);

  return {
    month: date.getUTCMonth() + 1,
    year: date.getUTCFullYear(),
  };
};

export const formatDate = (date) => {
  let dateToFormat = date;

  if (!date?.toLocaleDateString) {
    dateToFormat = new Date(date);
  }

  return dateToFormat.toLocaleDateString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};

export const formatNumericDate = (date) => {
  let dateToFormat = date;

  if (!date?.toLocaleDateString) {
    dateToFormat = new Date(date);
  }

  return dateToFormat.toLocaleDateString("en-US", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
};

export const formatDateDistance = (date) => {
  let dateToFormat = date;

  if (!date?.toLocaleDateString) {
    dateToFormat = new Date(date);
  }

  return formatDistanceToNowStrict(dateToFormat, {
    addSuffix: true,
  });
};

export const getTestCurrentFYEYear = () => calculateCurrentFYEYear(7);
