import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { deepPurple, green, lightBlue } from "@mui/material/colors";
import { Badge } from "@mui/material";
import { common } from "../../theme";
import { SkeletonIfLoading } from "../Skeleton";
import {
  ADMIN_ACCESS_LEVEL,
  EDITOR_ACCESS_LEVEL,
  GLOBAL_EDITOR_ACCESS_LEVEL,
  SUPER_ADMIN_ACCESS_LEVEL,
  VIEWER_ACCESS_LEVEL,
  accessLevelLabelMap,
} from "../../constants/user";
import TooltipOrFragment from "../Tooltip-Or-Fragment";

export const accessLevelMap = {
  [ADMIN_ACCESS_LEVEL]: {
    color: deepPurple[500],
    description:
      "Administrators have top-level access. They can manage user accounts, modify global settings and Global Projects, and create, edit, or delete scenarios.",
    label: accessLevelLabelMap[ADMIN_ACCESS_LEVEL],
  },
  [EDITOR_ACCESS_LEVEL]: {
    color: green[700],
    description:
      "Editors can create, edit, and delete scenarios in both the global and scenario workspaces. However, they cannot modify global settings, Global Projects, or manage user accounts.",
    label: accessLevelLabelMap[EDITOR_ACCESS_LEVEL],
  },
  [GLOBAL_EDITOR_ACCESS_LEVEL]: {
    color: green[700],
    description:
      "Global Editors can create, edit, and delete scenarios in both the global and scenario workspaces. They can also create and edit Global Projects. However, they cannot modify global settings or manage user accounts.",
    label: accessLevelLabelMap[GLOBAL_EDITOR_ACCESS_LEVEL],
  },
  [SUPER_ADMIN_ACCESS_LEVEL]: {
    color: deepPurple[500],
    description: "MME Administrator Access",
    label: accessLevelLabelMap[SUPER_ADMIN_ACCESS_LEVEL],
  },
  [VIEWER_ACCESS_LEVEL]: {
    color: lightBlue[700],
    description:
      "Viewers can access all areas of the software, including global settings, Global Projects, and scenarios. However, their role is observation-only, and they cannot make changes or modifications.",
    label: accessLevelLabelMap[VIEWER_ACCESS_LEVEL],
  },
};

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    color: ${common.white};
    background-color: ${({ $color }) => $color};
    position: relative;
    transform: unset;
  }
`;

const AccessLevelBadge = ({ accessLevel, isLoading, shouldDisableTooltip }) => {
  const { color, description, label } = accessLevelMap[accessLevel] || {};

  return (
    <SkeletonIfLoading isLoading={isLoading} variant="circular">
      <TooltipOrFragment
        arrow
        disableInteractive
        title={!shouldDisableTooltip && description}
      >
        <StyledBadge
          $color={color}
          badgeContent={label || (isLoading ? "Filled" : "")}
        />
      </TooltipOrFragment>
    </SkeletonIfLoading>
  );
};

AccessLevelBadge.propTypes = {
  accessLevel: PropTypes.string,
  isLoading: PropTypes.bool,
  shouldDisableTooltip: PropTypes.bool,
};

AccessLevelBadge.defaultProps = {
  accessLevel: undefined,
  isLoading: false,
  shouldDisableTooltip: false,
};

export default AccessLevelBadge;
