import get from "lodash.get";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import {
  booleanCompare,
  dateCompare,
  floatCompare,
  stringCompare,
} from "../../utils/sorting";

const equalFunc = () => 0;

const sortMap = {
  boolean: booleanCompare,
  date: dateCompare,
  float: floatCompare,
  string: stringCompare,
};

/* eslint-disable no-param-reassign */

const createVirtualizedTableSlice = ({
  defaultSort,
  unsortedItemsSelector,
  name,
}) => {
  const initialState = {
    sort: defaultSort,
  };

  const tableSlice = createSlice({
    name,
    initialState,
    reducers: {
      resetTableSlice: () => initialState,
      setIdToSortMap: (state, action) => {
        state.idToSortMap = action.payload;
      },
      setSort: (state, action) => {
        state.sort = action.payload;
      },
    },
  });

  const { resetTableSlice, setIdToSortMap, setSort } = tableSlice.actions;

  const getVirtualizedTableSlice = createSelector(
    (state) => state,
    (state) => state[tableSlice.name]
  );

  const getSort = createSelector(
    getVirtualizedTableSlice,
    (slice) => slice.sort
  );

  const getIdToSortMap = createSelector(
    getVirtualizedTableSlice,
    (slice) => slice.idToSortMap
  );

  const getSortedItems = createSelector(
    unsortedItemsSelector,
    getIdToSortMap,
    getSort,
    (items, idToSortMap, sort) =>
      items?.slice()?.sort((a, b) => {
        const sortFunction = sortMap[idToSortMap?.[sort?.id]];

        const aSortProperty = get(a, sort?.id);
        const bSortProperty = get(b, sort?.id);

        return (sortFunction || equalFunc)({
          a: aSortProperty,
          b: bSortProperty,
          order: sort?.order,
        });
      })
  );

  return {
    getSort,
    getSortedItems,
    getVirtualizedTableSlice,
    resetTableSlice,
    setIdToSortMap,
    setSort,
    tableSlice,
    virtualizedTableProps: {
      resetTableSlice,
      setIdToSortMap,
      setSort,
      sortedItemsSelector: getSortedItems,
      sortSelector: getSort,
    },
  };
};

export default createVirtualizedTableSlice;

/* eslint-enable no-param-reassign */
