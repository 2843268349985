import { APPLICATION_JSON_ACCEPT } from "utils";
import cipApi from "../cip-api";
import { REACT_APP_API_BASE_URL } from "../shared/environment";

export const RESET_PASSWORD_URL = `${REACT_APP_API_BASE_URL}/profiles/me/password-reset`;

const extendedApi = cipApi.injectEndpoints({
  endpoints: (build) => ({
    resetPassword: build.mutation({
      query: () => ({
        headers: {
          Accept: APPLICATION_JSON_ACCEPT,
        },
        method: "POST",
        url: RESET_PASSWORD_URL,
      }),
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useResetPasswordMutation } = extendedApi;
