import React from "react";
import styled from "styled-components";
import { Skeleton as MuiSkeleton } from "@mui/material";
import PropTypes from "prop-types";
import { SKELETON_LOADER_TEST_ID } from "./constants";

export const Skeleton = styled(MuiSkeleton).attrs(() => ({
  "data-testid": SKELETON_LOADER_TEST_ID,
}))`
  * {
    opacity: 0;
  }
`;

export const InvisibleLoader = styled(Skeleton)`
  display: none;
`;

export const SkeletonIfLoading = ({
  children,
  className,
  isLoading,
  variant,
  width,
  ...rest
}) => {
  if (isLoading) {
    return (
      <Skeleton
        className={className}
        variant={variant}
        width={width}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {children}
      </Skeleton>
    );
  }

  return children;
};

SkeletonIfLoading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  variant: PropTypes.string,
  width: PropTypes.string,
};

SkeletonIfLoading.defaultProps = {
  children: null,
  className: undefined,
  variant: "rectangular",
  width: undefined,
};

export const TextSkeletonIfLoading = styled(SkeletonIfLoading).attrs(
  (props) => ({
    variant: "text",
    ...props,
  })
)``;

export const OldTextSkeletonIfLoading = ({ children, isLoading, width }) => {
  if (isLoading) {
    return <Skeleton variant="text" width={width} />;
  }

  return children;
};

OldTextSkeletonIfLoading.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,
  width: PropTypes.number,
};

OldTextSkeletonIfLoading.defaultProps = {
  children: null,
  color: undefined,
  width: undefined,
};

export const InputSkeletonIfLoading = styled(SkeletonIfLoading).attrs(() => ({
  width: "100%",
}))``;
