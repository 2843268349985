import { createSelector, createSlice } from "@reduxjs/toolkit";
import { globalProjectsSelector } from "../shared/api/global-projects";
import createProjectFilterSlice from "../shared/components/table/project-filter-slice";
import createVirtualizedTableSlice from "../shared/components/table/virtualized-table-slice";
import { NAME_COLUMN_ID } from "../shared/constants/project";
import { ASCENDING_KEY } from "../shared/constants/sorting";

const getGlobalProjects = createSelector(
  globalProjectsSelector(),
  (result) => result?.data
);

const {
  getFilteredProjects,
  projectFilterSlice: globalProjectsFilterSlice,
  useProjectFilterProps,
} = createProjectFilterSlice({
  name: "globalProjectFilterSlice",
  projectsSelector: getGlobalProjects,
});

export {
  getFilteredProjects,
  globalProjectsFilterSlice,
  useProjectFilterProps,
};

const {
  getSortedItems,
  tableSlice: globalProjectsTableSlice,
  virtualizedTableProps,
} = createVirtualizedTableSlice({
  defaultSort: { id: NAME_COLUMN_ID, order: ASCENDING_KEY },
  name: "globalProjectsTableSlice",
  unsortedItemsSelector: getFilteredProjects,
});

export { getSortedItems, globalProjectsTableSlice, virtualizedTableProps };

/* eslint-disable no-param-reassign */

const globalProjectsSlice = createSlice({
  name: "globalProjects",
  initialState: {},
  reducers: {
    selectProject: (state, action) => {
      state.selectedProjectId = action.payload;
    },
  },
});

export const { selectProject } = globalProjectsSlice.actions;

export default globalProjectsSlice;

/* eslint-enable no-param-reassign */

const getGlobalProjectsSlice = createSelector(
  (state) => state,
  (state) => state[globalProjectsSlice.name]
);

const getSelectedProjectId = createSelector(
  getGlobalProjectsSlice,
  (slice) => slice.selectedProjectId
);

export const getIsItemSelected = createSelector(
  getSelectedProjectId,
  (state, { id }) => id,
  (selectedProjectId, projectId) => selectedProjectId === projectId
);
