import { useFlag } from "@unleash/proxy-client-react";
import {
  USER_STATUS_ACTIVE,
  USER_STATUS_DEACTIVATED,
  USER_STATUS_INVITE_EXPIRED,
  USER_STATUS_INVITE_PENDING,
} from "../../constants/user";

const useIsUserActionAvailable = (status) => {
  const isCancelInvitationEnabled = useFlag("cancelInvitation");
  const isEditAccessLevelEnabled = useFlag("editAccessLevel");
  const isDisableUserEnabled = useFlag("disableUser");
  const isRestoreUserEnabled = useFlag("restoreUser");
  const isSendNewInvitationEnabled = useFlag("sendNewInvitation");

  const statusToEnabledMap = {
    [USER_STATUS_INVITE_PENDING]:
      isCancelInvitationEnabled || isSendNewInvitationEnabled,
    [USER_STATUS_ACTIVE]: isEditAccessLevelEnabled || isDisableUserEnabled,
    [USER_STATUS_DEACTIVATED]: isRestoreUserEnabled,
    [USER_STATUS_INVITE_EXPIRED]:
      isSendNewInvitationEnabled || isCancelInvitationEnabled,
  };

  return statusToEnabledMap[status];
};

export default useIsUserActionAvailable;
