import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import throttle from "lodash.throttle";

export default () => {
  const [accessToken, setAccessToken] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const throttledGetAccessToken = useCallback(
    throttle(async () => {
      const token = await getAccessTokenSilently();

      setAccessToken(token);
    }, 60000),
    []
  );

  useIdleTimer({
    onAction: throttledGetAccessToken,
  });

  useEffect(() => {
    throttledGetAccessToken();
  }, []);

  return accessToken;
};
