export const formatCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
}).format;

const numberFormat = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

export const formatNumber = (value) => {
  const formattedValue = numberFormat.format(value);

  if (formattedValue.includes(".") && formattedValue.split(".")[1].length < 2) {
    return `${formattedValue}0`;
  }

  return formattedValue;
};

export const formatPhoneNumber = (value) => {
  let returnValue = "(";

  const charArray = value?.split("");

  new Array(10).fill()?.forEach((char, index) => {
    if (charArray?.[index] !== undefined) {
      returnValue = returnValue.concat(charArray[index]);
    } else {
      returnValue = returnValue.concat(" ");
    }

    if (index === 2) {
      returnValue = returnValue.concat(") ");
    } else if (index === 5) {
      returnValue = returnValue.concat("-");
    }
  });

  return returnValue;
};

export const formatInitials = (name = "") => {
  const names = name?.trim()?.split(" ");
  const firstNameLetter = names?.[0]?.[0]?.toUpperCase();
  const lastNameLetter = names?.[names?.length - 1]?.[0]?.toUpperCase();

  if (!names?.length) {
    return undefined;
  }

  if (names?.length === 1) {
    return firstNameLetter;
  }

  return `${firstNameLetter}${lastNameLetter}`;
};

export const formatArrayToCommaSentence = (strings) => {
  if (strings?.length > 2) {
    const copy = [...strings];
    const last = copy.pop();

    return `${copy.join(", ")}, and ${last}`;
  }

  return strings?.join(" and ");
};
