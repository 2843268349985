import { APPLICATION_JSON_ACCEPT, JSON_API_ACCEPT } from "utils";
import { REACT_APP_API_BASE_URL } from "../environment";
import jsonApiDeserialize from "../utils/json-api-deserialize";
import cipApi, {
  ADMIN_SETTINGS_TAG_TYPE,
  GLOBAL_PROJECT_TAG_TYPE,
} from "../../cip-api";

export const FETCH_PROJECTS_URL = `${REACT_APP_API_BASE_URL}/cip/global-projects`;

export const FETCH_PROJECT_INSTANCES_URL = `${REACT_APP_API_BASE_URL}/cip/scenarios/detailed-projects`;

const extendedApi = cipApi.injectEndpoints({
  endpoints: (build) => ({
    globalProjects: build.query({
      query: () => ({
        headers: {
          Accept: JSON_API_ACCEPT,
        },
        url: `/cip/global-projects?include=utilities,drivers`,
      }),
      providesTags: [GLOBAL_PROJECT_TAG_TYPE, ADMIN_SETTINGS_TAG_TYPE],
      transformResponse: (response) => jsonApiDeserialize(response),
    }),
    projectInstances: build.query({
      query: ({ projectId }) => ({
        headers: {
          Accept: APPLICATION_JSON_ACCEPT,
        },
        params: {
          globalProjectId: projectId,
          sort: "modifiedAt,DESC",
        },
        url: "/cip/scenarios/detailed-projects",
      }),
    }),
  }),
});

export const { useGlobalProjectsQuery, useProjectInstancesQuery } = extendedApi;

export const globalProjectsSelector =
  extendedApi.endpoints.globalProjects.select;
