/* eslint-disable prefer-destructuring */
// Destructuring won't work with dotEnv
const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
const REACT_APP_AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
const REACT_APP_AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_APP_NODE_API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL;
const REACT_APP_SUPPORT_EMAIL = "support@mmewater.com";
const REACT_APP_FEATURE_FLAG_CLIENT_KEY =
  process.env.REACT_APP_FEATURE_FLAG_CLIENT_KEY;
const REACT_APP_NODE_ENV = process.env.NODE_ENV;

export {
  REACT_APP_API_BASE_URL,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_FEATURE_FLAG_CLIENT_KEY,
  REACT_APP_NODE_ENV,
  REACT_APP_NODE_API_BASE_URL,
  REACT_APP_SUPPORT_EMAIL,
};
