import React, { useEffect } from "react";
import { grey } from "utils";
import { Button, Link, Stack, Typography } from "@mui/material";
import styled from "styled-components";
import Hotjar from "@hotjar/browser";
import { Fingerprint } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingPage from "./shared/components/Loading-Page";
import useAccessToken from "./shared/hooks/use-access-token";
import { setAccessToken } from "./shared/reducers/base-slice";
import {
  REACT_APP_NODE_ENV,
  REACT_APP_SUPPORT_EMAIL,
} from "./shared/environment";
import { text, typography } from "./shared/theme";

const Container = styled(Stack).attrs(() => ({
  alignItems: "center",
  spacing: 5,
}))`
  color: ${text.disabled};
  width: 442px;
`;

const PageWrapper = styled(Stack).attrs(() => ({
  alignItems: "center",
  justifyContent: "center",
}))`
  height: 100%;
  width: 100%;
`;

const HeavyText = styled.span`
  font-weight: ${typography.fontWeightBold};
`;

const BodyText = styled(Typography).attrs(() => ({
  component: "span",
  variant: "body1",
}))``;

const Ul = styled.ul`
  margin-block-start: 0;
`;

const Auth0Wrapper = ({ children }) => {
  const { isLoading, error } = useAuth0();

  if (isLoading) {
    return <LoadingPage shouldLeaveSpaceForAppBar={false} />;
  }

  const mailTo = `mailto:${REACT_APP_SUPPORT_EMAIL}`;

  if (error) {
    return (
      <PageWrapper>
        <Container>
          <Fingerprint sx={{ color: grey[300], fontSize: 156 }} />
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h6">Authentication Error</Typography>
            <Stack alignItems="center" spacing={3}>
              <BodyText>
                Something went wrong and your login can’t be completed. There
                are a few reasons this may have occurred:
                <Ul>
                  <li>You used an expired invitation link</li>
                  <li>Your account has been disabled</li>
                  <li>
                    You don’t have access to the organization you specified
                  </li>
                  <li>We experienced a technical issue on our end</li>
                </Ul>
                <HeavyText>What can you do?</HeavyText>
                <Ul>
                  <li>Return to the sign in page and try again</li>
                  <li>
                    Contact your program admin to make sure you’re using the
                    right organization code and a current invitation link
                  </li>
                  <li>
                    Write to{" "}
                    <Link href={mailTo}>{REACT_APP_SUPPORT_EMAIL}</Link> to
                    troubleshoot
                  </li>
                </Ul>
              </BodyText>
              <Stack direction="row" spacing={2}>
                <Button
                  component="a"
                  href={mailTo}
                  size="small"
                  variant="outlined"
                >
                  EMAIL SUPPORT
                </Button>
                <Button
                  component={Link}
                  href="/"
                  size="small"
                  variant="contained"
                >
                  RETRY LOGIN
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </PageWrapper>
    );
  }

  return children;
};

export const AccessTokenWrapper = ({ children }) => {
  const accessToken = useAccessToken();
  const dispatch = useDispatch();

  const { user } = useAuth0();

  useEffect(() => {
    dispatch(setAccessToken(accessToken));
  }, [accessToken]);

  useEffect(() => {
    // eslint-disable-next-line camelcase
    const { email, name, org_id, sub } = user || {};

    const isMMEEmployee = user?.email?.includes("@mmewater.com");
    const isProduction = REACT_APP_NODE_ENV === "production";

    if (isProduction && !isMMEEmployee) {
      Hotjar.init(3615903, 6);

      Hotjar.identify({
        email,
        name,
        orgId: org_id,
        sub,
      });
    }
  }, []);

  return children;
};

export default Auth0Wrapper;
