import React from "react";
import useResizeObserver from "use-resize-observer";
import styled from "styled-components";
import { MenuItem, Stack, Typography } from "@mui/material";
import {
  ADMIN_ACCESS_LEVEL,
  EDITOR_ACCESS_LEVEL,
  GLOBAL_EDITOR_ACCESS_LEVEL,
  VIEWER_ACCESS_LEVEL,
  accessLevelLabelMap,
} from "../../constants/user";
import TextField from "./Text-Field";
import { paperBorder, text } from "../../theme";
import AccessLevelBadge from "../user/Access-Level-Badge";
import { ACCESS_LEVEL_SELECT_OPTION } from "./constants";

const AccessLevelMenuItemDescription = styled(Typography).attrs(() => ({
  variant: "caption",
}))`
  color: ${text.secondary};
  white-space: wrap;
`;

const AccessLevelMenuItem = styled(MenuItem)`
  ${({ $shouldRenderBottomBorder }) =>
    $shouldRenderBottomBorder && `border-bottom: ${paperBorder};`}

  padding-bottom: 14px;
  padding-top: 14px;
`;

const accessLevelOptions = [
  {
    description:
      "Viewers have read-only access to all areas, including scenarios, global projects, and global settings.",
    value: VIEWER_ACCESS_LEVEL,
  },
  {
    description:
      "Editors can create, edit, and delete scenarios. They have read-only access to global settings and global projects.",
    value: EDITOR_ACCESS_LEVEL,
  },
  {
    description:
      "Global Editors can create, edit, and delete both scenarios and global projects. They have read-only access to global settings.",
    value: GLOBAL_EDITOR_ACCESS_LEVEL,
  },
  {
    description:
      "Administrators have top-level access with control over user accounts, global settings, global projects, and scenarios.",
    value: ADMIN_ACCESS_LEVEL,
  },
];

const AccessLevelSelect = ({ ...rest }) => {
  const { ref, width } = useResizeObserver();

  return (
    <TextField
      inputProps={{
        renderValue: (accessLevelValue) =>
          accessLevelLabelMap[accessLevelValue],
      }}
      select
      ref={ref}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {accessLevelOptions.map(
        ({ description, value: accessLevelValue }, index) => (
          <AccessLevelMenuItem
            $shouldRenderBottomBorder={index !== accessLevelOptions.length - 1}
            data-testid={`${ACCESS_LEVEL_SELECT_OPTION}-${accessLevelValue}`}
            key={accessLevelValue}
            style={{ width }}
            value={accessLevelValue}
          >
            <Stack spacing={0.5}>
              <AccessLevelBadge
                accessLevel={accessLevelValue}
                shouldDisableTooltip
              />
              <AccessLevelMenuItemDescription>
                {description}
              </AccessLevelMenuItemDescription>
            </Stack>
          </AccessLevelMenuItem>
        )
      )}
    </TextField>
  );
};

export default AccessLevelSelect;
