/* eslint-disable no-param-reassign */

import { createSelector, createSlice } from "@reduxjs/toolkit";
import { globalProjectsSelector } from "../../shared/api/global-projects";
import createProjectFilterSlice from "../../shared/components/table/project-filter-slice";
import createVirtualizedTableSlice from "../../shared/components/table/virtualized-table-slice";
import { NAME_COLUMN_ID } from "../../shared/constants/project";
import { ASCENDING_KEY } from "../../shared/constants/sorting";

const initialState = {
  projectIdsToInclude: [],
};

const includeProjectsSlice = createSlice({
  name: "includeProjects",
  initialState,
  reducers: {
    resetIncludeProjectsSlice: () => initialState,
    setProjectIdsToInclude: (state, action) => {
      state.projectIdsToInclude = action.payload;
    },
    setSelectedProjectId: (state, action) => {
      state.selectedProjectId = action.payload;
    },
  },
});

export const {
  setProjectIdsToInclude,
  setSelectedProjectId,
  resetIncludeProjectsSlice,
} = includeProjectsSlice.actions;

export default includeProjectsSlice;

/* eslint-enable no-param-reassign */

const getIncludeProjectsSlice = createSelector(
  (state) => state,
  (state) => state[includeProjectsSlice.name]
);

export const getSelectedProjectId = createSelector(
  getIncludeProjectsSlice,
  (slice) => slice.selectedProjectId
);

export const getProjectIdsToInclude = createSelector(
  getIncludeProjectsSlice,
  (slice) => slice.projectIdsToInclude || []
);

const getIsProjectIncludedMap = createSelector(
  getProjectIdsToInclude,
  (projectIdsToInclude) =>
    projectIdsToInclude?.reduce(
      (acc, id) => ({
        ...acc,
        [id]: true,
      }),
      {}
    )
);

export const getIsProjectSelected = createSelector(
  getSelectedProjectId,
  (state, { id }) => id,
  (selectedProjectId, projectId) => selectedProjectId === projectId
);

const getGlobalProjects = createSelector(
  (state) => state,
  (state) => globalProjectsSelector()(state)?.data
);

const {
  getFilteredProjects,
  projectFilterSlice: includeProjectsFilterSlice,
  useProjectFilterProps,
} = createProjectFilterSlice({
  name: "includeProjectsFilter",
  projectsSelector: getGlobalProjects,
});

export {
  getFilteredProjects,
  includeProjectsFilterSlice,
  useProjectFilterProps,
};

const getFilteredProjectsEnhancedWithIsIncluded = createSelector(
  getFilteredProjects,
  getIsProjectIncludedMap,
  (filteredProjects, isProjectIncludedMap) =>
    filteredProjects?.map((project) => ({
      ...project,
      isIncluded: !!isProjectIncludedMap?.[project.id],
    }))
);

const {
  getSortedItems,
  tableSlice: includeProjectsTableSlice,
  virtualizedTableProps,
} = createVirtualizedTableSlice({
  defaultSort: { id: NAME_COLUMN_ID, order: ASCENDING_KEY },
  name: "includeProjectsTableSlice",
  unsortedItemsSelector: getFilteredProjectsEnhancedWithIsIncluded,
});

export { getSortedItems, includeProjectsTableSlice, virtualizedTableProps };
