import { compareAsc, compareDesc } from "date-fns";
import { DESCENDING_KEY } from "../constants/sorting";

export const stringCompare = ({ a, b, order = DESCENDING_KEY }) => {
  if (order === DESCENDING_KEY) {
    return (b || "").localeCompare(a || "", "en", { sensitivity: "base" });
  }

  return (a || "").localeCompare(b || "", "en", { sensitivity: "base" });
};

export const floatCompare = ({ a, b, order = DESCENDING_KEY }) => {
  const aFloat = parseFloat(a) || 0;
  const bFloat = parseFloat(b) || 0;

  if (order === DESCENDING_KEY) {
    return bFloat - aFloat;
  }

  return aFloat - bFloat;
};

export const booleanCompare = ({ a, b, order = DESCENDING_KEY }) => {
  if (order === DESCENDING_KEY) {
    return b - a;
  }

  return a - b;
};

export const dateCompare = ({ a, b, order = DESCENDING_KEY }) => {
  const parseDateOrSetTo1 = (dateToParse) => {
    let dateToReturn = new Date(dateToParse);

    if (Number.isNaN(dateToReturn.getTime())) {
      dateToReturn = new Date();
      dateToReturn.setFullYear(1);
    }

    return dateToReturn;
  };

  const dateA = parseDateOrSetTo1(a);
  const dateB = parseDateOrSetTo1(b);

  if (order === DESCENDING_KEY) {
    return compareDesc(dateA, dateB);
  }

  return compareAsc(dateA, dateB);
};
