import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

async function enableMocking() {
  if (process.env.NODE_ENV !== "development") {
    return;
  }

  const { clientServer } = await import("./shared/utils/client-server");

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  await clientServer.start({
    quiet: true,
    onUnhandledRequest: "bypass",
  });
}

enableMocking().then(() => {
  ReactDOM.render(<App />, document.getElementById("app"));
});
