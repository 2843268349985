import React from "react";
import { SvgIcon } from "@mui/material";

export default (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props}>
    <svg
      fill="currentColor"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 14.2965V19H12V21H7V10.02H12V10.2744L8 14.2965Z" />
      <path d="M14 21V17.6704L19 12.6427V19C19 20.1 18.1 21 17 21H14Z" />
      <path d="M17 3C17.6103 3 18.1591 3.27706 18.5267 3.71163L14.2619 8H0V5C0 3.9 0.9 3 2 3H17Z" />
      <path d="M0 19C0 20.1 0.9 21 2 21H5V10H0V19Z" />
      <path d="M9 18V14.8754L18.2154 5.66005L21.34 8.78461L12.1246 18H9Z" />
      <path d="M23.7563 5.19345C24.0812 5.5184 24.0812 6.04333 23.7563 6.36828L22.2315 7.89307L19.1069 4.7685L20.6317 3.24372C20.9567 2.91876 21.4816 2.91876 21.8066 3.24372L23.7563 5.19345Z" />
    </svg>
  </SvgIcon>
);
