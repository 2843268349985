import React from "react";
import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import styled from "styled-components";
import { text, typography } from "../../theme";

const Container = styled(Stack).attrs(() => ({
  direction: "row",
  justifyContent: "center",
}))`
  left: 0;
  padding: 16px;
  position: sticky;
`;

const NoResultsMessage = styled(Typography).attrs(() => ({ variant: "body1" }))`
  color: ${text.primary};
  font-weight: ${typography.fontWeightMedium};
`;

const NoResults = ({ children }) => (
  <Container>
    <NoResultsMessage>{children}</NoResultsMessage>
  </Container>
);

NoResults.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NoResults;
