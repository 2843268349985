import { USER_STATUS_ACTIVE } from "../../shared/constants/user";

export const USER_MANAGEMENT_TABLE_ERROR_TEXT = "Failed to load users";
export const USER_MANAGEMENT_TABLE_ROW_TEST_ID = "user-management-table-row";

export const USER_MANAGEMENT_COLUMN_CONFIGURATION_LOCAL_STORAGE_KEY =
  "user-management-column-configuration";
export const USER_MANAGEMENT_COLUMN_CONFIGURATION_TITLE = "Select User Columns";

export const USER_MANAGEMENT_TABLE_SEARCH_PLACEHOLDERTEXT =
  "Search all users...";

export const USER_MANAGEMENT_INVITE_USER_BUTTON_TEXT = "INVITE NEW USER";

export const USER_MANAGEMENT_INVITE_USER_DIALOG_TITLE = "Invite new user";
export const USER_MANAGEMENT_INVITE_USER_SUBMIT_BUTTON_TEXT =
  "SEND INVITATION EMAIL";

export const USER_MANAGEMENT_ACCESS_LEVEL_LABEL_TEXT = "Access Level";
export const USER_MANAGEMENT_INVITE_USER_ERROR_TEXT = "Failed to invite user";

export const USER_MANAGEMENT_INVITE_USER_DIALOG_TEST_ID = "invite-user-dialog";

export const USER_MANAGEMENT_INVITE_USER_CLOSE_TEST_ID = "close-invite-user";

export const USER_MANAGEMENT_ACTIONS_CELL_TEST_ID = "user-management-actions";

export const USER_STATUS_ACTIVE_BUTTON_TEXT = "Active";
export const USER_STATUS_INVITE_PENDING_BUTTON_TEXT = "Invite Pending";
export const USER_STATUS_DEACTIVATED_BUTTON_TEXT = "Deactivated";
export const USER_STATUS_INVITE_EXPIRED_BUTTON_TEXT = "Invite Expired";

export const USER_STATUS_BUTTON_ICON_TEST_ID = "user-status-icon";

export const DISABLED_OPACITY_CONTAINER_TEST_ID =
  "user-disabled-opacity-container";

export const checkIsUserNotActive = ({ status }) =>
  status !== USER_STATUS_ACTIVE;
