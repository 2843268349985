import { max, sum } from "d3";
import { REACT_APP_API_BASE_URL } from "../shared/environment";
import cipApi, {
  ADMIN_SETTINGS_TAG_TYPE,
  SCENARIO_PROJECT_TAG_TYPE,
  SCENARIO_TAG_TYPE,
} from "../cip-api";

export const FETCH_STACKED_BAR_URL = `${REACT_APP_API_BASE_URL}/cip/scenarios/:scenarioId/charts/stacked-bar-chart`;

export const transformColumnData = (columnData) =>
  columnData.map(({ year, projectCosts }) => ({
    year,
    ...projectCosts.reduce(
      (acc, { cost, projectId }) => ({
        ...acc,
        [projectId]: cost,
      }),
      {}
    ),
  }));

export const calculateMaxYFromTransformedColumnData = (
  transformedColumnData
) => {
  if (!transformedColumnData) {
    return 0;
  }

  const columnsWithoutYears = transformedColumnData?.map((column) => {
    const { year, ...costs } = column;

    return { ...costs };
  });

  return max(
    columnsWithoutYears?.map((column) =>
      sum(Object.values(column), (cost) => parseFloat(cost))
    )
  );
};

export const previewColumnData = ({
  columnData,
  newEndYear,
  previewCosts,
  projectId,
}) => {
  const previewCostsForYear = previewCosts?.[newEndYear];

  return newEndYear
    ? columnData
        ?.map((column) => {
          const copy = { ...column };

          delete copy[projectId];

          return copy;
        })
        .map((column) => {
          const { year } = column;

          const costForYear = previewCostsForYear?.[year];

          if (costForYear) {
            return {
              ...column,
              [projectId]: costForYear,
            };
          }

          return column;
        })
    : columnData;
};

export const previewEnableOrDisable = ({ columnData, enabled, project }) =>
  columnData.map((column) => {
    const copy = { ...column };

    const { year } = column;

    const costsByYear = project?.projectCosts?.escalatedCostByYear;

    const projectCostForYear = costsByYear[year];

    if (!enabled) {
      delete copy[project.id];
    } else if (projectCostForYear) {
      copy[project.id] = projectCostForYear;
    }

    return copy;
  });

export const FETCH_COST_PREVIEW_URL = `${REACT_APP_API_BASE_URL}/cip/scenarios/:scenarioId/projects/:projectId/costs-preview`;

const extendedApi = cipApi.injectEndpoints({
  endpoints: (build) => ({
    costPreview: build.query({
      query: ({ projectId, scenarioId }) => ({
        url: `/cip/scenarios/${scenarioId}/projects/${projectId}/costs-preview`,
      }),
      providesTags: [
        ADMIN_SETTINGS_TAG_TYPE,
        SCENARIO_PROJECT_TAG_TYPE,
        SCENARIO_TAG_TYPE,
      ],
    }),
    stackedBar: build.query({
      query: ({ endYear, scenarioId, startYear }) => ({
        params: {
          endYear,
          startYear,
        },
        url: `/cip/scenarios/${scenarioId}/charts/stacked-bar-chart`,
      }),
      providesTags: [
        ADMIN_SETTINGS_TAG_TYPE,
        SCENARIO_PROJECT_TAG_TYPE,
        SCENARIO_TAG_TYPE,
      ],
      transformResponse: (response) => transformColumnData(response.columnData),
    }),
  }),
});

export const { useCostPreviewQuery, useStackedBarQuery } = extendedApi;

export const stackedBarSelector = extendedApi.endpoints.stackedBar.select;
export const costPreviewSelector = extendedApi.endpoints.costPreview.select;
