import { createSelector, createSlice } from "@reduxjs/toolkit";
import intersection from "lodash.intersection";
import {
  PROJECT_FILTER_APPROVAL_OPTION_ALL_PROJECTS,
  PROJECT_FILTER_APPROVAL_OPTION_APPROVED,
  PROJECT_FILTER_APPROVAL_OPTION_NON_APPROVED,
  PROJECT_FILTER_COST_OPTION_PROJECTED_COST_LABEL,
  PROJECT_FILTER_COST_OPTION_TODAYS_COST_LABEL,
  PROJECT_FILTER_COST_OPTION_TOTAL_COST_LABEL,
  PROJECT_FILTER_YEAR_OPTION_END_YEAR_LABEL,
  PROJECT_FILTER_YEAR_OPTION_MAX_END_YEAR_LABEL,
  PROJECT_FILTER_YEAR_OPTION_START_YEAR_LABEL,
} from "./constants";
import {
  PROJECT_APPROVAL_APPROVED,
  PROJECT_END_YEAR_COLUMN_ID,
  PROJECT_MAX_END_YEAR_COLUMN_ID,
  PROJECT_START_YEAR_COLUMN_ID,
  PROJECT_TODAYS_COST_COLUMN_ID,
  PROJECT_TOTAL_COST_COLUMN_ID,
  PROJECT_TOTAL_COST_ESCALATED_COLUMN_ID,
} from "../../constants/project";
import { doValuesMatchSearch } from "../../utils/filtering";
import {
  PROJECT_APPROVAL_STATUS_LABEL_TEXT,
  PROJECT_UTILITY_LABEL_TEXT,
} from "../../../projects/change-project/constants";
import { PROJECTS_TABLE_DURATION_LABEL } from "./columns/constants";

export const costOptions = [
  {
    label: PROJECT_FILTER_COST_OPTION_PROJECTED_COST_LABEL,
    value: PROJECT_TOTAL_COST_ESCALATED_COLUMN_ID,
  },
  {
    label: PROJECT_FILTER_COST_OPTION_TODAYS_COST_LABEL,
    value: PROJECT_TODAYS_COST_COLUMN_ID,
  },
  {
    label: PROJECT_FILTER_COST_OPTION_TOTAL_COST_LABEL,
    value: PROJECT_TOTAL_COST_COLUMN_ID,
  },
];

export const yearOptions = [
  {
    label: PROJECT_FILTER_YEAR_OPTION_END_YEAR_LABEL,
    value: PROJECT_END_YEAR_COLUMN_ID,
  },
  {
    label: PROJECT_FILTER_YEAR_OPTION_MAX_END_YEAR_LABEL,
    value: PROJECT_MAX_END_YEAR_COLUMN_ID,
  },
  {
    label: PROJECT_FILTER_YEAR_OPTION_START_YEAR_LABEL,
    value: PROJECT_START_YEAR_COLUMN_ID,
  },
];

export const approvalOptions = [
  PROJECT_FILTER_APPROVAL_OPTION_ALL_PROJECTS,
  PROJECT_FILTER_APPROVAL_OPTION_APPROVED,
  PROJECT_FILTER_APPROVAL_OPTION_NON_APPROVED,
];

export const defaultValues = {
  approvalStatus: approvalOptions[0],
  costOption: costOptions[0],
  costRange: [null, null],
  drivers: [],
  durationRange: [null, null],
  keyword: "",
  utilities: [],
  yearOption: yearOptions[0],
  yearRange: [null, null],
};

const initialState = {
  search: "",
};

const createProjectFilterSlice = ({ name, projectsSelector }) => {
  /* eslint-disable no-param-reassign */
  const projectFilterSlice = createSlice({
    name,
    initialState,
    reducers: {
      resetProjectFilters: () => initialState,
      setDelayedFormValues: (state, action) => {
        state.delayedFormValues = action.payload;
      },
      setSubmittedFormValues: (state, action) => {
        state.submittedFormValues = action.payload;
      },
      setSearch: (state, action) => {
        state.search = action.payload;
      },
    },
  });
  /* eslint-enable no-param-reassign */

  const {
    resetProjectFilters,
    setDelayedFormValues,
    setSubmittedFormValues,
    setSearch,
  } = projectFilterSlice.actions;

  const getProjectFilterSlice = createSelector(
    (state) => state,
    (state) => state[projectFilterSlice.name]
  );

  const getDelayedFormValues = createSelector(
    getProjectFilterSlice,
    (slice) => slice.delayedFormValues
  );

  const getSubmittedFormValues = createSelector(
    getProjectFilterSlice,
    (slice) => slice.submittedFormValues
  );

  const getSearch = createSelector(
    getProjectFilterSlice,
    (slice) => slice.search
  );

  const filterBySearch = (projects, searchKey) =>
    projects?.filter(({ key, name: projectName, notes }) =>
      doValuesMatchSearch(searchKey, [key, projectName, notes])
    );

  const filterProjects = ({ formValues, keyword, projects }) => {
    if (!formValues) {
      return filterBySearch(projects, keyword);
    }

    const {
      approvalStatus,
      costOption,
      costRange,
      drivers: formDrivers,
      durationRange,
      utilities: formUtilities,
      yearOption,
      yearRange,
    } = formValues;
    const { value: costKey } = costOption;
    const [costStart, costEnd] = costRange;

    const { value: yearKey } = yearOption;
    const [yearStart, yearEnd] = yearRange;

    const [durationStart, durationEnd] = durationRange;

    return filterBySearch(projects, keyword)?.filter((project) => {
      const cost = parseFloat(project[costKey]);

      const {
        allocationByDriverId,
        approval,
        duration,
        utility: { id: utilityId },
      } = project;

      const isWithinUtilities =
        !formUtilities.length || formUtilities.includes(utilityId);

      const projectDrivers = Object.keys(allocationByDriverId || {});

      const isWithinDrivers =
        !formDrivers.length ||
        !!intersection(formDrivers, projectDrivers).length;

      const year = project[yearKey];

      const isWithinRange = ({ end, start, value }) => {
        if (start !== null && value < start) {
          return false;
        }

        if (end !== null && value > end) {
          return false;
        }

        return true;
      };

      const isWithinCostRange = isWithinRange({
        end: costEnd,
        start: costStart,
        value: cost,
      });

      const isWithinYearRange = isWithinRange({
        end: yearEnd,
        start: yearStart,
        value: year,
      });

      const isWithinDurationRange = isWithinRange({
        end: durationEnd,
        start: durationStart,
        value: duration,
      });

      let isWithinApprovalStatus;

      switch (approvalStatus) {
        case PROJECT_FILTER_APPROVAL_OPTION_APPROVED:
          isWithinApprovalStatus = approval === PROJECT_APPROVAL_APPROVED;
          break;

        case PROJECT_FILTER_APPROVAL_OPTION_NON_APPROVED:
          isWithinApprovalStatus = approval !== PROJECT_APPROVAL_APPROVED;
          break;

        default:
          isWithinApprovalStatus = true;
      }

      return (
        isWithinCostRange &&
        isWithinDrivers &&
        isWithinUtilities &&
        isWithinYearRange &&
        isWithinDurationRange &&
        isWithinApprovalStatus
      );
    });
  };

  const getDelayedNumberOfPotentialFilteredProjects = createSelector(
    projectsSelector,
    getDelayedFormValues,
    (projects, delayedFormValues) =>
      filterProjects({
        formValues: delayedFormValues,
        keyword: delayedFormValues?.keyword,
        projects,
      })?.length || 0
  );

  const getFilteredProjects = createSelector(
    projectsSelector,
    getSubmittedFormValues,
    getSearch,
    (projects, submittedFormValues, search) =>
      filterProjects({
        formValues: submittedFormValues,
        keyword: search,
        projects,
      })
  );

  const getActiveFilterLabels = createSelector(
    getSubmittedFormValues,
    (submittedFormValues) => {
      const activeFilterLabels = [];

      if (!submittedFormValues) {
        return activeFilterLabels;
      }

      const {
        approvalStatus: submittedApprovalStatus,
        costOption: { label: costLabel },
        drivers: submittedDrivers,
        utilities: submittedUtilities,
        yearOption: { label: yearLabel },
      } = submittedFormValues;

      const {
        approvalStatus: defaultApprovalStatus,
        drivers: defaultDrivers,
        utilities: defaultUtilities,
      } = defaultValues;

      if (defaultUtilities.length !== submittedUtilities.length) {
        activeFilterLabels.push(PROJECT_UTILITY_LABEL_TEXT);
      }

      if (defaultApprovalStatus !== submittedApprovalStatus) {
        activeFilterLabels.push(PROJECT_APPROVAL_STATUS_LABEL_TEXT);
      }

      if (defaultDrivers.length !== submittedDrivers.length) {
        activeFilterLabels.push("Driver");
      }

      const rangeKeys = [
        ["costRange", costLabel],
        ["yearRange", yearLabel],
        ["durationRange", PROJECTS_TABLE_DURATION_LABEL],
      ];

      rangeKeys.forEach(([rangeKey, label]) => {
        const [submittedStart, submittedEnd] = submittedFormValues[rangeKey];
        const [defaultStart, defaultEnd] = defaultValues[rangeKey];

        if (submittedStart !== defaultStart || submittedEnd !== defaultEnd) {
          activeFilterLabels.push(label);
        }
      });

      return activeFilterLabels;
    }
  );

  const getNumberOfActiveFilters = createSelector(
    getActiveFilterLabels,
    (activeFilterLabels) => activeFilterLabels?.length || 0
  );

  return {
    getFilteredProjects,
    projectFilterSlice,
    useProjectFilterProps: {
      getActiveFilterLabels,
      getFilteredProjects,
      getUnfilteredProjects: projectsSelector,
      getDelayedNumberOfPotentialFilteredProjects,
      getNumberOfActiveFilters,
      getSubmittedFormValues,
      projectFilterSlice,
      resetProjectFilters,
      setDelayedFormValues,
      setSubmittedFormValues,
      setSearch,
    },
  };
};

export default createProjectFilterSlice;
