export const CIP_PATH = "/cip";
export const ADMIN_PATH = "/admin";
export const EDIT_PROFILE_PATH = "/edit-profile";
export const REPORTS_PATH = "/reports";
export const SETUP_PATH = "/model-setup";
export const SCENARIOS_PATH = "/scenarios";
export const PROJECTS_PATH = "/projects";
export const SCENARIO_PROJECTS_PATH = "/projects";
export const CREATE_PROJECT_PATH = "/create-project";
export const SETTINGS_PATH = "/settings";
export const EDIT_PROJECT_BASE_PATH = "/edit-project";
export const DUPLICATE_PROJECT_BASE_PATH = "/duplicate-project";
export const MODEL_SETUP_NAVIGATION_INDICES_PATH = `${SETUP_PATH}/escalations`;
export const MODEL_SETUP_SCENARIO_DETAILS_PATH = `${SETUP_PATH}/scenario-details`;
export const MODEL_SETUP_NAVIGATION_EXPORT_PATH = `${SETUP_PATH}/export`;
export const SETTINGS_INDICES_PATH = `${SETTINGS_PATH}/escalations`;
export const SETTINGS_PROGRAM_SETUP_PATH = `${SETTINGS_PATH}/program-setup`;
export const SETTINGS_EXPORT_PATH = `${SETTINGS_PATH}/export`;
export const SETTINGS_USER_MANAGEMENT_PATH = `${SETTINGS_PATH}/user-management`;

export const scenarioPaths = [
  `${SCENARIOS_PATH}/:scenario`,
  SCENARIOS_PATH,
  "/",
];

export const projectPaths = [`${PROJECTS_PATH}/:project`, PROJECTS_PATH];
export const settingsPaths = [
  SETTINGS_INDICES_PATH,
  SETTINGS_PROGRAM_SETUP_PATH,
  SETTINGS_EXPORT_PATH,
  SETTINGS_USER_MANAGEMENT_PATH,
];

export const addScenarioRouteParam = (path) => `/:scenario${path}`;

export const scenarioProjectsPaths = [
  addScenarioRouteParam(SCENARIO_PROJECTS_PATH),
  `${addScenarioRouteParam(SCENARIO_PROJECTS_PATH)}/:project`,
];

export const modelSetupPaths = [
  addScenarioRouteParam(MODEL_SETUP_NAVIGATION_INDICES_PATH),
  addScenarioRouteParam(MODEL_SETUP_SCENARIO_DETAILS_PATH),
  addScenarioRouteParam(MODEL_SETUP_NAVIGATION_EXPORT_PATH),
];
