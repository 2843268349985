import React from "react";
import PropTypes from "prop-types";
import {
  DialogTitle as MuiDialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const DialogTitle = ({ closeTestId, handleClose, title }) => (
  <Stack
    alignItems="center"
    component={MuiDialogTitle}
    direction="row"
    justifyContent="space-between"
  >
    {title}
    <IconButton onClick={handleClose} size="small" data-testid={closeTestId}>
      <Close />
    </IconButton>
  </Stack>
);

DialogTitle.propTypes = {
  closeTestId: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

DialogTitle.defaultProps = {
  closeTestId: undefined,
};

export default DialogTitle;
