import { APPLICATION_JSON_ACCEPT, JSON_API_ACCEPT } from "utils";
import { REACT_APP_API_BASE_URL } from "../environment";
import jsonApiDeserialize from "../utils/json-api-deserialize";
import cipApi, {
  ADMIN_SETTINGS_TAG_TYPE,
  SCENARIO_PROJECT_TAG_TYPE,
  SCENARIO_TAG_TYPE,
} from "../../cip-api";
import { finishedReload, needsReload } from "../reducers/request-slice";

export const FETCH_SCENARIO_PROJECTS_URL = `${REACT_APP_API_BASE_URL}/cip/scenarios/:scenario/projects`;

export const PATCH_SCENARIO_PROJECT_URL = `${REACT_APP_API_BASE_URL}/scenarios/:scenarioId/projects/:projectId`;

export const SYNC_SCENARIO_PROJECTS_URL = `${REACT_APP_API_BASE_URL}/scenarios/:scenarioId/sync`;

export const patchScenarioProjectBase = {
  invalidatesTags: [SCENARIO_PROJECT_TAG_TYPE],
  query: ({ body, id, scenarioId }) => ({
    url: `/scenarios/${scenarioId}/projects/${id}`,
    method: "PATCH",
    body,
  }),
};

export const INCLUDE_EXCLUDE_SCENARIO_PROJECTS_URL = `${REACT_APP_API_BASE_URL}/scenarios/:scenarioId/roster`;

export const syncScenarioProjectRequestSliceKey = "sync-scenario-project";

const extendedApi = cipApi.injectEndpoints({
  endpoints: (build) => ({
    enableOrDisableScenarioProject: build.mutation({
      ...patchScenarioProjectBase,
      async onQueryStarted(
        { body, id: projectId, scenarioId },
        { dispatch, queryFulfilled }
      ) {
        const enabled = body?.enabled;

        const patchScenarioProjectsResult = dispatch(
          cipApi.util.updateQueryData(
            "scenarioProjects",
            { scenarioId },
            (old) =>
              old?.map((currentProject) => {
                if (currentProject?.id === projectId) {
                  const propsToUpdate = {
                    enabled,
                  };

                  return {
                    ...currentProject,
                    ...propsToUpdate,
                  };
                }
                return currentProject;
              })
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchScenarioProjectsResult.undo();
        }
      },
    }),
    includeExcludeScenarioProjects: build.mutation({
      invalidatesTags: [SCENARIO_PROJECT_TAG_TYPE],
      async onQueryStarted(
        { idsToExclude, scenarioId },
        { dispatch, queryFulfilled }
      ) {
        const patchScenarioProjectsResult = dispatch(
          cipApi.util.updateQueryData(
            "scenarioProjects",
            { scenarioId },
            (old) =>
              old?.filter(
                ({ globalProjectId }) =>
                  !idsToExclude?.includes(globalProjectId)
              )
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchScenarioProjectsResult?.undo();
        }
      },
      query: ({ idsToInclude = [], idsToExclude = [], scenarioId }) => ({
        body: {
          excludedGlobalProjectIds: idsToExclude,
          includedGlobalProjectIds: idsToInclude,
        },
        headers: {
          Accept: APPLICATION_JSON_ACCEPT,
          "Content-Type": APPLICATION_JSON_ACCEPT,
        },
        method: "POST",
        url: `/scenarios/${scenarioId}/roster`,
      }),
    }),
    patchScenarioProject: build.mutation(patchScenarioProjectBase),
    scenarioProjects: build.query({
      query: ({ scenarioId }) => ({
        headers: {
          Accept: JSON_API_ACCEPT,
        },
        url: `/cip/scenarios/${scenarioId}/projects?include=drivers,utilities`,
      }),
      providesTags: [
        SCENARIO_PROJECT_TAG_TYPE,
        ADMIN_SETTINGS_TAG_TYPE,
        SCENARIO_TAG_TYPE,
      ],
      transformResponse: (response) => jsonApiDeserialize(response),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(finishedReload(syncScenarioProjectRequestSliceKey));
        } catch {
          // do nothing
        }
      },
    }),
    syncScenarioProjects: build.mutation({
      invalidatesTags: [SCENARIO_PROJECT_TAG_TYPE],
      query: ({ scenarioId, scenarioProjectIds, shouldPreserveEndYears }) => ({
        body: {
          scenarioProjectIds,
          shouldPreserveEndYears,
        },
        headers: {
          Accept: APPLICATION_JSON_ACCEPT,
          "Content-Type": APPLICATION_JSON_ACCEPT,
        },
        method: "POST",
        url: `/scenarios/${scenarioId}/sync`,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          // do nothing
        }

        dispatch(needsReload(syncScenarioProjectRequestSliceKey));
      },
    }),
  }),
});

export const {
  useEnableOrDisableScenarioProjectMutation,
  useIncludeExcludeScenarioProjectsMutation,
  usePatchScenarioProjectMutation,
  useScenarioProjectsQuery,
  useSyncScenarioProjectsMutation,
} = extendedApi;

export const scenarioProjectsSelector =
  extendedApi.endpoints.scenarioProjects.select;
