import { createSelector, createSlice } from "@reduxjs/toolkit";

/* eslint-disable no-param-reassign */
const requestSlice = createSlice({
  name: "request",
  initialState: {},
  reducers: {
    finishedReload: (state, action) => {
      state[action.payload] = false;
    },
    needsReload: (state, action) => {
      state[action.payload] = true;
    },
  },
});

/* eslint-enable no-param-reassign */

export default requestSlice;

export const { finishedReload, needsReload } = requestSlice.actions;

const getRequestSlice = createSelector(
  (state) => state[requestSlice.name],
  (slice) => slice
);

export const getDoesRequestNeedReload = createSelector(
  getRequestSlice,
  (state, requestKey) => requestKey,
  (slice, requestKey) => slice[requestKey]
);
