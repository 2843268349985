import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import { tooltipDelayProps } from "../theme";

const TooltipOrFragment = ({ children, title, ...rest }) => {
  const ComponentToRender = title ? Tooltip : Fragment;

  return (
    <ComponentToRender
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(title ? { title, ...tooltipDelayProps, ...rest } : {})}
    >
      {children}
    </ComponentToRender>
  );
};

TooltipOrFragment.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

TooltipOrFragment.defaultProps = {
  title: undefined,
};

export default TooltipOrFragment;
