import React from "react";
import PropTypes from "prop-types";
import TooltipOrFragment from "./Tooltip-Or-Fragment";
import { tooltipInstantProps } from "../theme";

const AccessTooltip = ({ title, ...rest }) => (
  <TooltipOrFragment
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...tooltipInstantProps}
    disableInteractive
    title={title}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  />
);

AccessTooltip.propTypes = {
  title: PropTypes.string,
};

AccessTooltip.defaultProps = {
  title: undefined,
};

export default AccessTooltip;
