import { configureStore } from "@reduxjs/toolkit";
import cipSlice, {
  cipProjectFilterSlice,
  cipTableSlice,
} from "./cip/cip-slice";
import cipApi from "./cip-api";
import baseSlice from "./shared/reducers/base-slice";
import requestSlice from "./shared/reducers/request-slice";
import globalProjectsSlice, {
  globalProjectsFilterSlice,
  globalProjectsTableSlice,
} from "./projects/projects-slice";
import scenarioProjectsSlice, {
  scenarioProjectsProjectFilterSlice,
  scenarioProjectsTableSlice,
} from "./scenario-projects/scenario-projects-slice";
import includeProjectsSlice, {
  includeProjectsFilterSlice,
  includeProjectsTableSlice,
} from "./scenario-projects/include-projects/include-projects-slice";
import userManagementSlice, {
  userManagementTableSlice,
} from "./settings/users/user-management-slice";
import layoutSlice from "./layout/layout-slice";

export const createReduxStore = ({ testReducers } = {}) =>
  configureStore({
    devTools: {
      actionSanitizer: (action) =>
        action.type.includes(cipApi.reducerPath)
          ? { type: action.type }
          : action,
      stateSanitizer: (state) => ({
        ...state,
        cipApi: undefined,
      }),
    },
    reducer: {
      [cipSlice.name]: cipSlice.reducer,
      [baseSlice.name]: baseSlice.reducer,
      [cipApi.reducerPath]: cipApi.reducer,
      [requestSlice.name]: requestSlice.reducer,
      [cipProjectFilterSlice.name]: cipProjectFilterSlice.reducer,
      [cipTableSlice.name]: cipTableSlice.reducer,
      [globalProjectsFilterSlice.name]: globalProjectsFilterSlice.reducer,
      [globalProjectsTableSlice.name]: globalProjectsTableSlice.reducer,
      [globalProjectsSlice.name]: globalProjectsSlice.reducer,
      [scenarioProjectsSlice.name]: scenarioProjectsSlice.reducer,
      [scenarioProjectsProjectFilterSlice.name]:
        scenarioProjectsProjectFilterSlice.reducer,
      [scenarioProjectsTableSlice.name]: scenarioProjectsTableSlice.reducer,
      [includeProjectsSlice.name]: includeProjectsSlice.reducer,
      [includeProjectsFilterSlice.name]: includeProjectsFilterSlice.reducer,
      [includeProjectsTableSlice.name]: includeProjectsTableSlice.reducer,
      [userManagementTableSlice.name]: userManagementTableSlice.reducer,
      [userManagementSlice.name]: userManagementSlice.reducer,
      [layoutSlice.name]: layoutSlice.reducer,
      ...testReducers,
    },
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
      cipApi.middleware,
    ],
  });

const reduxStore = createReduxStore();

export default reduxStore;
