import { Email, MoreVert, Phone } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  DialogContent,
  Divider,
  Link,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { text } from "../../theme";
import { formatPhoneNumber } from "../../utils/formatting";
import AccessLevelBadge from "./Access-Level-Badge";
import refPropType from "../../prop-types/ref";
import {
  BigAvatar,
  InvitationSent,
  LastLogin,
  MemberSince,
  TimestampStack,
} from "./shared";
import IconButton from "../Icon-Button";
import {
  USER_STATUS_ACTIVE,
  atLeastAdminAccessLevels,
} from "../../constants/user";
import UserActionsMenu from "./User-Actions-Menu";
import usePopover from "../../hooks/use-popover";
import {
  USER_POPOVER_ACTIONS_MENU_TEST_ID,
  USER_POPOVER_VIEW_IN_DIRECTORY_LINK_TEXT,
} from "./constants";
import UserStatus from "../../../settings/users/User-Status";
import { canISeeThisUserInUsersTable } from "../../api/profile";
import { SETTINGS_USER_MANAGEMENT_PATH } from "../../constants/routes";
import { displaySnackbarMessage } from "../../../layout/layout-slice";

const disabledText = css`
  ${({ $disabled }) => $disabled && `color: ${text.disabled};`}
`;

const NameTypography = styled(Typography).attrs(() => ({ variant: "h5" }))`
  color: ${text.primary};
  ${disabledText}
`;

const JobTitleTypography = styled(Typography).attrs(() => ({
  variant: "subtitle2",
}))`
  color: ${text.secondary};
  ${disabledText}
`;

const AvatarStack = styled(Stack).attrs(() => ({
  alignItems: "center",
  spacing: 1,
}))`
  ${({ $disabled }) => $disabled && "opacity: .38;"}
`;

const UserPopover = ({
  anchorEl,
  onClose,
  open,
  profile,
  shouldHideActions,
  shouldHideDirectoryLink,
}) => {
  const {
    accessLevel,
    avatarColor,
    memberSince,
    email,
    id,
    invitedAt,
    fullName,
    jobTitle,
    lastLogin,
    phoneNumber,
    status,
  } = profile || {};

  const dispatch = useDispatch();

  const shouldShowViewInDirectoryLink =
    useSelector((state) => canISeeThisUserInUsersTable(state, profile)) &&
    !shouldHideDirectoryLink;

  const {
    popoverAnchor,
    handleClickOpenPopover,
    handleClosePopover,
    isPopoverOpen,
  } = usePopover();

  const isUserActive = !status || status === USER_STATUS_ACTIVE;

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        onClose={onClose}
        open={open}
      >
        <DialogContent>
          <Stack direction="row" spacing={4}>
            <AvatarStack $disabled={!isUserActive}>
              <BigAvatar avatarColorKey={avatarColor} fullName={fullName} />
              <AccessLevelBadge accessLevel={accessLevel} />
            </AvatarStack>
            <Stack spacing={1.5}>
              <Stack
                alignItems="flex-start"
                direction="row"
                justifyContent="space-between"
              >
                <Stack>
                  <NameTypography $disabled={!isUserActive}>
                    {fullName}
                  </NameTypography>
                  <JobTitleTypography $disabled={!isUserActive}>
                    {jobTitle}
                  </JobTitleTypography>
                </Stack>
                {!shouldHideActions && (
                  <>
                    <IconButton
                      accessLevelsRequired={atLeastAdminAccessLevels}
                      data-testid={USER_POPOVER_ACTIONS_MENU_TEST_ID}
                      onClick={handleClickOpenPopover}
                    >
                      <MoreVert />
                    </IconButton>
                    <UserActionsMenu
                      anchorEl={popoverAnchor}
                      handleClose={handleClosePopover}
                      isOpen={isPopoverOpen}
                      onActionSuccess={onClose}
                      profile={profile}
                    />
                  </>
                )}
              </Stack>
              <TimestampStack>
                <MemberSince timestamp={memberSince} />
                <LastLogin timestamp={lastLogin} />
                <InvitationSent timestamp={invitedAt} />
                {shouldShowViewInDirectoryLink && (
                  <Link
                    component={RouterLink}
                    to={{
                      pathname: SETTINGS_USER_MANAGEMENT_PATH,
                      state: {
                        scrollToId: id,
                      },
                    }}
                    variant="caption"
                  >
                    {USER_POPOVER_VIEW_IN_DIRECTORY_LINK_TEXT}
                  </Link>
                )}
              </TimestampStack>
              {!isUserActive && (
                <UserStatus onActionSuccess={onClose} profile={profile} />
              )}
              <Divider />
              <Stack direction="row" spacing={1}>
                {phoneNumber && (
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();

                      navigator.clipboard.writeText(phoneNumber);
                      dispatch(
                        displaySnackbarMessage(
                          "Phone number copied to clipboard"
                        )
                      );
                    }}
                    startIcon={<Phone />}
                    variant="outlined"
                  >
                    {formatPhoneNumber(phoneNumber)}
                  </Button>
                )}
                <Button
                  onClick={(event) => {
                    event.stopPropagation();

                    navigator.clipboard.writeText(email);
                    dispatch(
                      displaySnackbarMessage("Email copied to clipboard")
                    );
                  }}
                  startIcon={<Email />}
                  variant="outlined"
                >
                  {email}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
      </Popover>
    </>
  );
};

UserPopover.propTypes = {
  anchorEl: refPropType,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  profile: PropTypes.shape({
    accessLevel: PropTypes.string,
    avatarColor: PropTypes.string,
    memberSince: PropTypes.string,
    email: PropTypes.string,
    invitedAt: PropTypes.string,
    fullName: PropTypes.string,
    jobTitle: PropTypes.string,
    lastLogin: PropTypes.string,
    phoneNumber: PropTypes.string,
  }).isRequired,
  shouldHideActions: PropTypes.bool,
  shouldHideDirectoryLink: PropTypes.bool,
};

UserPopover.defaultProps = {
  anchorEl: undefined,
  shouldHideActions: false,
  shouldHideDirectoryLink: false,
};

export default UserPopover;
