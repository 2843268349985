import React, { Suspense, lazy } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Layout from "./layout";
import {
  ADMIN_PATH,
  CIP_PATH,
  REPORTS_PATH,
  SETUP_PATH,
  scenarioPaths,
  projectPaths,
  addScenarioRouteParam,
  settingsPaths,
  SCENARIO_PROJECTS_PATH,
  scenarioProjectsPaths,
  EDIT_PROFILE_PATH,
} from "./shared/constants/routes";
import BodyPaper from "./shared/components/BodyPaper";
import LoadingPage from "./shared/components/Loading-Page";
import { AccessTokenWrapper } from "./Authentication-Wrapper";
import BlockingRequestsWrapper from "./Blocking-Requests-Wrapper";

const Scenarios = lazy(() => import("./scenario"));
const Projects = lazy(() => import("./projects"));
const CapitalImprovementPlan = lazy(() => import("./cip"));
const CIPReports = lazy(() => import("./cip-reports"));
const NotFound = lazy(() => import("./not-found"));
const ModelSetup = lazy(() => import("./model-setup"));
const Settings = lazy(() => import("./settings"));
const ScenarioProjects = lazy(() => import("./scenario-projects"));
const EditProfile = lazy(() => import("./profile"));

const scenarioSelectedRoutes = {
  cip: addScenarioRouteParam(CIP_PATH),
  reports: addScenarioRouteParam(REPORTS_PATH),
  scenarioProjects: addScenarioRouteParam(SCENARIO_PROJECTS_PATH),
  setup: addScenarioRouteParam(SETUP_PATH),
};

const Routes = () => {
  const setupMatch = useRouteMatch(scenarioSelectedRoutes.setup);
  const reportsMatch = useRouteMatch(scenarioSelectedRoutes.reports);
  const cipMatch = useRouteMatch(scenarioSelectedRoutes.cip);
  const scenarioProjectsMatch = useRouteMatch(
    scenarioSelectedRoutes.scenarioProjects
  );

  const scenarioId =
    setupMatch?.params?.scenario ||
    reportsMatch?.params?.scenario ||
    cipMatch?.params?.scenario ||
    scenarioProjectsMatch?.params?.scenario;

  return (
    <AccessTokenWrapper>
      <BlockingRequestsWrapper>
        <Layout scenarioId={scenarioId}>
          <Suspense fallback={<LoadingPage shouldLeaveSpaceForAppBar />}>
            <Switch>
              <Route path={scenarioSelectedRoutes.setup}>
                <ModelSetup />
              </Route>
              <Route exact path={scenarioSelectedRoutes.reports}>
                <CIPReports />
              </Route>
              <Route exact path={ADMIN_PATH}>
                <BodyPaper>Admin page</BodyPaper>
              </Route>
              <Route exact path={EDIT_PROFILE_PATH}>
                <EditProfile />
              </Route>
              <Route exact path={projectPaths}>
                <Projects />
              </Route>
              <Route exact path={scenarioSelectedRoutes.cip}>
                <CapitalImprovementPlan />
              </Route>
              <Route exact path={scenarioProjectsPaths}>
                <ScenarioProjects />
              </Route>
              <Route exact path={settingsPaths}>
                <Settings />
              </Route>
              <Route exact path={scenarioPaths}>
                <Scenarios />
              </Route>
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </Suspense>
        </Layout>
      </BlockingRequestsWrapper>
    </AccessTokenWrapper>
  );
};

export default withAuthenticationRequired(Routes);
