import { createSelector, createSlice } from "@reduxjs/toolkit";
import { max, range, stack, sum } from "d3";
import Decimal from "decimal.js";
import {
  cipHoverColor,
  disabledGanttColor,
  disabledUnselectedGanttColor,
  gantBarSelectedStroke,
  stackedBarBucketColor,
  stackedBarBucketHoverColor,
  stackedBarBucketHoverStrokeColor,
  stackedBarBucketSelectedColor,
  stackedBarBucketSelectedStrokeColor,
  stackedBarBucketUnselectedColor,
  stackedBarColor,
  stackedBarUnselectedColor,
} from "../shared/theme";
import {
  STACKED_BAR_BUCKET_ID,
  dragAreaSpace,
  minimumQuadrantWidth,
  minimumQuadrantHeight,
  stackedBarHeightToRemove,
  STACKED_BAR_FILTER_BUCKET_ID,
  createCipMaxEndYearsSessionStorageKey,
  createCipYearsShownSessionStorageKey,
} from "./constants";
import {
  costPreviewSelector,
  previewColumnData,
  stackedBarSelector,
} from "./api";
import { stringCompare } from "../shared/utils/sorting";
import { scenarioProjectsSelector } from "../shared/api/scenario-projects";
import { itemSize } from "../shared/components/table/Virtualized-Table-Redux";
import { scenarioSelector } from "../shared/api/scenarios";
import createProjectFilterSlice from "../shared/components/table/project-filter-slice";
import createVirtualizedTableSlice from "../shared/components/table/virtualized-table-slice";
import { NAME_COLUMN_ID } from "../shared/constants/project";
import { ASCENDING_KEY } from "../shared/constants/sorting";
import { driversSelector } from "../shared/api/drivers";
import { getCurrentFYEYear } from "../shared/api/admin";

const initialState = {};

export const CIP_STACKED_BAR_DRAG_TARGET = "stacked-bar";
export const CIP_GANTT_DRAG_TARGET = "gantt";

export const getCipStartYear = getCurrentFYEYear;

/* eslint-disable no-param-reassign */
const cipSlice = createSlice({
  name: "cip",
  initialState,
  reducers: {
    drag: (state, action) => {
      state.dragCurrentX = action.payload;

      const x = action.payload;

      const { draggedProject, step } = state;

      const { endYear } = draggedProject;

      const mouseOffset = x - state.dragStartX;

      const numberOfYears = Math.round(mouseOffset / step);

      const newEndYear = endYear + numberOfYears;

      if (newEndYear !== state.newEndYear) {
        state.newEndYear = newEndYear;
      }
    },
    dragEnd: (state) => {
      state.dragCurrentX = null;
      state.dragStartX = null;
      state.newEndYear = null;
      state.step = null;
      state.draggedProject = null;
      state.lastVisibleYear = null;
      state.dragChart = null;
    },
    dragStart: (state, action) => {
      const { chart, lastVisibleYear, project, step, x } = action.payload;
      state.dragStartX = x;
      state.dragCurrentX = x;
      state.step = step;
      state.draggedProject = project;
      state.newEndYear = project?.endYear;
      state.lastVisibleYear = lastVisibleYear;
      state.dragChart = chart;
    },
    hoverChartProject: (state, action) => {
      if (state.dragStartX) {
        return;
      }

      state.chartHoveredProjectId = action.payload.id;
    },
    unHoverChartProject: (state) => {
      if (!state.chartHoveredProjectId) {
        return;
      }

      state.chartHoveredProjectId = null;
    },
    hoverTableProject: (state, action) => {
      if (state.dragStartX) {
        return;
      }

      state.tableHoveredProjectId = action.payload;
    },
    unHoverTableProject: (state) => {
      if (state.dragStartX) {
        return;
      }

      state.tableHoveredProjectId = null;
    },
    resize: (state, action) => {
      state.resizeCurrentPosition = action.payload;
    },
    resizeEnd: (state) => {
      state.resizeCurrentPosition = null;
      state.resizeStartPosition = null;
      state.isVerticalResize = null;
    },
    resizeStart: (state, action) => {
      state.resizeCurrentPosition = action.payload.position;
      state.resizeStartPosition = action.payload.position;
      state.isVerticalResize = action.payload.isVertical;
    },
    resetCip: () => initialState,
    selectProject: (state, action) => {
      state.selectedProjectId = action.payload;
    },
    unSelectProject: (state) => {
      state.selectedProjectId = null;
    },
    setAvailableSize: (state, action) => {
      state.availableHeight = action.payload.height;
      state.availableWidth = action.payload.width;
    },
    setShouldHideFilteredProjects: (state, action) => {
      state.shouldHideFilteredProjects = action.payload;
    },
    setLeftQuadrantWidth: (state, action) => {
      state.leftQuadrantWidth = action.payload;
    },
    setScenarioId: (state, action) => {
      state.scenarioId = action.payload;
    },
    setShouldShowMaxEndYears: (state, action) => {
      const shouldShow = action.payload;

      state.shouldShowMaxEndYears = shouldShow;

      sessionStorage.setItem(
        createCipMaxEndYearsSessionStorageKey(state.scenarioId),
        shouldShow
      );
    },
    setTopQuadrantHeight: (state, action) => {
      state.topQuadrantHeight = action.payload;
    },
    setYearsShown: (state, action) => {
      state.yearsShown = action.payload;
      sessionStorage.setItem(
        createCipYearsShownSessionStorageKey(state.scenarioId),
        action.payload
      );
    },
  },
});

export default cipSlice;

export const {
  drag,
  dragEnd,
  dragStart,
  hoverChartProject,
  hoverTableProject,
  unHoverTableProject,
  resize,
  resizeEnd,
  resizeStart,
  resetCip,
  selectProject,
  setAvailableSize,
  setShouldHideFilteredProjects,
  setLeftQuadrantWidth,
  setScenarioId,
  setShouldShowMaxEndYears,
  setTopQuadrantHeight,
  setYearsShown,
  unHoverChartProject,
  unSelectProject,
} = cipSlice.actions;

const getCipSlice = createSelector(
  (state) => state[cipSlice.name],
  (slice) => slice
);

export const getScenarioId = createSelector(
  getCipSlice,
  (slice) => slice.scenarioId
);

export const getShouldHideFilteredProjects = createSelector(
  getCipSlice,
  (slice) => slice.shouldHideFilteredProjects
);

const getResizeStartPosition = createSelector(
  getCipSlice,
  (slice) => slice.resizeStartPosition
);

const getResizeCurrentPosition = createSelector(
  getCipSlice,
  (slice) => slice.resizeCurrentPosition
);

export const getIsVerticalResize = createSelector(
  getCipSlice,
  (slice) => slice.isVerticalResize
);

export const getIsResizing = createSelector(
  getResizeStartPosition,
  (resizeStartPosition) => !!resizeStartPosition
);

const getVerticalDragDifference = createSelector(
  getIsResizing,
  getIsVerticalResize,
  getResizeCurrentPosition,
  getResizeStartPosition,
  (
    isResizing,
    isVerticalResize,
    resizeCurrentPosition,
    resizeStartPosition
  ) => {
    if (!isResizing || isVerticalResize) {
      return 0;
    }

    return resizeCurrentPosition - resizeStartPosition || 0;
  }
);

const getHorizontalDragDifference = createSelector(
  getIsResizing,
  getIsVerticalResize,
  getResizeCurrentPosition,
  getResizeStartPosition,
  (
    isResizing,
    isVerticalResize,
    resizeCurrentPosition,
    resizeStartPosition
  ) => {
    if (isResizing && isVerticalResize) {
      return resizeCurrentPosition - resizeStartPosition || 0;
    }

    return 0;
  }
);

const getDragCurrentX = createSelector(
  getCipSlice,
  (slice) => slice.dragCurrentX
);

const getDragStartX = createSelector(getCipSlice, (slice) => slice.dragStartX);

export const getIsDragging = createSelector(
  getDragStartX,
  (startX) => !!startX
);

export const getDragDifference = createSelector(
  getDragCurrentX,
  getDragStartX,
  (currentX, startX) => currentX - startX
);

export const getScenarioQueryKey = createSelector(
  getScenarioId,
  (scenarioId) => ({ scenarioId })
);

export const getCipScenario = createSelector(
  getScenarioQueryKey,
  (state) => state,
  (scenarioQueryKey, state) => scenarioSelector(scenarioQueryKey)(state)?.data
);

export const getYearsShown = createSelector(
  getCipSlice,
  getCipScenario,
  (slice, scenario) => slice.yearsShown || scenario?.planningHorizon || 10
);

export const getYears = createSelector(
  getYearsShown,
  getCipStartYear,
  (yearsShown, startYear) => range(startYear, startYear + yearsShown, 1)
);

export const getLastYear = createSelector(
  getYears,
  (years) => years?.[years?.length - 1]
);

export const getCipSelectedProjectId = createSelector(
  getCipSlice,
  (slice) => slice.selectedProjectId
);

export const getIsItemSelected = createSelector(
  getCipSelectedProjectId,
  (state, project) => project?.id,
  (selectedProjectId, projectIdToCheck) =>
    selectedProjectId === projectIdToCheck
);

export const getCipSelectedSmallProjectsBucketYear = createSelector(
  getCipSelectedProjectId,
  (selectedProjectId) =>
    selectedProjectId?.includes(STACKED_BAR_BUCKET_ID)
      ? selectedProjectId.split("-").pop()
      : undefined
);

export const getCipChartHoveredProjectId = createSelector(
  getCipSlice,
  (slice) => slice.chartHoveredProjectId
);

export const getIsItemHovered = createSelector(
  getCipChartHoveredProjectId,
  (state, project) => project?.id,
  (hoveredProjectId, projectIdToCheck) => hoveredProjectId === projectIdToCheck
);

export const getScenarioProjectsQueryKey = createSelector(
  getScenarioId,
  (scenarioId) => ({ scenarioId })
);

const getScenarioProjects = createSelector(
  getScenarioProjectsQueryKey,
  (state) => state,
  (queryKey, state) => scenarioProjectsSelector(queryKey)(state)?.data
);

export const getScenarioProjectsWithinYearRange = createSelector(
  getScenarioProjects,
  getCipStartYear,
  getLastYear,
  (scenarioProjects, yearRangeStart, yearRangeEnd) =>
    scenarioProjects?.filter(
      ({ projectCosts: { costByYear } }) =>
        !!Object.keys(costByYear).find(
          (year) => year >= yearRangeStart && year <= yearRangeEnd
        )
    )
);

const {
  getFilteredProjects,
  projectFilterSlice: cipProjectFilterSlice,
  useProjectFilterProps,
} = createProjectFilterSlice({
  name: "cipProjectFilterSlice",
  projectsSelector: getScenarioProjectsWithinYearRange,
});

export const getFilteredProjectIds = createSelector(
  getFilteredProjects,
  (filteredProjects) => filteredProjects?.map(({ id }) => id)
);

const {
  getSortedItems,
  tableSlice: cipTableSlice,
  virtualizedTableProps,
} = createVirtualizedTableSlice({
  defaultSort: { id: NAME_COLUMN_ID, order: ASCENDING_KEY },
  name: "cipProjectsTableSlice",
  unsortedItemsSelector: getFilteredProjects,
});

export { cipTableSlice, getSortedItems, virtualizedTableProps };

export const getSortedIndexByIdMap = createSelector(
  getSortedItems,
  (sortedItems) =>
    sortedItems?.reduce(
      (acc, project, index) => ({
        ...acc,
        [project.id]: index,
      }),
      {}
    )
);

const getNewEndYear = createSelector(getCipSlice, (slice) => slice.newEndYear);

export const getDraggedProjectInfo = createSelector(
  getCipSlice,
  getSortedIndexByIdMap,
  getNewEndYear,
  getCipStartYear,
  getLastYear,
  (slice, sortedIndexByIdMap, potentialNewEndYear, cipStartYear, lastYear) => {
    let newEndYear;

    const { draggedProject } = slice;

    if (potentialNewEndYear) {
      const { endYear, maximumEndYear, startYear } = draggedProject || {};

      const yearDifference = potentialNewEndYear - endYear;

      const isOutOfViewOnRight = yearDifference + startYear > lastYear;
      const isPastMaxEndYear = potentialNewEndYear > maximumEndYear;
      const isOutOfViewOnLeft = potentialNewEndYear < cipStartYear;

      if (isPastMaxEndYear) {
        newEndYear = maximumEndYear;
      } else if (isOutOfViewOnRight) {
        newEndYear = lastYear - startYear + endYear;
      } else if (isOutOfViewOnLeft) {
        newEndYear = cipStartYear;
      } else {
        newEndYear = potentialNewEndYear;
      }
    }

    return {
      chart: slice.dragChart,
      duration: slice.draggedProject?.duration,
      id: slice.draggedProject?.id,
      index:
        slice.draggedProject?.id &&
        sortedIndexByIdMap[slice.draggedProject?.id],
      newEndYear,
      project: draggedProject,
      shouldUpdate:
        slice.newEndYear && slice.newEndYear !== slice.draggedProject?.endYear,
    };
  }
);

const getFilteredProjectsByIdMap = createSelector(
  getFilteredProjects,
  (filteredProjects) =>
    filteredProjects?.reduce(
      (acc, project) => ({
        ...acc,
        [project.id]: project,
      }),
      {}
    )
);

export const getFilteredOutProjects = createSelector(
  getFilteredProjectsByIdMap,
  getScenarioProjectsWithinYearRange,
  (filteredProjectsByIdMap, scenarioProjectsWithinYearRange) =>
    scenarioProjectsWithinYearRange?.filter(
      ({ id }) => !filteredProjectsByIdMap?.[id]
    )
);

export const getCipFilteredOutProjectsByIdMap = createSelector(
  getFilteredOutProjects,
  (filteredOutProjects) =>
    filteredOutProjects?.reduce(
      (acc, project) => ({
        ...acc,
        [project.id]: project,
      }),
      {}
    )
);

export { getFilteredProjects, useProjectFilterProps };

export { cipProjectFilterSlice };

export const getCipChartHoveredProject = createSelector(
  getFilteredProjectsByIdMap,
  getCipChartHoveredProjectId,
  (filteredProjectsById, chartHoveredProjectId) =>
    filteredProjectsById?.[chartHoveredProjectId]
);

export const getCipTableHoveredProjectId = createSelector(
  getCipSlice,
  (slice) => slice.tableHoveredProjectId
);

export const getCipHoveredProjectId = createSelector(
  getCipChartHoveredProjectId,
  getCipTableHoveredProjectId,
  (chartHoveredProjectId, tableHoveredProjectId) =>
    chartHoveredProjectId || tableHoveredProjectId
);

export const getCipStrokeForProject = createSelector(
  getCipSelectedProjectId,
  getCipHoveredProjectId,
  getIsDragging,
  (state, project) => project,
  (selectedProjectId, hoveredProjectId, isDragging, { id } = {}) => {
    if (id?.includes(STACKED_BAR_BUCKET_ID)) {
      if (selectedProjectId && selectedProjectId === id) {
        return stackedBarBucketSelectedStrokeColor;
      }

      if (hoveredProjectId && hoveredProjectId === id) {
        return stackedBarBucketHoverStrokeColor;
      }
    }

    if (isDragging && selectedProjectId && selectedProjectId === id) {
      return gantBarSelectedStroke;
    }

    return null;
  }
);

export const getCipColorForProject = createSelector(
  getCipSelectedProjectId,
  getCipHoveredProjectId,
  (state, project) => project,
  (selectedProjectId, hoveredProjectId, { enabled, id } = {}) => {
    if (id?.includes(STACKED_BAR_BUCKET_ID)) {
      if (selectedProjectId) {
        if (selectedProjectId === id) {
          return stackedBarBucketSelectedColor;
        }

        if (hoveredProjectId && hoveredProjectId === id) {
          return stackedBarBucketHoverColor;
        }

        return stackedBarBucketUnselectedColor;
      }

      if (hoveredProjectId && hoveredProjectId === id) {
        return stackedBarBucketHoverColor;
      }

      return stackedBarBucketColor;
    }

    if (!enabled) {
      if (selectedProjectId) {
        return disabledUnselectedGanttColor;
      }

      return disabledGanttColor;
    }

    if (selectedProjectId) {
      if (selectedProjectId === id) {
        return stackedBarColor;
      }
      if (hoveredProjectId && hoveredProjectId === id) {
        return cipHoverColor;
      }
      return stackedBarUnselectedColor;
    }
    if (hoveredProjectId && hoveredProjectId === id) {
      return cipHoverColor;
    }
    return stackedBarColor;
  }
);

export const getShouldShowMaxEndYears = createSelector(
  getCipSlice,
  (slice) => slice.shouldShowMaxEndYears
);

export const getShouldShowMaxEndYearForProject = createSelector(
  getCipSelectedProjectId,
  getCipHoveredProjectId,
  getShouldShowMaxEndYears,
  (state, project) => project,
  (selectedProjectId, hoveredProjectId, shouldShowMaxEndYears, { id }) => {
    const isSelected = selectedProjectId && selectedProjectId === id;
    const isHovered = hoveredProjectId && hoveredProjectId === id;

    return shouldShowMaxEndYears || isSelected || isHovered;
  }
);

export const getStackedBarQueryKey = createSelector(
  getScenarioId,
  getLastYear,
  getCipStartYear,
  (scenarioId, lastYear, startYear) => ({
    scenarioId,
    endYear: lastYear,
    startYear,
  })
);

export const getCipStackedBarResponse = createSelector(
  getStackedBarQueryKey,
  (state) => state,
  (queryKey, state) => stackedBarSelector(queryKey)(state)?.data
);

export const getDriversResponse = createSelector(
  (state) => state,
  (state) => driversSelector()(state)?.data
);

export const getCostPreviewQueryKey = createSelector(
  getScenarioId,
  getDraggedProjectInfo,
  (scenarioId, draggedProjectInfo) => ({
    projectId: draggedProjectInfo?.id,
    scenarioId,
  })
);

export const getCipCostPreviewResponse = createSelector(
  getCostPreviewQueryKey,
  getDraggedProjectInfo,
  (state) => state,
  (queryKey, draggedProjectInfo, state) =>
    costPreviewSelector(queryKey)(state)?.data
);

export const getPreviewColumnData = createSelector(
  getCipStackedBarResponse,
  getCipCostPreviewResponse,
  getDraggedProjectInfo,
  (stackedBarResponse, costPreviewResponse, draggedProjectInfo) =>
    previewColumnData({
      columnData: stackedBarResponse,
      newEndYear: draggedProjectInfo?.newEndYear,
      previewCosts: costPreviewResponse,
      projectId: draggedProjectInfo?.id,
    })
);

const getAvailableHeight = createSelector(
  getCipSlice,
  (slice) => slice.availableHeight || 0
);

const getAvailableWidth = createSelector(
  getCipSlice,
  (slice) => slice.availableWidth || 0
);

const getMaximumTopQuadrantHeight = createSelector(
  getAvailableHeight,
  (availableHeight) => availableHeight - dragAreaSpace - minimumQuadrantHeight
);

export const getTopQuadrantHeight = createSelector(
  getCipSlice,
  getAvailableHeight,
  getMaximumTopQuadrantHeight,
  (slice, availableHeight, maximumTopQuadrantHeight) => {
    const { topQuadrantHeight } = slice;

    if (maximumTopQuadrantHeight < topQuadrantHeight) {
      return maximumTopQuadrantHeight;
    }

    if (slice.topQuadrantHeight) {
      return slice.topQuadrantHeight;
    }

    const halfHeight = new Decimal(availableHeight)
      .minus(dragAreaSpace)
      .dividedBy(2);

    if (halfHeight.lessThan(minimumQuadrantHeight)) {
      return minimumQuadrantHeight;
    }

    return halfHeight.toNumber();
  }
);

export const getTopQuadrantResizeHeight = createSelector(
  getTopQuadrantHeight,
  getVerticalDragDifference,
  getMaximumTopQuadrantHeight,
  (topQuadrantHeight, verticalDragDifference, maximumTopQuadrantHeight) => {
    const newHeight = topQuadrantHeight + verticalDragDifference;

    if (newHeight < minimumQuadrantHeight) {
      return minimumQuadrantHeight;
    }

    if (newHeight > maximumTopQuadrantHeight) {
      return maximumTopQuadrantHeight;
    }

    return newHeight;
  }
);

export const getBottomQuadrantHeight = createSelector(
  getAvailableHeight,
  getTopQuadrantHeight,
  (availableHeight, topQuadrantHeight) =>
    availableHeight - dragAreaSpace - topQuadrantHeight
);

export const getBottomQuadrantResizeHeight = createSelector(
  getAvailableHeight,
  getTopQuadrantResizeHeight,
  (availableHeight, topQuadrantResizeHeight) =>
    availableHeight - dragAreaSpace - topQuadrantResizeHeight
);

const getMaximumLeftQuadrantWidth = createSelector(
  getAvailableWidth,
  (availableWidth) => availableWidth - dragAreaSpace - minimumQuadrantWidth
);

export const getLeftQuadrantWidth = createSelector(
  getCipSlice,
  getAvailableWidth,
  getMaximumLeftQuadrantWidth,
  (slice, availableWidth, maximumLeftQuadrantWidth) => {
    const { leftQuadrantWidth } = slice;

    if (leftQuadrantWidth > maximumLeftQuadrantWidth) {
      return maximumLeftQuadrantWidth;
    }

    if (slice.leftQuadrantWidth) {
      return slice.leftQuadrantWidth;
    }

    const halfWidth = new Decimal(availableWidth)
      .minus(dragAreaSpace)
      .dividedBy(2);

    if (halfWidth.lessThan(minimumQuadrantWidth)) {
      return minimumQuadrantWidth;
    }

    return halfWidth.toNumber();
  }
);

export const getLeftQuadrantResizeWidth = createSelector(
  getLeftQuadrantWidth,
  getHorizontalDragDifference,
  getMaximumLeftQuadrantWidth,
  (leftQuadrantWidth, horizontalDragDifference, maximumLeftQuadrantWidth) => {
    const newWidth = leftQuadrantWidth + horizontalDragDifference;

    if (newWidth < minimumQuadrantWidth) {
      return minimumQuadrantWidth;
    }

    if (newWidth > maximumLeftQuadrantWidth) {
      return maximumLeftQuadrantWidth;
    }

    return newWidth;
  }
);

export const getRightQuadrantWidth = createSelector(
  getLeftQuadrantWidth,
  getAvailableWidth,
  (leftQuadrantWidth, availableWidth) =>
    availableWidth - dragAreaSpace - leftQuadrantWidth
);

export const getRightQuadrantResizeWidth = createSelector(
  getLeftQuadrantResizeWidth,
  getAvailableWidth,
  (leftQuadrantResizeWidth, availableWidth) =>
    availableWidth - dragAreaSpace - leftQuadrantResizeWidth
);

export const getStackedBarHeight = createSelector(
  getTopQuadrantHeight,
  (topQuadrantHeight) => Math.max(topQuadrantHeight - itemSize - 16, 0)
);

const createFilterBucketedDataFromColumnData = ({
  columnData,
  years,
  filteredOutProjectsById,
  shouldHideFilteredProjects,
}) => {
  const numberOfFilteredProjectCostsByYear = years?.reduce(
    (acc, year) => ({
      ...acc,
      [year]: 0,
    }),
    {}
  );

  const filterBucketedData = columnData?.map((yearData) => {
    let bucketSum = new Decimal(0);

    const yearDataCopy = {
      ...yearData,
    };

    const { year } = yearData;

    Object.entries(yearData).forEach(([projectId, value]) => {
      if (projectId !== "year" && !!filteredOutProjectsById?.[projectId]) {
        bucketSum = bucketSum.plus(value);

        delete yearDataCopy[projectId];
        numberOfFilteredProjectCostsByYear[year] += 1;
      }
    });

    return {
      ...yearDataCopy,
      [STACKED_BAR_FILTER_BUCKET_ID]: shouldHideFilteredProjects
        ? 0
        : bucketSum.toNumber(),
    };
  });

  return {
    filterBucketedData,
    numberOfFilteredProjectCostsByYear,
  };
};

const getFilterBucketedData = createSelector(
  getPreviewColumnData,
  getYears,
  getCipFilteredOutProjectsByIdMap,
  getShouldHideFilteredProjects,
  (previewData, years, filteredOutProjectsById, shouldHideFilteredProjects) =>
    createFilterBucketedDataFromColumnData({
      columnData: previewData,
      years,
      filteredOutProjectsById,
      shouldHideFilteredProjects,
    })
);

export const getStackedBarTotalNumberOfProjectsPerYear = createSelector(
  getCipStackedBarResponse,
  (columnData) =>
    columnData?.reduce((acc, yearData) => {
      const { year, ...projectCosts } = yearData;

      return {
        ...acc,
        [year]: Object.values(projectCosts || {}).length,
      };
    }, {})
);

export const getStackedBarNumberOfFilteredOutProjectsByYear = createSelector(
  getCipStackedBarResponse,
  getCipFilteredOutProjectsByIdMap,
  (columnData, filteredOutProjectsById) =>
    columnData?.reduce((acc, yearData) => {
      const { year, ...projectCosts } = yearData;

      return {
        ...acc,
        [year]: Object.keys(projectCosts)?.filter(
          (projectId) => !!filteredOutProjectsById[projectId]
        )?.length,
      };
    }, {})
);

export const getTotalFilteredOutProjectCostByYear = createSelector(
  getCipStackedBarResponse,
  getCipFilteredOutProjectsByIdMap,
  (columnData, filteredOutProjectsByIdMap) =>
    columnData?.reduce((acc, yearData) => {
      let filteredOutSum = new Decimal(0);

      const { year, ...projectCosts } = yearData;

      Object.entries(projectCosts).forEach(([projectId, cost]) => {
        if (filteredOutProjectsByIdMap[projectId]) {
          filteredOutSum = filteredOutSum.plus(cost);
        }
      });

      return {
        ...acc,
        [year]: filteredOutSum,
      };
    }, {})
);

export const getStackedBarChartMaxY = createSelector(
  getCipStackedBarResponse,
  getYears,
  getCipFilteredOutProjectsByIdMap,
  getShouldHideFilteredProjects,
  (
    cipStackedBarResponse,
    years,
    filteredOutProjectsById,
    shouldHideFilteredProjects
  ) => {
    const filterBucketedData = createFilterBucketedDataFromColumnData({
      columnData: cipStackedBarResponse,
      years,
      filteredOutProjectsById,
      shouldHideFilteredProjects,
    });

    if (!filterBucketedData?.filterBucketedData) {
      return 0;
    }
    const columnsWithoutYears = filterBucketedData?.filterBucketedData?.map(
      (column) => {
        const { year, ...costs } = column;

        return { ...costs };
      }
    );

    return (
      max(
        columnsWithoutYears?.map((column) =>
          sum(Object.values(column), (cost) => parseFloat(cost))
        )
      ) * 1.05
    );
  }
);

export const getMinimumStackedBarAmountToShow = createSelector(
  getStackedBarChartMaxY,
  getStackedBarHeight,
  (maxY, chartHeight) =>
    new Decimal(maxY || 1)
      .dividedBy(chartHeight || 1)
      .times(1 + stackedBarHeightToRemove)
);

const getBucketedData = createSelector(
  getFilterBucketedData,
  getDraggedProjectInfo,
  getYears,
  getMinimumStackedBarAmountToShow,
  (filterBucketedData, draggedProjectInfo, years, minimumAmountToShow) => {
    const bucketedProjectIdsByYear = years?.reduce(
      (acc, year) => ({
        ...acc,
        [year]: [],
      }),
      {}
    );

    const bucketedData = filterBucketedData?.filterBucketedData?.map(
      (yearData) => {
        let bucketSum = new Decimal(0);

        const yearDataCopy = {
          ...yearData,
        };

        const { year } = yearData;

        Object.entries(yearData).forEach(([projectId, value]) => {
          if (
            projectId !== "year" &&
            projectId !== draggedProjectInfo?.id &&
            projectId !== STACKED_BAR_FILTER_BUCKET_ID &&
            new Decimal(value).lt(minimumAmountToShow)
          ) {
            bucketSum = bucketSum.plus(value);

            delete yearDataCopy[projectId];
            bucketedProjectIdsByYear[year]?.push(projectId);
          }
        });

        return {
          ...yearDataCopy,
          [STACKED_BAR_BUCKET_ID]: bucketSum.toNumber(),
        };
      }
    );

    return {
      bucketedData,
      bucketedProjectIdsByYear,
    };
  }
);

const getBucketedProjectIdsByYear = createSelector(
  getBucketedData,
  (bucketedData) => bucketedData?.bucketedProjectIdsByYear
);

export const getStackedData = createSelector(getBucketedData, (bucketedData) =>
  bucketedData?.bucketedData?.map((yearData) => {
    const { year, ...rest } = yearData;

    delete rest[STACKED_BAR_BUCKET_ID];
    delete rest[STACKED_BAR_FILTER_BUCKET_ID];

    const projectIdsSortedByCost = Object.entries({ ...rest })
      .sort((a, b) => {
        const difference = parseFloat(b[1]) - parseFloat(a[1]);

        if (difference === 0) {
          return stringCompare({ a: a[0], b: b[0] });
        }

        return difference;
      })
      .map(([id]) => id);

    return stack().keys([
      ...projectIdsSortedByCost,
      STACKED_BAR_BUCKET_ID,
      STACKED_BAR_FILTER_BUCKET_ID,
    ])([yearData]);
  })
);

export const getNumberOfFilteredOutProjects = createSelector(
  getFilteredOutProjects,
  (filteredOutProjects) => filteredOutProjects?.length
);

export const getNumberOfProjectsOutsideOfYearRange = createSelector(
  getScenarioProjects,
  getScenarioProjectsWithinYearRange,
  (scenarioProjects, scenarioProjectsWithinYearRange) =>
    scenarioProjects?.length - scenarioProjectsWithinYearRange?.length
);

export const getScenarioProjectsByIdMap = createSelector(
  getScenarioProjects,
  (scenarioProjects) =>
    scenarioProjects?.reduce(
      (acc, scenarioProject) => ({
        ...acc,
        [scenarioProject.id]: scenarioProject,
      }),
      {}
    )
);

export const getSelectedBucketedProjects = createSelector(
  getCipSelectedSmallProjectsBucketYear,
  getScenarioProjectsByIdMap,
  getBucketedProjectIdsByYear,
  (selectedYear, scenarioProjectsById, bucketedProjectIdsByYear) =>
    !selectedYear
      ? []
      : bucketedProjectIdsByYear?.[selectedYear]?.map(
          (id) => scenarioProjectsById?.[id]
        )
);
