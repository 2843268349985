export const COLUMN_CONFIGURATION_ICON_TEST_ID =
  "projects-column-configuration-icon";
export const COLUMN_CONFIGURATION_DRAGGABLE_COLUMN_TEST_ID =
  "projects-column-configuration-draggable-column";
export const COLUMN_CONFIGURATION_NON_DRAGGABLE_COLUMN_TEST_ID =
  "projects-column-configuration-non-draggable-column";
export const COLUMN_CONFIGURATION_VISIBILITY_OFF_TEST_ID =
  "projects-column-configuration-visibility-off";
export const COLUMN_CONFIGURATION_DRAG_INDICATOR_TEST_ID =
  "projects-column-configuration-drag-indicator";
export const COLUMN_CONFIGURATION_DESCRIPTION =
  "Drag the most important columns to top and toggle off any you don’t need.";
export const COLUMN_CONFIGURATION_ICON_TOOLTIP =
  "Configure column order and visibility";

export const VIRTUALIZED_TABLE_HEAD_CELL_TEST_ID =
  "virtualized-table-head-cell";

export const VIRTUALIZED_TABLE_FILTER_BUTTON_TEXT = "FILTER";
export const VIRTUALIZED_TABLE_FILTER_CLEAR_BUTTON_TEXT = "CLEAR ALL";

export const PROJECT_FILTER_COST_OPTION_PROJECTED_COST_LABEL =
  "Total Escalated Cost";
export const PROJECT_FILTER_COST_OPTION_TOTAL_COST_LABEL = "Cost at Year";
export const PROJECT_FILTER_COST_OPTION_TODAYS_COST_LABEL = "Today's Cost";

export const PROJECT_FILTER_YEAR_OPTION_END_YEAR_LABEL = "Planned End Year";
export const PROJECT_FILTER_YEAR_OPTION_MAX_END_YEAR_LABEL = "Max End Year";
export const PROJECT_FILTER_YEAR_OPTION_START_YEAR_LABEL = "Planned Start Year";

export const PROJECT_FILTER_APPROVAL_OPTION_ALL_PROJECTS = "All Projects";
export const PROJECT_FILTER_APPROVAL_OPTION_APPROVED = "Only Approved";
export const PROJECT_FILTER_APPROVAL_OPTION_NON_APPROVED = "Only Non-Approved";

export const PROJECT_FILTER_KEYWORD_SECTION_TITLE = "KEYWORD";
export const PROJECT_FILTER_KEYWORD_LABEL_TEXT = "Includes the text";

export const FILTER_DIALOG_CLOSE_TEST_ID = "close-filter-dialog";

export const FILTER_DIALOG_UTILITY_SECTION_TITLE = "UTILITY";
export const FILTER_DIALOG_DRIVER_SECTION_TITLE = "PROJECT DRIVERS";

export const DRIVER_FILTER_BUTTON_TEST_ID = "driver-filter";
export const DRIVER_FILTER_ARC_TEST_ID = "filter-driver-arc";

export const FILTER_DIALOG_DURATION_SECTION_TITLE = "DURATION";

export const FILTER_DIALOG_UTILITY_BUTTON_TEST_ID = "utility-filter";

export const PROJECT_FILTER_COST_RANGE_SLIDER_TEST_ID = "cost-range-slider";
export const PROJECT_FILTER_YEAR_RANGE_SLIDER_TEST_ID = "year-range-slider";
export const PROJECT_FILTER_DURATION_RANGE_SLIDER_TEST_ID =
  "duration-range-slider";

export const PROJECT_FILTER_APPROVAL_SECTION_TITLE = "APPROVAL STATUS";
export const PROJECT_FILTER_APPROVAL_STATUS_LABEL = "Filter by";

export const VIRTUALIZED_TABLE_ROW_ACTIONS_MENU_BUTTON_TEST_ID =
  "virtualized-table-row-actions-menu-button";
export const VIRTUALIZED_TABLE_ACTIONS_MENU_BUTTON_TEST_ID =
  "virtualized-table-actions-menu";
export const VIRTUALIZED_TABLE_ACTIONS_MENU_CONFIGURE_COLUMNS_BUTTON_TEXT =
  "Configure columns";

export const FILTER_DEBOUNCE_DELAY = 250;
