import React, { Fragment } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import {
  AdminPanelSettings,
  CancelScheduleSend,
  Mail,
  NoAccounts,
  Restore,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import {
  USER_STATUS_ACTIVE,
  USER_STATUS_DEACTIVATED,
  USER_STATUS_INVITE_EXPIRED,
  USER_STATUS_INVITE_PENDING,
} from "../../constants/user";
import {
  USER_ACTIONS_MENU_CANCEL_INVITATION_BUTTON_TEXT,
  USER_ACTIONS_MENU_DISABLE_ACCOUNT_BUTTON_TEXT,
  USER_ACTIONS_MENU_EDIT_ACCESS_LEVEL_BUTTON_TEXT,
  USER_ACTIONS_MENU_RESTORE_ACCOUNT_BUTTON_TEXT,
  USER_ACTIONS_MENU_SEND_NEW_INVITATION_BUTTON_TEXT,
} from "./constants";
import refPropType from "../../prop-types/ref";
import { error } from "../../theme";
import useDialog from "../../hooks/use-dialog";
import CancelInvitation from "./Cancel-Invitation";
import useIsUserActionAvailable from "./use-is-user-action-available";
import EditAccessLevel from "./Edit-Access-Level";
import DisableUser from "./Disable-User";
import RestoreUser from "./Restore-User";
import SendNewInvitation from "./Send-New-Invitation";

const UserActionsMenu = ({
  anchorEl,
  handleClose,
  isOpen,
  onActionSuccess,
  profile,
}) => {
  const { accessLevel, disabledAt, email, id, invitationId, fullName, status } =
    profile;

  const {
    isOpen: isCancelInvitationDialogOpen,
    handleClose: handleCloseCancelInvitationDialog,
    handleOpen: handleOpenCancelInvitationDialog,
  } = useDialog();

  const cancelInvitationAction = {
    action: handleOpenCancelInvitationDialog,
    actionText: USER_ACTIONS_MENU_CANCEL_INVITATION_BUTTON_TEXT,
    isErrorColor: true,
    Icon: CancelScheduleSend,
  };

  const {
    isOpen: isEditAccessLevelDialogOpen,
    handleClose: handleCloseEditAccessLevelDialog,
    handleOpen: handleOpenEditAccessLevelDialog,
  } = useDialog();

  const editAccessLevelAction = {
    action: handleOpenEditAccessLevelDialog,
    actionText: USER_ACTIONS_MENU_EDIT_ACCESS_LEVEL_BUTTON_TEXT,
    Icon: AdminPanelSettings,
  };

  const {
    isOpen: isDisableUserDialogOpen,
    handleClose: handleCloseDisableUserDialog,
    handleOpen: handleOpenDisableUserDialog,
  } = useDialog();

  const disableUserAction = {
    action: handleOpenDisableUserDialog,
    actionText: USER_ACTIONS_MENU_DISABLE_ACCOUNT_BUTTON_TEXT,
    Icon: NoAccounts,
    isErrorColor: true,
  };

  const {
    isOpen: isRestoreUserDialogOpen,
    handleClose: handleCloseRestoreUserDialog,
    handleOpen: handleOpenRestoreUserDialog,
  } = useDialog();

  const restoreUserAction = {
    action: handleOpenRestoreUserDialog,
    actionText: USER_ACTIONS_MENU_RESTORE_ACCOUNT_BUTTON_TEXT,
    Icon: Restore,
  };

  const {
    isOpen: isSendNewInvitationDialogOpen,
    handleClose: handleCloseSendNewInvitationDialog,
    handleOpen: handleOpenSendNewInvitationDialog,
  } = useDialog();

  const sendNewInvitationAction = {
    action: handleOpenSendNewInvitationDialog,
    actionText: USER_ACTIONS_MENU_SEND_NEW_INVITATION_BUTTON_TEXT,
    Icon: Mail,
  };

  const statusToActionsMap = {
    [USER_STATUS_INVITE_PENDING]: [
      sendNewInvitationAction,
      cancelInvitationAction,
    ],
    [USER_STATUS_ACTIVE]: [editAccessLevelAction, disableUserAction],
    [USER_STATUS_DEACTIVATED]: [restoreUserAction],
    [USER_STATUS_INVITE_EXPIRED]: [
      sendNewInvitationAction,
      cancelInvitationAction,
    ],
  };

  const isCancelInvitationEnabled = useFlag("cancelInvitation");
  const isEditAccessLevelEnabled = useFlag("editAccessLevel");
  const isDisableUserEnabled = useFlag("disableUser");
  const isRestoreUserEnabled = useFlag("restoreUser");
  const isSendNewInvitationEnabled = useFlag("sendNewInvitation");

  Object.keys(statusToActionsMap).forEach((key) => {
    statusToActionsMap[key] = statusToActionsMap[key].filter((action) => {
      if (action === editAccessLevelAction && !isEditAccessLevelEnabled) {
        return false;
      }

      if (action === sendNewInvitationAction && !isSendNewInvitationEnabled) {
        return false;
      }

      if (action === cancelInvitationAction && !isCancelInvitationEnabled) {
        return false;
      }

      if (action === disableUserAction && !isDisableUserEnabled) {
        return false;
      }

      if (action === restoreUserAction && !isRestoreUserEnabled) {
        return false;
      }

      return true;
    });
  });

  const actions = statusToActionsMap[status];

  const isUserActionAvailable = useIsUserActionAvailable(status);

  if (!isUserActionAvailable) {
    return null;
  }

  return (
    <>
      <CancelInvitation
        email={email}
        handleClose={handleCloseCancelInvitationDialog}
        invitationId={invitationId}
        isOpen={isCancelInvitationDialogOpen}
        name={fullName}
        onActionSuccess={onActionSuccess}
      />
      <DisableUser
        accessLevel={accessLevel}
        email={email}
        handleClose={handleCloseDisableUserDialog}
        isOpen={isDisableUserDialogOpen}
        name={fullName}
        onActionSuccess={onActionSuccess}
        userId={id}
      />
      <RestoreUser
        accessLevel={accessLevel}
        disabledAt={disabledAt}
        email={email}
        handleClose={handleCloseRestoreUserDialog}
        isOpen={isRestoreUserDialogOpen}
        name={fullName}
        onActionSuccess={onActionSuccess}
        userId={id}
      />
      <SendNewInvitation
        accessLevel={accessLevel}
        email={email}
        handleClose={handleCloseSendNewInvitationDialog}
        isOpen={isSendNewInvitationDialogOpen}
        name={fullName}
        onActionSuccess={onActionSuccess}
      />
      <EditAccessLevel
        accessLevel={accessLevel}
        handleClose={handleCloseEditAccessLevelDialog}
        isOpen={isEditAccessLevelDialogOpen}
        name={fullName}
        onActionSuccess={onActionSuccess}
        userId={id}
      />
      <Menu anchorEl={anchorEl} onClose={handleClose} open={isOpen}>
        <MenuList>
          {actions?.map(({ action, actionText, isErrorColor, Icon }, index) => [
            index !== 0 && <Divider />,
            <MenuItem
              onClick={() => {
                handleClose();
                action();
              }}
            >
              <ListItemIcon>
                <Icon color={isErrorColor ? "error" : undefined} />
              </ListItemIcon>
              <ListItemText
                sx={{ color: isErrorColor ? error.main : undefined }}
              >
                {actionText}
              </ListItemText>
            </MenuItem>,
          ])}
        </MenuList>
      </Menu>
    </>
  );
};

UserActionsMenu.propTypes = {
  anchorEl: refPropType,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onActionSuccess: PropTypes.func,
  profile: PropTypes.shape({
    accessLevel: PropTypes.string.isRequired,
    disabledAt: PropTypes.string,
    email: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    invitationId: PropTypes.string,
    fullName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
};

UserActionsMenu.defaultProps = {
  anchorEl: undefined,
  onActionSuccess: undefined,
};

export default UserActionsMenu;
