import { createSelector } from "@reduxjs/toolkit";
import cipApi, { USER_TAG_TYPE } from "../../cip-api";
import { REACT_APP_API_BASE_URL } from "../environment";
import {
  ADMIN_ACCESS_LEVEL,
  SUPER_ADMIN_ACCESS_LEVEL,
  USER_STATUS_DEACTIVATED,
} from "../constants/user";

export const FETCH_MY_PROFILE_URL = `${REACT_APP_API_BASE_URL}/profiles/me`;
export const PATCH_PROFILE_URL = `${REACT_APP_API_BASE_URL}/profiles/:userId`;

const extendedApi = cipApi.injectEndpoints({
  endpoints: (build) => ({
    myProfile: build.query({
      query: () => ({
        url: FETCH_MY_PROFILE_URL,
      }),
      providesTags: [USER_TAG_TYPE],
    }),
    patchProfile: build.mutation({
      query: ({ userId, ...rest }) => ({
        body: {
          ...rest,
        },
        method: "PATCH",
        url: `/profiles/${userId}`,
      }),
      invalidatesTags: [USER_TAG_TYPE],
    }),
  }),
});

export const { useMyProfileQuery, usePatchProfileMutation } = extendedApi;

export const myProfileSelector = extendedApi.endpoints.myProfile.select();
export const getMyProfile = createSelector(
  (state) => state,
  (state) => myProfileSelector(state)?.data
);

export const canISeeThisUserInUsersTable = createSelector(
  getMyProfile,
  (state, userToView) => userToView,
  (myProfile, userToView) => {
    const myAccessLevel = myProfile?.accessLevel;

    if (myAccessLevel === SUPER_ADMIN_ACCESS_LEVEL) {
      return true;
    }

    if (userToView?.email?.toLowerCase()?.includes("@mmewater.com")) {
      return false;
    }

    if (myAccessLevel === ADMIN_ACCESS_LEVEL) {
      return true;
    }

    if (userToView?.status !== USER_STATUS_DEACTIVATED) {
      return true;
    }

    return false;
  }
);
