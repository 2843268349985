import React, { Fragment } from "react";
import { Button as MaterialButton, CircularProgress } from "@mui/material";
import styled from "styled-components";
import PropTypes from "prop-types";
import { SKELETON_LOADER_TEST_ID } from "./constants";
import { common } from "../theme";
import useHasAccess from "../hooks/use-has-access";
import AccessTooltip from "./Access-Tooltip";

const TextWrapper = styled.div`
  opacity: ${({ $isLoading }) => ($isLoading ? 0 : 1)};
`;

const StyledButton = styled(MaterialButton)`
  position: relative;
`;

const StyledLoader = styled(CircularProgress)`
  height: 1em;
  width: 1em;
  position: absolute;
`;

const WhiteBackground = styled.div`
  background-color: ${common.white};
  border-radius: inherit;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
`;

const Div = styled.div``;

const Button = ({
  accessLevelsRequired,
  children,
  isLoading,
  shouldShowWhiteBackground,
  ...rest
}) => {
  const { noAccess, noAccessMessage } = useHasAccess(accessLevelsRequired);

  const DivOrFragment = noAccessMessage ? Div : Fragment;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <AccessTooltip title={noAccessMessage}>
      <DivOrFragment>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <StyledButton disabled={isLoading || noAccess} {...rest}>
          <TextWrapper $isLoading={isLoading}>{children}</TextWrapper>
          {isLoading && (
            <StyledLoader
              data-testid={SKELETON_LOADER_TEST_ID}
              color="inherit"
              size="1.5em"
            />
          )}
          {shouldShowWhiteBackground && <WhiteBackground />}
        </StyledButton>
      </DivOrFragment>
    </AccessTooltip>
  );
};

Button.propTypes = {
  accessLevelsRequired: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  shouldShowWhiteBackground: PropTypes.bool,
};

Button.defaultProps = {
  accessLevelsRequired: undefined,
  isLoading: false,
  shouldShowWhiteBackground: false,
};

export default Button;
