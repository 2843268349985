import React from "react";
import { NoAccounts } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import DialogTitle from "../Dialog-Title";
import Button from "../Button";
import {
  DISABLE_USER_BUTTON_TEST_ID,
  DISABLE_USER_BUTTON_TEXT,
  DISABLE_USER_ERROR_TEXT,
} from "./constants";
import { useDisableUserMutation } from "./api";
import FormSubmissionErrorAlert from "../forms/Form-Submission-Error-Alert";
import { displaySnackbarMessage } from "../../../layout/layout-slice";
import { HeavyText, NoteText, UserActionText } from "./shared";
import TooltipOrFragment from "../Tooltip-Or-Fragment";
import {
  SUPER_ADMIN_ACCESS_LEVEL,
  accessLevelLabelMap,
} from "../../constants/user";

const formId = "disable-account";

const DisableUser = ({
  accessLevel,
  handleClose,
  email,
  name,
  isOpen,
  onActionSuccess,
  userId,
}) => {
  const dispatch = useDispatch();

  const isSuperAdmin = accessLevel === SUPER_ADMIN_ACCESS_LEVEL;

  const [unwrappedMutate, { isError, isLoading, reset }] =
    useDisableUserMutation();

  const disableUser = () =>
    unwrappedMutate({ userId })
      .unwrap()
      .then(() => {
        handleClose();
        onActionSuccess?.();
        dispatch(
          displaySnackbarMessage(
            `Success. The account for ${name} at ${email} has been disabled.`
          )
        );
      })
      .catch(() => {});

  const handleSubmit = (event) => {
    event.preventDefault();

    disableUser();
  };

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <DialogTitle handleClose={handleClose} title="Disable Account" />
      {isError && (
        <FormSubmissionErrorAlert
          errorText={DISABLE_USER_ERROR_TEXT}
          formId={formId}
          resetMutate={reset}
        />
      )}
      <DialogContent>
        <UserActionText>
          <span>
            <HeavyText>{name}</HeavyText>
            &nbsp;at&nbsp;
            <HeavyText>{email}</HeavyText>
            &nbsp;will have their access revoked and will no longer be able to
            sign in to your organization. An admin can restore their account in
            the future.
          </span>
          <NoteText>
            Note: changes may take up to 30 mins to take affect.
          </NoteText>
        </UserActionText>
      </DialogContent>
      <DialogActions>
        <form id={formId} onSubmit={handleSubmit}>
          <TooltipOrFragment
            title={
              isSuperAdmin
                ? `You can't disable an ${accessLevelLabelMap[SUPER_ADMIN_ACCESS_LEVEL]} user account`
                : undefined
            }
          >
            <div>
              <Button
                color="error"
                data-testid={DISABLE_USER_BUTTON_TEST_ID}
                disabled={isSuperAdmin}
                isLoading={isLoading}
                startIcon={<NoAccounts />}
                type="submit"
                variant="contained"
              >
                {DISABLE_USER_BUTTON_TEXT}
              </Button>
            </div>
          </TooltipOrFragment>
        </form>
      </DialogActions>
    </Dialog>
  );
};

DisableUser.propTypes = {
  accessLevel: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onActionSuccess: PropTypes.func,
  userId: PropTypes.string.isRequired,
};

DisableUser.defaultProps = {
  onActionSuccess: undefined,
};

export default DisableUser;
