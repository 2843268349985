export const CIP_LABEL = "CIP DASHBOARD";
export const SETUP_LABEL = "MODEL SETUP";
export const SCENARIO_PROJECTS_LABEL = "SCENARIO PROJECTS";
export const REPORTS_LABEL = "SUMMARY";
export const ADMIN_LABEL = "Admin";
export const EDIT_PROFILE_LABEL = "Edit Profile";
export const LOGOUT_LABEL = "Sign Out";
export const RESET_PASSWORD_LABEL = "Reset Password";
export const SCENARIOS_LABEL = "SCENARIOS";
export const PROJECTS_LABEL = "GLOBAL PROJECTS";
export const SETTINGS_LABEL = "SETTINGS";

export const APP_BAR_TEST_ID = "layout-app-bar";

export const USER_MENU_TEST_ID = "user-menu";

export const SWITCHER_GLOBAL_WORKSPACE_TITLE = "Global Workspace";
export const SWITCHER_GLOBAL_WORKSPACE_DESCRIPTION =
  "Manage Scenarios, Global Projects and Program Settings";

export const SWITCHER_ALL_SCENARIOS_TITLE = "All Scenarios";
export const SWITCHER_ALL_SCENARIOS_DESCRIPTION =
  "Select or create a scenario to explore";

export const SWITCHER_LAST_SCENARIO_ID_STORAGE_KEY =
  "switcher-last-scenario-id";

export const SWITCHER_BUTTON_TEST_ID = "scenario-switcher-button";

export const SWITCHER_SCENARIO_CURRENTLY_EDITING =
  "You're editing this scenario right now";
export const SWITCHER_SCENARIO_NOT_EDITING =
  "Return to edit your last active scenario";

export const SWITCHER_OPTION_TEST_ID = "scenario-switcher-option";

export const RESET_PASSWORD_ERROR_TEXT = "Failed to request password reset";
export const RESET_PASSWORD_BUTTON_TEXT = "REQUEST RESET";

export const RESET_PASSWORD_DIALOG_TITLE = "Reset Your Password";
export const RESET_PASSWORD_CLOSE_ICON_TEST_ID = "reset-password-close";
