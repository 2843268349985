import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Provider as ReduxProvider } from "react-redux";
import { FlagProvider } from "@unleash/proxy-client-react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { Auth0Provider } from "@auth0/auth0-react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import Routes from "./Routes";
import {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_FEATURE_FLAG_CLIENT_KEY,
} from "./shared/environment";

import { materialTheme } from "./shared/theme";

import { AppStateProvider } from "./shared/hooks/app-state";
import Auth0Wrapper from "./Authentication-Wrapper";
import ErrorBoundary from "./Error-Boundary";
import reduxStore from "./redux-store";

const Wrapper = styled.div`
  height: 100%;
`;

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Roboto, sans-serif;
    margin: 0;
  }
`;

const history = createBrowserHistory();

const App = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const invitation = searchParams.get("invitation");
  const organization = searchParams.get("organization");

  return (
    <ReduxProvider store={reduxStore}>
      <ThemeProvider theme={materialTheme}>
        <ErrorBoundary>
          <Auth0Provider
            authorizationParams={{
              audience: REACT_APP_AUTH0_AUDIENCE,
              ...(invitation ? { invitation } : {}),
              ...(organization ? { organization } : {}),
              redirect_uri: window.location.origin,
            }}
            domain={REACT_APP_AUTH0_DOMAIN}
            clientId={REACT_APP_AUTH0_CLIENT_ID}
            onRedirectCallback={(appState) => {
              const [base, search] = (appState?.returnTo || "?").split("?");
              const urlParams = new URLSearchParams(search);

              urlParams.delete("organization");
              urlParams.delete("organization_name");
              urlParams.delete("invitation");

              history.replace(
                appState?.returnTo
                  ? `${base}?${urlParams.toString()}`
                  : window.location.pathname
              );
            }}
          >
            <StyledEngineProvider injectFirst>
              <FlagProvider
                config={{
                  appName: "uma-frontend",
                  clientKey: REACT_APP_FEATURE_FLAG_CLIENT_KEY,
                  refreshInterval: 60,
                  url: "https://unleash.utilitysage.com/api/frontend",
                }}
              >
                <AppStateProvider>
                  <GlobalStyle />
                  <Router history={history}>
                    <Auth0Wrapper>
                      <Wrapper>
                        <Routes />
                      </Wrapper>
                    </Auth0Wrapper>
                  </Router>
                </AppStateProvider>
              </FlagProvider>
            </StyledEngineProvider>
          </Auth0Provider>
        </ErrorBoundary>
      </ThemeProvider>
    </ReduxProvider>
  );
};

export default App;
