import { range as d3Range } from "d3";
import Decimal from "decimal.js";
import { getCurrentYear } from "./date";

// eslint-disable-next-line import/prefer-default-export
export const getForecastedPercentageForPlanningHorizon = ({
  forecastedPercentageByYear,
  planningHorizon,
}) => {
  const percentageChangeByYear = Object.entries(
    forecastedPercentageByYear || {}
  ).reduce(
    (acc, [year, value]) => ({
      ...acc,
      [year]: new Decimal(value).times(100).toDecimalPlaces(2).toNumber(),
    }),
    {}
  );

  const lastYearWithData = parseInt(
    Object.keys(forecastedPercentageByYear || {}).pop(),
    10
  );

  const firstForecastYear = getCurrentYear() + 1;
  const lastForecastYear = getCurrentYear() + planningHorizon;

  const years = d3Range(firstForecastYear, lastForecastYear + 1);

  const forecastedPercentagesForPlanningHorizon = years.reduce((acc, year) => {
    const isBeyond = parseInt(year, 10) >= lastYearWithData;

    const percentageIncrease = isBeyond
      ? percentageChangeByYear?.[lastYearWithData]
      : percentageChangeByYear?.[year];

    return {
      ...acc,
      [year]: percentageIncrease,
    };
  }, {});

  const minForecastPercentage = Math.min(
    ...Object.values(forecastedPercentagesForPlanningHorizon)
  );
  const maxForecastPercentage = Math.max(
    ...Object.values(forecastedPercentagesForPlanningHorizon)
  );

  return {
    forecastYears: years,
    forecastedPercentagesForPlanningHorizon,
    minForecastPercentage,
    maxForecastPercentage,
  };
};
