import { createSelector, createSlice } from "@reduxjs/toolkit";
import createProjectFilterSlice from "../shared/components/table/project-filter-slice";
import { scenarioProjectsSelector } from "../shared/api/scenario-projects";
import createVirtualizedTableSlice from "../shared/components/table/virtualized-table-slice";
import { NAME_COLUMN_ID } from "../shared/constants/project";
import { ASCENDING_KEY } from "../shared/constants/sorting";

/* eslint-disable no-param-reassign */

const initialState = {};

const scenarioProjectsSlice = createSlice({
  name: "scenarioProjects",
  initialState,
  reducers: {
    resetScenarioProjectsSlice: () => initialState,
    setScenarioId: (state, action) => {
      state.scenarioId = action.payload;
    },
    setSelectedProjectId: (state, action) => {
      state.selectedProjectId = action.payload;
    },
  },
});

export const {
  setScenarioId,
  setSelectedProjectId,
  resetScenarioProjectsSlice,
} = scenarioProjectsSlice.actions;

export default scenarioProjectsSlice;

/* eslint-enable no-param-reassign */

const getScenarioProjectsSlice = createSelector(
  (state) => state,
  (state) => state[scenarioProjectsSlice.name]
);

const getScenarioId = createSelector(
  getScenarioProjectsSlice,
  (slice) => slice.scenarioId
);

const getSelectedProjectId = createSelector(
  getScenarioProjectsSlice,
  (slice) => slice.selectedProjectId
);

export const getIsScenarioProjectSelected = createSelector(
  getSelectedProjectId,
  (state, { id }) => id,
  (selectedProjectId, projectId) => selectedProjectId === projectId
);

export const getScenarioProjectsQueryKey = createSelector(
  getScenarioId,
  (scenarioId) => ({ scenarioId })
);

const getScenarioProjects = createSelector(
  getScenarioProjectsQueryKey,
  (state) => state,
  (queryKey, state) => scenarioProjectsSelector(queryKey)(state)?.data
);

const {
  getFilteredProjects,
  projectFilterSlice: scenarioProjectsProjectFilterSlice,
  useProjectFilterProps,
} = createProjectFilterSlice({
  name: "scenarioProjectsFilter",
  projectsSelector: getScenarioProjects,
});

export {
  getFilteredProjects,
  scenarioProjectsProjectFilterSlice,
  useProjectFilterProps,
};

const {
  getSortedItems,
  tableSlice: scenarioProjectsTableSlice,
  virtualizedTableProps,
} = createVirtualizedTableSlice({
  defaultSort: { id: NAME_COLUMN_ID, order: ASCENDING_KEY },
  name: "scenarioProjectsTableSlice",
  unsortedItemsSelector: getFilteredProjects,
});

export { getSortedItems, scenarioProjectsTableSlice, virtualizedTableProps };
