import React, { Fragment } from "react";
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from "@mui/material";
import PropTypes from "prop-types";
import { Link, matchPath, useHistory, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";
import { AccountCircle, LockReset, Logout } from "@mui/icons-material";
import { appBarPadding, appBarPaddingNumber, primary } from "../shared/theme";
import {
  APP_BAR_TEST_ID,
  CIP_LABEL,
  EDIT_PROFILE_LABEL,
  LOGOUT_LABEL,
  PROJECTS_LABEL,
  REPORTS_LABEL,
  RESET_PASSWORD_LABEL,
  SCENARIOS_LABEL,
  SCENARIO_PROJECTS_LABEL,
  SETTINGS_LABEL,
  SETUP_LABEL,
  USER_MENU_TEST_ID,
} from "./constants";
import {
  CIP_PATH,
  modelSetupPaths,
  MODEL_SETUP_SCENARIO_DETAILS_PATH,
  projectPaths,
  PROJECTS_PATH,
  REPORTS_PATH,
  scenarioPaths,
  scenarioProjectsPaths,
  SCENARIOS_PATH,
  SCENARIO_PROJECTS_PATH,
  settingsPaths,
  SETTINGS_INDICES_PATH,
  EDIT_PROFILE_PATH,
} from "../shared/constants/routes";
import {
  InnerAppBarStack,
  StyledAppBar,
  StyledAppBarContainer as AppBarContainer,
  StyledContainer,
} from "../shared/components/Layout-Components";
import Switcher from "./Switcher";
import usePopover from "../shared/hooks/use-popover";
import useDialog from "../shared/hooks/use-dialog";
import ResetPassword from "./Reset-Password";
import { useMyProfileQuery } from "../shared/api/profile";
import { InitialsAvatar } from "../shared/components/user/shared";
import Snackbars from "./Snackbars";

const iconButtonPadding = 8;

const StyledAppBarContainer = styled(AppBarContainer)`
  padding-left: ${appBarPadding};

  padding-right: ${appBarPaddingNumber - iconButtonPadding}px;
`;

const SwitcherContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

const StyledTabs = styled(Tabs)`
  height: 100%;
  padding-left: 16px;
  min-width: min-content;

  .MuiTabs-flexContainer {
    height: 100%;
    justify-content: flex-end;
  }

  .MuiTabs-indicator {
    background-color: ${primary.contrastText};
  }
`;

const Layout = ({ children, scenarioId }) => {
  const {
    handleClosePopover: handleCloseUserMenu,
    handleClickOpenPopover: handleOpenUserMenu,
    isPopoverOpen,
    popoverAnchor,
  } = usePopover();

  const { logout } = useAuth0();

  const { data: { avatarColor, fullName } = {} } = useMyProfileQuery();

  const {
    isOpen: isResetPasswordOpen,
    handleClose: handleCloseResetPassword,
    handleOpen: handleOpenResetPassword,
  } = useDialog();

  const { pathname } = useLocation();
  const scenarioSelectedTabs = [
    {
      label: SETUP_LABEL,
      path: modelSetupPaths,
      to: `/${scenarioId}${MODEL_SETUP_SCENARIO_DETAILS_PATH}`,
    },
    {
      label: SCENARIO_PROJECTS_LABEL,
      path: scenarioProjectsPaths,
      to: `/${scenarioId}${SCENARIO_PROJECTS_PATH}`,
    },
    {
      label: CIP_LABEL,
      path: [`/${scenarioId}${CIP_PATH}`],
      to: `/${scenarioId}${CIP_PATH}`,
    },
    {
      label: REPORTS_LABEL,
      path: [`/${scenarioId}${REPORTS_PATH}`],
      to: `/${scenarioId}${REPORTS_PATH}`,
    },
  ];

  const noScenarioSelectedTabs = [
    {
      label: SCENARIOS_LABEL,
      path: scenarioPaths,
      to: SCENARIOS_PATH,
    },
    {
      label: PROJECTS_LABEL,
      path: projectPaths,
      to: PROJECTS_PATH,
    },
    {
      label: SETTINGS_LABEL,
      path: settingsPaths,
      to: SETTINGS_INDICES_PATH,
    },
  ];

  const tabs = scenarioId ? scenarioSelectedTabs : noScenarioSelectedTabs;

  const history = useHistory();

  const handleEditProfile = () => history.push(EDIT_PROFILE_PATH);

  const menuItems = [
    {
      action: handleEditProfile,
      Icon: AccountCircle,
      label: EDIT_PROFILE_LABEL,
    },
    {
      action: handleOpenResetPassword,
      Icon: LockReset,
      label: RESET_PASSWORD_LABEL,
    },
    {
      action: () =>
        logout({ logoutParams: { returnTo: `${window.location.origin}` } }),
      Icon: Logout,
      label: LOGOUT_LABEL,
      shouldRenderDivider: true,
    },
  ];

  return (
    <>
      <ResetPassword
        handleClose={handleCloseResetPassword}
        isOpen={isResetPasswordOpen}
      />
      <StyledAppBar
        $isGlobalWorkspace={!scenarioId}
        data-testid={APP_BAR_TEST_ID}
        position="sticky"
      >
        <StyledAppBarContainer>
          <InnerAppBarStack
            justifyContent={!scenarioId ? "space-between" : undefined}
          >
            <SwitcherContainer>
              <Switcher scenarioId={scenarioId} />
            </SwitcherContainer>
            <StyledTabs
              textColor="inherit"
              value={
                tabs.find(({ path }) =>
                  matchPath(pathname, {
                    exact: true,
                    path,
                  })
                )?.to || false
              }
            >
              {tabs.map(({ label, to }) => (
                <Tab
                  component={Link}
                  key={to}
                  to={to}
                  label={label}
                  value={to}
                />
              ))}
            </StyledTabs>
            <IconButton
              data-testid={USER_MENU_TEST_ID}
              onClick={handleOpenUserMenu}
            >
              <InitialsAvatar
                avatarColorKey={avatarColor}
                fullName={fullName}
              />
            </IconButton>
            <Menu
              anchorEl={popoverAnchor}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              onClose={handleCloseUserMenu}
              open={isPopoverOpen}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
            >
              {menuItems.map(
                ({ action, label, Icon, path, shouldRenderDivider }) => {
                  const linkProps = {
                    component: Link,
                    onClick: handleCloseUserMenu,
                    to: path,
                  };

                  const actionProps = {
                    onClick: (...args) => {
                      handleCloseUserMenu();
                      action(...args);
                    },
                  };

                  return [
                    shouldRenderDivider && <Divider />,
                    <MenuItem
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...(action ? actionProps : linkProps)}
                    >
                      <ListItemIcon>
                        <Icon />
                      </ListItemIcon>
                      <ListItemText>{label}</ListItemText>
                    </MenuItem>,
                  ];
                }
              )}
            </Menu>
          </InnerAppBarStack>
        </StyledAppBarContainer>
      </StyledAppBar>
      <StyledContainer>{children}</StyledContainer>
      <Snackbars />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  scenarioId: PropTypes.string,
};

Layout.defaultProps = {
  scenarioId: undefined,
};

export default Layout;
