import cipApi, { USER_TAG_TYPE } from "../../../cip-api";
import { REACT_APP_API_BASE_URL } from "../../environment";

export const DELETE_CANCEL_INVITE_URL = `${REACT_APP_API_BASE_URL}/invitations/:userId`;
export const DISABLE_USER_URL = `${REACT_APP_API_BASE_URL}/profiles/:userId`;
export const INVITE_USER_URL = `${REACT_APP_API_BASE_URL}/invitations`;

const extendedApi = cipApi.injectEndpoints({
  endpoints: (build) => ({
    cancelInvite: build.mutation({
      query: ({ invitationId }) => ({
        method: "DELETE",
        url: `/invitations/${invitationId}`,
      }),
      invalidatesTags: [USER_TAG_TYPE],
    }),
    disableUser: build.mutation({
      query: ({ userId }) => ({
        method: "DELETE",
        url: `/profiles/${userId}`,
      }),
      invalidatesTags: [USER_TAG_TYPE],
    }),
    inviteUser: build.mutation({
      query: ({ accessLevel, email, fullName }) => ({
        body: {
          inviteeAccessLevel: accessLevel,
          inviteeEmail: email,
          inviteeFullName: fullName,
        },
        method: "POST",
        url: INVITE_USER_URL,
      }),
      invalidatesTags: [USER_TAG_TYPE],
    }),
    restoreUser: build.mutation({
      query: ({ accessLevel, userId }) => ({
        body: {
          accessLevel,
          disabledAt: null,
        },
        method: "PATCH",
        url: `/profiles/${userId}`,
      }),
      invalidatesTags: [USER_TAG_TYPE],
    }),
  }),
});

export const {
  useCancelInviteMutation,
  useDisableUserMutation,
  useInviteUserMutation,
  useRestoreUserMutation,
} = extendedApi;
