import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AdminPanelSettings } from "@mui/icons-material";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import DialogTitle from "../Dialog-Title";
import Button from "../Button";
import {
  CONFIRM_DEMOTE_SELF_BUTTON_TEXT,
  EDIT_ACCESS_LEVEL_BUTTON_TEST_ID,
  EDIT_ACCESS_LEVEL_BUTTON_TEXT,
  EDIT_ACCESS_LEVEL_ERROR_TEXT,
} from "./constants";
import FormSubmissionErrorAlert from "../forms/Form-Submission-Error-Alert";
import { displaySnackbarMessage } from "../../../layout/layout-slice";
import {
  ADMIN_ACCESS_LEVEL,
  SUPER_ADMIN_ACCESS_LEVEL,
  accessLevelLabelMap,
} from "../../constants/user";
import { getAdminSettings } from "../../api/admin";
import AccessLevelSelect from "../forms/Access-Level-Select";
import { USER_MANAGEMENT_ACCESS_LEVEL_LABEL_TEXT } from "../../../settings/users/constants";
import { createRequiredError } from "../../constants/validation";
import { getMyProfile, usePatchProfileMutation } from "../../api/profile";
import { HeavyText, NoteText, UserActionText } from "./shared";
import TooltipOrFragment from "../Tooltip-Or-Fragment";

const formId = "edit-access-level";

const EditAccessLevel = ({
  accessLevel,
  handleClose,
  name,
  isOpen,
  onActionSuccess,
  userId,
}) => {
  const dispatch = useDispatch();

  const myId = useSelector(getMyProfile)?.id;

  const isSuperAdmin = accessLevel === SUPER_ADMIN_ACCESS_LEVEL;

  const defaultValues = {
    accessLevel: isSuperAdmin ? ADMIN_ACCESS_LEVEL : accessLevel,
  };

  const {
    control,
    handleSubmit,
    reset: resetForm,
    watch,
  } = useForm({
    defaultValues,
    mode: "onTouched",
  });

  const [shouldDisplayWarning, setShouldDisplayWarning] = useState(false);

  useEffect(() => {
    if (isOpen) {
      resetForm(defaultValues);
      setShouldDisplayWarning(false);
    }
  }, [isOpen]);

  const newAccessLevel = watch("accessLevel");

  const organizationName = useSelector(getAdminSettings)?.orgName;

  const [unwrappedMutate, { isError, isLoading, reset }] =
    usePatchProfileMutation();

  const editAccessLevel = (values) =>
    unwrappedMutate({ userId, ...values })
      .unwrap()
      .then(() => {
        handleClose();
        onActionSuccess?.();
        dispatch(
          displaySnackbarMessage(
            `Success. ${name}'s access level has been set to ${
              accessLevelLabelMap[values.accessLevel]
            }.`
          )
        );
      })
      .catch(() => {});

  const submit = (values) => {
    const amIDemotingMyself =
      userId && myId === userId && values.accessLevel !== ADMIN_ACCESS_LEVEL;

    if (amIDemotingMyself) {
      if (shouldDisplayWarning) {
        editAccessLevel(values);
      } else {
        setShouldDisplayWarning(true);
      }
    } else {
      editAccessLevel(values);
    }
  };

  const adminLabel = accessLevelLabelMap[ADMIN_ACCESS_LEVEL];

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <DialogTitle
        handleClose={handleClose}
        title={
          shouldDisplayWarning
            ? `Remove Your Own ${accessLevelLabelMap[ADMIN_ACCESS_LEVEL]} Access?`
            : "Edit Access Level"
        }
      />
      {isError && (
        <FormSubmissionErrorAlert
          errorText={EDIT_ACCESS_LEVEL_ERROR_TEXT}
          formId={formId}
          resetMutate={reset}
        />
      )}
      <DialogContent>
        <Stack spacing={4}>
          <UserActionText>
            {shouldDisplayWarning ? (
              <div>
                {"You have selected to change your access level from "}
                <HeavyText>{adminLabel}</HeavyText>
                {" to "}
                <HeavyText>{accessLevelLabelMap[newAccessLevel]}</HeavyText>
                {". If you continue, then you will lose access to "}
                {adminLabel}
                {
                  " capabilities including the ability to restore your own access back to "
                }
                {adminLabel}.
              </div>
            ) : (
              <div>
                <HeavyText>{name}</HeavyText>
                {` currently has `}
                <HeavyText>{accessLevelLabelMap[accessLevel]}</HeavyText>
                {` access to `}
                <HeavyText>{organizationName}</HeavyText>. Select a new access
                level to update their permissions in the software.
              </div>
            )}
            <NoteText>
              Note: changes may take up to 30 mins to take affect.
            </NoteText>
          </UserActionText>
          {!shouldDisplayWarning && (
            <Controller
              control={control}
              name="accessLevel"
              render={({
                field: { onBlur, onChange, value },
                fieldState: { error },
              }) => (
                <AccessLevelSelect
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  label={USER_MANAGEMENT_ACCESS_LEVEL_LABEL_TEXT}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
              rules={{
                required: createRequiredError(
                  USER_MANAGEMENT_ACCESS_LEVEL_LABEL_TEXT
                ),
              }}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <form id={formId} onSubmit={handleSubmit(submit)}>
          <TooltipOrFragment
            title={
              isSuperAdmin
                ? `You can't edit an ${accessLevelLabelMap[SUPER_ADMIN_ACCESS_LEVEL]}'s access level`
                : undefined
            }
          >
            <div>
              <Button
                disabled={isSuperAdmin}
                data-testid={EDIT_ACCESS_LEVEL_BUTTON_TEST_ID}
                isLoading={isLoading}
                startIcon={<AdminPanelSettings />}
                type="submit"
                variant="contained"
              >
                {shouldDisplayWarning
                  ? CONFIRM_DEMOTE_SELF_BUTTON_TEXT
                  : EDIT_ACCESS_LEVEL_BUTTON_TEXT}
              </Button>
            </div>
          </TooltipOrFragment>
        </form>
      </DialogActions>
    </Dialog>
  );
};

EditAccessLevel.propTypes = {
  accessLevel: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onActionSuccess: PropTypes.func,
  userId: PropTypes.string.isRequired,
};

EditAccessLevel.defaultProps = {
  onActionSuccess: undefined,
};

export default EditAccessLevel;
