import { APPLICATION_JSON_ACCEPT, calculateCurrentFYEYear } from "utils";
import { createSelector } from "@reduxjs/toolkit";
import cipApi, { ADMIN_SETTINGS_TAG_TYPE } from "../../cip-api";
import { REACT_APP_API_BASE_URL } from "../environment";

export const PATCH_ADMIN_SETTINGS_URL = `${REACT_APP_API_BASE_URL}/admin/settings`;

const extendedApi = cipApi.injectEndpoints({
  endpoints: (build) => ({
    adminSettings: build.query({
      query: () => ({
        url: `/admin/settings`,
      }),
      providesTags: [ADMIN_SETTINGS_TAG_TYPE],
    }),
    patchAdminSettings: build.mutation({
      invalidatesTags: [ADMIN_SETTINGS_TAG_TYPE],
      query: ({ body }) => ({
        body,
        headers: {
          Accept: APPLICATION_JSON_ACCEPT,
          "Content-Type": APPLICATION_JSON_ACCEPT,
        },
        method: "PATCH",
        url: `/admin/settings`,
      }),
    }),
  }),
});

export const { useAdminSettingsQuery, usePatchAdminSettingsMutation } =
  extendedApi;

export const adminSettingsSelector =
  extendedApi.endpoints.adminSettings.select();

export const getAdminSettings = createSelector(
  adminSettingsSelector,
  (adminSettingsQuery) => adminSettingsQuery?.data
);

export const getOrganizationName = createSelector(
  getAdminSettings,
  (adminSettings) => adminSettings?.orgName
);

export const getCurrentFYEYear = createSelector(
  process.env.NODE_ENV === "test"
    ? () => ({ fiscalYearStartMonth: 7 })
    : getAdminSettings,
  (adminSettings) => {
    const fiscalYearStartMonth = adminSettings?.fiscalYearStartMonth;

    return calculateCurrentFYEYear(fiscalYearStartMonth);
  }
);
