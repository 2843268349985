export const SCENARIO_ICON_LABEL_TEXT = "Scenario Icon";
export const SCENARIO_ICON_HELPER_TEXT =
  "Select a thematic icon to help your team quickly recognize this scenario";
export const SCENARIO_NAME_LABEL_TEXT = "Scenario Name";
export const SCENARIO_DESCRIPTION_LABEL_TEXT = "Scenario Description";
export const SCENARIO_DESCRIPTION_HELPER_TEXT =
  "What is the purpose of this scenario? Describe the unique intention or possible future it anticipates.";
export const SCENARIO_LABELS_LABEL_TEXT =
  "Select 1-3 thematic intentions that describe this scenario";

export const CHANGE_SCENARIO_CONFIRM_LEAVE_TEXT =
  "Leave page and discard changes? This action cannot be undone.";

export const ICON_SELECTED_TEST_ID = "icon-selected";

export const ICON_SELECTABLE_TEST_ID = "selectable-icon";

export const LABEL_SELECTED_TEST_ID = "label-selected";
export const LABEL_SELECTABLE_TEST_ID = "selectable-label";

export const LABEL_PICKER_ERROR_TEXT = "Must select between 1-3 labels";

export const COST_ALLOCATION_TYPE_S_CURVE_LABEL = "Automatic S-Curve";
export const COST_ALLOCATION_TYPE_MANUAL_LABEL = "Manual";

export const COST_ALLOCATION_TYPE_LABEL = "Cost Allocation";
export const COST_ALLOCATION_TYPE_HELPER_TEXT =
  "Choose automatic s-curve cost allocation to automatically assign annual costs. Choose manual cost allocation to assign them manually.";
export const COST_ALLOCATION_100_PERCENT_ERROR_TEXT = "Must total 100%";

export const COST_ALLOCATION_MANUAL_LABEL =
  "Allocate non-escalated cost(s) for each year";
export const COST_ALLOCATION_S_CURVE_LABEL =
  "Automatic non-escalated cost(s) for each year ";

export const COST_ALLOCATION_BAR_TEST_ID = "cost-allocation-bar";

export const DRIVER_ALLOCATION_ADD_DRIVER_BUTTON_TEXT = "ADD DRIVER";
export const DRIVER_ALLOCATION_DRIVER_LABEL_PRETEXT = "Driver";
export const DRIVER_ALLOCATION_PERCENTAGE_LABEL_TEXT = "% Allocated";

export const DRIVER_ALLOCATIONS_100_PERCENT_ERROR_TEXT =
  "Sum of drivers must total 100%";

export const REMOVE_DRIVER_ICON_TEST_ID = "remove-driver";

export const FORM_SUBMISSION_ERROR_CLOSE_ICON_TEST_ID =
  "form-submission-error-close-icon";

export const reactHookFormSetValueProps = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true,
};

export const ACCESS_LEVEL_SELECT_OPTION = "access-level-select-option";
