export const SUPER_ADMIN_ACCESS_LEVEL = "SUPER_ADMIN";
export const ADMIN_ACCESS_LEVEL = "ADMIN";
export const EDITOR_ACCESS_LEVEL = "EDITOR";
export const GLOBAL_EDITOR_ACCESS_LEVEL = "GLOBAL_EDITOR";
export const VIEWER_ACCESS_LEVEL = "VIEWER";

export const accessLevelLabelMap = {
  [SUPER_ADMIN_ACCESS_LEVEL]: "MME Admin",
  [ADMIN_ACCESS_LEVEL]: "Administrator",
  [EDITOR_ACCESS_LEVEL]: "Editor",
  [GLOBAL_EDITOR_ACCESS_LEVEL]: "Global Editor",
  [VIEWER_ACCESS_LEVEL]: "Viewer",
};

export const atLeastEditorAccessLevels = [
  SUPER_ADMIN_ACCESS_LEVEL,
  ADMIN_ACCESS_LEVEL,
  GLOBAL_EDITOR_ACCESS_LEVEL,
  EDITOR_ACCESS_LEVEL,
];

export const atLeastGlobalEditorAccessLevels = [
  SUPER_ADMIN_ACCESS_LEVEL,
  ADMIN_ACCESS_LEVEL,
  GLOBAL_EDITOR_ACCESS_LEVEL,
];

export const atLeastAdminAccessLevels = [
  SUPER_ADMIN_ACCESS_LEVEL,
  ADMIN_ACCESS_LEVEL,
];

export const FULL_NAME_LABEL_TEXT = "Full Name";
export const USER_EMAIL_LABEL_TEXT = "Email";

export const USER_STATUS_ACTIVE = "ACTIVE";
export const USER_STATUS_DEACTIVATED = "DEACTIVATED";
export const USER_STATUS_INVITE_PENDING = "INVITE_PENDING";
export const USER_STATUS_INVITE_EXPIRED = "INVITE_EXPIRED";
