import React, { useCallback, useEffect } from "react";
import { Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import {
  closeSnackbar,
  getIsSnackbarOpen,
  getSnackbarMessage,
} from "./layout-slice";
import { snackbarAutoHideDuration } from "../shared/theme";

const Snackbars = () => {
  const dispatch = useDispatch();

  const message = useSelector(getSnackbarMessage);
  const isOpen = useSelector(getIsSnackbarOpen);

  const handleClose = () => dispatch(closeSnackbar());

  const debouncedClose = useCallback(
    debounce(handleClose, snackbarAutoHideDuration),
    []
  );

  useEffect(() => {
    if (message) {
      debouncedClose();
    }
  }, [message]);

  return (
    <Snackbar
      autoHideDuration={null}
      message={message}
      onClose={handleClose}
      open={isOpen}
    />
  );
};

export default Snackbars;
