import PropTypes from "prop-types";

export const CIP_UNDO_BUTTON_TEXT = "UNDO";

export const CIP_PROJECTS_TABLE_ERROR_TEXT = "Failed to load Project List";
export const CIP_PROJECTS_TABLE_ROW_TEST_ID = "cip-projects-table-row";
export const CIP_PROJECTS_COLUMN_CONFIGURATION_LOCAL_STORAGE_KEY =
  "cip-project-column-configuration";
export const CIP_PROJECTS_COLUMN_CONFIGURATION_TITLE = "Select Project Columns";
export const CIP_PROJECTS_SYNC_NOTIFICATION_BADGE_TEST_ID =
  "cip-sync-notification-badge";
export const CIP_SYNC_SCENARIO_PROJECTS_ICON_TEST_ID = "cip-sync-projects-icon";

export const CIP_DONUT_SPARK_CHART_ARC_TEST_ID = "cip-donut-spark-arc";
export const CIP_DONUT_FULL_CHART_ARC_TEST_ID = "cip-donut-full-arc";

export const CIP_PROJECT_SUMMARY_TITLE = "SELECTED PROJECT";

export const CIP_ESCALATION_SPARK_CHART_LINE_TEST_ID =
  "cip-escalation-spark-line";

export const CIP_SYNC_PROJECT_BUTTON_TEXT = "Sync changes";
export const CIP_ENABLE_PROJECT_BUTTON_TEXT = "Turn on";
export const CIP_DISABLE_PROJECT_BUTTON_TEXT = "Turn off";
export const CIP_NAVIGATE_TO_SCENARIO_PROJECT_BUTTON_TEXT =
  "Go to Scenario Project";

export const CIP_NAME_ENABLE_BUTTON_TEST_ID = "cip-name-enable";

export const CIP_PROJECTS_SEARCH_PLACEHOLDER = "Search all";

export const CIP_YEARS_SHOWN_LABEL_TEXT = "Select years shown";

export const CIP_YEARS_SHOWN_SUBMIT_BUTTON_TEXT = "SAVE CHANGES";

export const CIP_STACKED_BAR_TEST_ID = "stacked-bar-bar";
export const CIP_FILTERED_PROJECTS_BUCKET_TEST_ID =
  "stacked-bar-filtered-bucket";

export const CIP_GANTT_BAR_TEST_ID = "gantt-bar";
export const CIP_MAX_END_YEAR_GANTT_BAR_TEST_ID = "gantt-max-end-year";

export const CIP_GANTT_HEADER_ACTIONS_BUTTON_TEST_ID = "gantt-header-actions";

export const CIP_GANTT_ROW_ACTIONS_BUTTON_TEST_ID = "gantt-row-actions";

export const CIP_MOUSE_EVENT_WRAPPER_TEST_ID = "cip-mouse-event-wrapper";

export const CIP_CHART_HEADER_ACTION_EDIT_YEARS_SHOWN_BUTTON_TEXT =
  "Edit years shown";

export const CIP_CHART_HEADER_ACTION_SHOW_MAX_END_YEARS = "Show max end years";
export const CIP_CHART_HEADER_ACTION_HIDE_MAX_END_YEARS = "Hide max end years";

export const CIP_CHART_HEADER_ACTION_SHOW_FILTERED_PROJECTS =
  "Show filtered projects";
export const CIP_CHART_HEADER_ACTION_HIDE_FILTERED_PROJECTS =
  "Hide filtered projects";

export const CIP_YEAR_AXIS_TICK_TEST_ID_GANTT = "cip-year-axis-tick-gantt";
export const CIP_YEAR_AXIS_TICK_TEST_ID_STACKED_BAR =
  "cip-year-axis-tick-stacked-bar";

export const CIP_EMPTY_TITLE = "No Projects Yet";
export const CIP_EMPTY_SUB_TEXT =
  "Add projects to your scenario so you can edit the CIP";
export const CIP_EMPTY_BUTTON_TEXT = "GO TO SCENARIO PROJECTS";

export const CIP_DRAGGED_PROJECT_GANTT_GUIDES =
  "cip-dragged-project-gantt-guides";
export const CIP_DRAGGED_PROJECT_STACKED_BAR_GUIDES =
  "cip-dragged-project-stacked-bar-guides";

export const CIP_HOVERED_PROJECT_AXIS_GUIDE_GANTT =
  "cip-hovered-project-axis-guide-gantt";
export const CIP_HOVERED_PROJECT_AXIS_GUIDE_STACKED_BAR =
  "cip-hovered-project-axis-guide-stacked-bar";

export const chartActionsMenuPadding = 60;

export const STACKED_BAR_BUCKET_ID = "stacked-bucket";
export const STACKED_BAR_FILTER_BUCKET_ID = "stacked-filtered-bucket";

export const CIP_FETCH_ERROR_DISPLAY_TEXT = "Failed to load CIP Dashboard";

export const CIP_STACKED_BAR_ACTIONS_BUTTON_TEST_ID = "cip-stacked-bar-actions";

export const CIP_TOP_DRAG_AREA_TEST_ID = "top-drag-area";
export const CIP_LEFT_DRAG_AREA_TEST_ID = "left-drag-area";
export const CIP_TOP_LEFT_QUADRANT_TEST_ID = "cip-top-left-quadrant";
export const CIP_BOTTOM_RIGHT_QUADRANT_TEST_ID = "cip-bottom-right-quadrant";

export const CIP_YEAR_AXIS_YEAR_INTERACTION_TARGET_TEST_ID =
  "year-axis-year-interaction-target";

export const CIP_YEAR_AXIS_YEAR_INTERACTION_TARGET_TOOLTIP_ERROR_TEXT =
  "Unable to fetch data. Refresh browser to try again.";

export const CIP_FETCH_YEARLY_BREAKDOWN_ERROR_TEXT =
  "Failed to fetch yearly driver breakdown";

export const CIP_GANTT_SCHEDULE_RESIZE_QUADRANT_TEXT = "Schedule";

export const CIP_YEARS_SHOWN_SESSION_STORAGE_KEY = "cip-years-shown";

export const createCipYearsShownSessionStorageKey = (scenarioId) =>
  `${CIP_YEARS_SHOWN_SESSION_STORAGE_KEY}-${scenarioId}`;

export const CIP_SHOW_MAX_END_YEARS_SESSION_STORAGE_KEY = "cip-max-end-years";

export const createCipMaxEndYearsSessionStorageKey = (scenarioId) =>
  `${CIP_SHOW_MAX_END_YEARS_SESSION_STORAGE_KEY}-${scenarioId}`;

export const stackedBarHeightToRemove = 0.3;

export const minimumQuadrantHeight = 212;
export const minimumQuadrantWidth = 376;

export const dragAreaSpace = 16;

export const donutDriverChartPropType = PropTypes.arrayOf(
  PropTypes.shape({
    escalatedCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    driverName: PropTypes.string.isRequired,
    driverId: PropTypes.string.isRequired,
    percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
  })
);
