import Decimal from "decimal.js";

const oneBillion = 1000000000;
const oneMillion = 1000000;
const oneThousand = 1000;

export const formatCurrencyShort = (number = 0, decimalPlaces = 0) => {
  const divideAndFormat = (divisor) =>
    new Decimal(number)
      .dividedBy(divisor)
      .toDecimalPlaces(decimalPlaces, Decimal.ROUND_HALF_DOWN);

  if (number >= oneBillion) {
    return `${divideAndFormat(oneBillion)}B`;
  }
  if (number >= oneMillion) {
    return `${divideAndFormat(oneMillion)}M`;
  }

  if (number >= oneThousand) {
    return `${divideAndFormat(oneThousand)}k`;
  }

  return `${number}`;
};

export const formatPluralOrSingularLabel = (amount, label) =>
  `${label}${amount > 1 || amount === 0 ? "s" : ""}`;
