import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  CancelScheduleSend,
  HowToReg,
  MarkEmailUnread,
  NoAccounts,
} from "@mui/icons-material";
import useIsUserActionAvailable from "../../shared/components/user/use-is-user-action-available";
import useHasAccess from "../../shared/hooks/use-has-access";
import usePopover from "../../shared/hooks/use-popover";
import TooltipOrFragment from "../../shared/components/Tooltip-Or-Fragment";
import {
  USER_STATUS_ACTIVE,
  USER_STATUS_DEACTIVATED,
  USER_STATUS_INVITE_EXPIRED,
  USER_STATUS_INVITE_PENDING,
  atLeastAdminAccessLevels,
} from "../../shared/constants/user";
import {
  USER_STATUS_ACTIVE_BUTTON_TEXT,
  USER_STATUS_BUTTON_ICON_TEST_ID,
  USER_STATUS_DEACTIVATED_BUTTON_TEXT,
  USER_STATUS_INVITE_EXPIRED_BUTTON_TEXT,
  USER_STATUS_INVITE_PENDING_BUTTON_TEXT,
} from "./constants";
import { typography } from "../../shared/theme";
import Button from "../../shared/components/Button";
import UserActionsMenu from "../../shared/components/user/User-Actions-Menu";

const StatusButton = styled(Button).attrs((props) => ({
  color: "text",
  variant: "text",
  ...props,
}))`
  font-weight: ${typography.fontWeightRegular};
  text-transform: unset;
`;

const statusMap = {
  [USER_STATUS_ACTIVE]: {
    buttonText: USER_STATUS_ACTIVE_BUTTON_TEXT,
    Icon: HowToReg,
  },
  [USER_STATUS_DEACTIVATED]: {
    buttonText: USER_STATUS_DEACTIVATED_BUTTON_TEXT,
    Icon: NoAccounts,
  },
  [USER_STATUS_INVITE_PENDING]: {
    buttonText: USER_STATUS_INVITE_PENDING_BUTTON_TEXT,
    Icon: MarkEmailUnread,
  },
  [USER_STATUS_INVITE_EXPIRED]: {
    buttonText: USER_STATUS_INVITE_EXPIRED_BUTTON_TEXT,
    color: "error",
    Icon: CancelScheduleSend,
  },
};

const UserStatus = ({ onActionSuccess, profile }) => {
  const {
    handleClickOpenPopover,
    handleClosePopover,
    isPopoverOpen,
    popoverAnchor,
  } = usePopover();

  const { status } = profile;

  const isUserActionAvailable = useIsUserActionAvailable(status);

  const accessLevelsRequired = atLeastAdminAccessLevels;

  const { hasAccess, noAccess } = useHasAccess(atLeastAdminAccessLevels);

  const shouldShowNoActionsMessage = !isUserActionAvailable && hasAccess;

  const { buttonText, color, Icon } = statusMap[status];

  const isButtonDisabled = !isUserActionAvailable || noAccess;

  return (
    <>
      <TooltipOrFragment
        disableInteractive
        title={
          shouldShowNoActionsMessage
            ? "No actions available for this user"
            : undefined
        }
      >
        <div>
          <StatusButton
            accessLevelsRequired={accessLevelsRequired}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(color ? { color } : {})}
            disabled={isButtonDisabled}
            onClick={handleClickOpenPopover}
            startIcon={
              <Icon
                data-testid={USER_STATUS_BUTTON_ICON_TEST_ID}
                color={isButtonDisabled ? "disabled" : color || "action"}
              />
            }
          >
            {buttonText}
          </StatusButton>
        </div>
      </TooltipOrFragment>
      <UserActionsMenu
        anchorEl={popoverAnchor}
        handleClose={handleClosePopover}
        isOpen={isPopoverOpen}
        onActionSuccess={onActionSuccess}
        profile={profile}
      />
    </>
  );
};

UserStatus.propTypes = {
  onActionSuccess: PropTypes.func,
  profile: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
};

UserStatus.defaultProps = {
  onActionSuccess: undefined,
};

export default UserStatus;
