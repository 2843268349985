import React from "react";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@mui/material";
import { SkeletonIfLoading } from "../Skeleton";
import { tooltipDelayProps } from "../../theme";
import {
  COLUMN_CONFIGURATION_ICON_TEST_ID,
  COLUMN_CONFIGURATION_ICON_TOOLTIP,
} from "./constants";
import EditTable from "../icons/Edit-Table";

const ColumnConfigurationButton = ({
  handleOpenColumnConfiguration,
  isLoading,
}) => (
  <SkeletonIfLoading isLoading={isLoading} variant="circular">
    <Tooltip
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...tooltipDelayProps}
      title={COLUMN_CONFIGURATION_ICON_TOOLTIP}
    >
      <IconButton
        data-testid={COLUMN_CONFIGURATION_ICON_TEST_ID}
        onClick={handleOpenColumnConfiguration}
      >
        <EditTable color="action" />
      </IconButton>
    </Tooltip>
  </SkeletonIfLoading>
);

ColumnConfigurationButton.propTypes = {
  handleOpenColumnConfiguration: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ColumnConfigurationButton;
