import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { REACT_APP_API_BASE_URL } from "./shared/environment";
import { getAccessToken } from "./shared/reducers/base-slice";

export const SCENARIO_PROJECT_TAG_TYPE = "scenario-project";
export const GLOBAL_PROJECT_TAG_TYPE = "global-project";
export const SCENARIO_TAG_TYPE = "scenario";
export const UTILITY_TAG_TYPE = "utility";
export const ADMIN_SETTINGS_TAG_TYPE = "admin-settings";
export const LABELS_TAG_TYPE = "labels";
export const CIP_REPORT_CONFIG_TYPE = "cip-report-config";
export const USER_TAG_TYPE = "user";

const cipApi = createApi({
  reducerPath: "cipApi",
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getAccessToken(getState());

      headers.set("Authorization", `Bearer ${token}`);

      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: [
    ADMIN_SETTINGS_TAG_TYPE,
    CIP_REPORT_CONFIG_TYPE,
    SCENARIO_PROJECT_TAG_TYPE,
    GLOBAL_PROJECT_TAG_TYPE,
    LABELS_TAG_TYPE,
    SCENARIO_TAG_TYPE,
    USER_TAG_TYPE,
    UTILITY_TAG_TYPE,
  ],
});

export default cipApi;
