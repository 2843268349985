import { MoreVert, Add } from "@mui/icons-material";
import PropTypes from "prop-types";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useRef } from "react";
import styled from "styled-components";
import usePopover from "../../hooks/use-popover";
import EditTable from "../icons/Edit-Table";
import { centerPopoverProps, tableBorder } from "../../theme";
import {
  VIRTUALIZED_TABLE_ACTIONS_MENU_BUTTON_TEST_ID,
  VIRTUALIZED_TABLE_ACTIONS_MENU_CONFIGURE_COLUMNS_BUTTON_TEXT,
  VIRTUALIZED_TABLE_ROW_ACTIONS_MENU_BUTTON_TEST_ID,
} from "./constants";

export const actionsColumnWidth = 40;

const BaseActionsCell = styled(Stack).attrs(() => ({
  alignItems: "center",
  justifyContent: "center",
}))`
  position: sticky;
  right: 0;
  width: ${actionsColumnWidth}px;
  z-index: 1;
`;

const ActionsHeadCell = styled(BaseActionsCell)`
  background-color: inherit;
  border-bottom: ${tableBorder};
`;

const ActionsCell = styled(BaseActionsCell)`
  background-color: inherit;
  overflow: visible;
`;

export const ActionsIconButton = styled(IconButton)``;

export const TableRowActionsMenu = ({
  actionsMenuProps,
  ActionsMenu,
  rowActionsTooltip,
}) => {
  const {
    handleClickOpenPopover,
    handleClosePopover,
    isPopoverOpen,
    popoverAnchor,
  } = usePopover();

  const handleClick = (event) => {
    event.stopPropagation();

    handleClickOpenPopover(event);
  };

  const handleClose = (event) => {
    event.stopPropagation();

    handleClosePopover(event);
  };

  return (
    <ActionsCell>
      <Tooltip disableInteractive title={rowActionsTooltip}>
        <ActionsIconButton
          data-testid={VIRTUALIZED_TABLE_ROW_ACTIONS_MENU_BUTTON_TEST_ID}
          onClick={handleClick}
          onKeyDown={(event) => event.stopPropagation()}
          size="small"
        >
          <MoreVert />
        </ActionsIconButton>
      </Tooltip>
      <Menu
        anchorEl={popoverAnchor}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...centerPopoverProps}
        onClose={handleClose}
        open={isPopoverOpen}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <ActionsMenu {...actionsMenuProps} onClose={handleClose} />
      </Menu>
    </ActionsCell>
  );
};

TableRowActionsMenu.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  actionsMenuProps: PropTypes.object.isRequired,
  ActionsMenu: PropTypes.func.isRequired,
  rowActionsTooltip: PropTypes.string,
};

TableRowActionsMenu.defaultProps = {
  rowActionsTooltip: undefined,
};

const CombinedColumnConfiguration = ({
  handleExpandColumns,
  handleOpenColumnConfiguration,
  numberOfHiddenColumns,
  shouldAllowColumnExpansion,
}) => {
  const actionsRef = useRef(null);

  const {
    handleClickOpenPopover,
    handleClosePopover,
    isPopoverOpen,
    popoverAnchor,
  } = usePopover();

  const options = [
    {
      label: VIRTUALIZED_TABLE_ACTIONS_MENU_CONFIGURE_COLUMNS_BUTTON_TEXT,
      Icon: EditTable,
      onClick: () => handleOpenColumnConfiguration(actionsRef),
    },
    ...(shouldAllowColumnExpansion
      ? [
          {
            label: `Show ${numberOfHiddenColumns} hidden columns`,
            Icon: Add,
            onClick: handleExpandColumns,
          },
        ]
      : []),
  ];

  const createIconClickHandler = (onClick) => (event) => {
    onClick(event);
    handleClosePopover();
  };

  return (
    <>
      <ActionsHeadCell>
        <Tooltip disableInteractive title="Table Actions">
          <IconButton
            data-testid={VIRTUALIZED_TABLE_ACTIONS_MENU_BUTTON_TEST_ID}
            onClick={handleClickOpenPopover}
            ref={actionsRef}
            size="small"
          >
            <MoreVert />
          </IconButton>
        </Tooltip>
      </ActionsHeadCell>
      <Menu
        anchorEl={popoverAnchor}
        onClose={handleClosePopover}
        open={isPopoverOpen}
      >
        {options.map(({ label, Icon, onClick }) => (
          <MenuItem key={label} onClick={createIconClickHandler(onClick)}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

CombinedColumnConfiguration.propTypes = {
  handleExpandColumns: PropTypes.func.isRequired,
  handleOpenColumnConfiguration: PropTypes.func.isRequired,
  numberOfHiddenColumns: PropTypes.number,
  shouldAllowColumnExpansion: PropTypes.bool.isRequired,
};

CombinedColumnConfiguration.defaultProps = {
  numberOfHiddenColumns: 0,
};

export default CombinedColumnConfiguration;
