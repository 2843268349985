import { useState } from "react";

const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isPopoverOpen = !!anchorEl;

  const handleClickOpenPopover = (event) => setAnchorEl(event.currentTarget);

  const handleClosePopover = (event) => {
    event?.stopPropagation();

    setAnchorEl(null);
  };

  const handleOpenPopoverWithRef = (ref) => setAnchorEl(ref.current);

  return {
    handleClosePopover,
    handleClickOpenPopover,
    handleOpenPopoverWithRef,
    isPopoverOpen,
    popoverAnchor: anchorEl,
  };
};

export default usePopover;
