export const PROJECT_NAME_LABEL_TEXT = "Project Name";
export const PROJECT_KEY_LABEL_TEXT = "Project ID";
export const PROJECT_UTILITY_LABEL_TEXT = "Utility";
export const PROJECT_TOTAL_COST_LABEL_TEXT = "Total Cost";
export const PROJECT_COST_YEAR_LABEL_TEXT = "Cost Year";
export const PROJECT_END_YEAR_LABEL_TEXT = "Planned End Year";
export const PROJECT_DURATION_LABEL_TEXT = "Duration (Years)";
export const PROJECT_MAX_END_YEAR_LABEL_TEXT = "Max End Year";
export const PROJECT_NOTES_LABEL_TEXT = "Notes (Optional)";
export const PROJECT_DRIVER_ALLOCATIONS_LABEL_TEXT =
  "Assign 1 or more drivers and the percentage of funding allocated for each — Must total 100%";
export const PROJECT_APPROVAL_STATUS_LABEL_TEXT = "Approval Status";

export const PROJECT_MAX_END_YEAR_BEFORE_END_YEAR_ERROR_TEXT = `${PROJECT_MAX_END_YEAR_LABEL_TEXT} cannot come before ${PROJECT_END_YEAR_LABEL_TEXT}`;
export const PROJECT_KEY_NOT_UNIQUE_ERROR_TEXT =
  "This ID is already being used";

export const PROJECT_KEY_HELPER_TEXT =
  "Choose a unique identifcation code to easily look up this project";
export const PROJECT_UTILITY_HELPER_TEXT =
  "Select the utility that manages this project";
export const PROJECT_TOTAL_COST_HELPER_TEXT =
  "What is the current total cost of this project? Do not include escalations.";
export const PROJECT_COST_YEAR_HELPER_TEXT =
  "Use current year unless the project cost estimate is from a prior calendar year";
export const PROJECT_END_YEAR_HELPER_TEXT = "What year will this project end?";
export const PROJECT_DURATION_HELPER_TEXT =
  "How many years will this project last? If the project duration is less than 1 year, select 1.";
export const PROJECT_MAX_END_YEAR_HELPER_TEXT =
  "Based on regulatory and engineering constraints, when is the latest this project can feasibly be completed?";
export const PROJECT_NOTES_HELPER_TEXT =
  "Include any additional information here that you might want to reference later";
export const PROJECT_APPROVAL_STATUS_HELPER_TEXT =
  "Approved projects are part of the approved master plan";

export const PROJECT_UTILITY_OPTION_TEST_ID = "project-utility-option";
export const PROJECT_COST_YEAR_OPTION_TEST_ID = "project-cost-year-option";
export const PROJECT_APPROVAL_STATUS_OPTION_TEST_ID =
  "project-approval-status-option";

export const PROJECT_DETAILS_SECTION_TITLE = "Project Details";
export const DRIVER_ALLOCATIONS_SECTION_TITLE = "Driver Funding Allocations";
export const PROJECT_TIMING_SECTION_TITLE = "Project Timing";
export const PROJECT_COST_SECTION_TITLE = "Project Cost";
export const PROJECT_DESCRIPTION_SECTION_TITLE = "Project Notes";

export const CHANGE_PROJECT_DISCARD_BUTTON_TEXT = "DISCARD CHANGES";
export const CREATE_PROJECT_BUTTON_TEXT = "CREATE PROJECT";
export const EDIT_PROJECT_BUTTON_TEXT = "SAVE CHANGES";
export const DUPLICATE_PROJECT_BUTTON_TEXT = "DUPLICATE PROJECT";

export const CREATE_PROJECT_ERROR_DISPLAY_TEXT = "Failed to create project";
export const DUPLICATE_PROJECT_ERROR_DISPLAY_TEXT =
  "Failed to duplicate project";

export const EDIT_PROJECT_ERROR_TEXT = "Failed to edit project";
export const EDIT_PROJECT_ERROR_DISPLAY_TEXT = "Failed to save changes";

export const CONFIRM_LEAVE_CREATE_PROJECT_DESCRIPTION =
  "If you leave now, then your new Global Project will be discarded.";

export const CONFIRM_LEAVE_EDIT_PROJECT_DESCRIPTION =
  "Leave page and discard changes? This action cannot be undone.";

export const CREATE_PROJECT_PAGE_TITLE = "Create New Global Project";
export const EDIT_PROJECT_PAGE_TITLE = "Edit Global Project";
export const CHANGE_PROJECT_PAGE_TOOLTIP_DESCRIPTION =
  "Global projects are shared universally. Each scenario creates a decoupled instance of the project that can be synced with changes from the original.";

export const DELETE_PROJECT_CONFIRM_BUTTON_TEXT = "DELETE";
export const DELETE_PROJECT_CONFIRM_BUTTON_TEST_ID = "project-delete-confirm";
export const DELETE_PROJECT_CANCEL_BUTTON_TEXT = "CANCEL";

export const DELETE_PROJECT_HAS_INSTANCES_ERROR =
  "The project being deleted has instances";

export const DELETE_PROJECT_HAS_MULTIPLE_INSTANCES_ERROR_DISPLAY_TEXT =
  "Some of the selected projects could not be deleted because they are in use by one or more scenarios. First remove each project from all scenarios and then return to delete the global project.";

export const DELETE_PROJECT_CLOSE_ICON_TEST_ID = "delete-project-close";

export const FETCH_CHANGE_PROJECT_ERROR_DISPLAY_TEXT = "Failed to load project";

export const PROJECT_DURATION_OPTION_TEST_ID = "project-duration-option";

export const CONFIRM_LEAVE_CHANGE_PROJECT_TITLE = "Leave and discard changes?";

export const CLOSE_CHANGE_PROJECT_TEST_ID = "close-change-project";

export const CHANGE_PROJECT_SUBMIT_BUTTON_TEST_ID = "change-project-submit";
