import styled from "styled-components";
import { Paper } from "@mui/material";
import { appBarPadding, bodyPaperMarginTop, footerHeight } from "../theme";

export default styled(Paper).attrs(() => ({
  variant: "outlined",
  square: true,
}))`
  ${({ $shouldNotFillVerticalSpace }) =>
    !$shouldNotFillVerticalSpace &&
    `min-height: calc(100% - ${bodyPaperMarginTop} - ${footerHeight});`}
  box-sizing: border-box;
  padding: ${appBarPadding};
`;
