import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { appBarHeight } from "../theme";
import { SKELETON_LOADER_TEST_ID } from "./constants";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: ${({ $shouldLeaveSpaceForAppBar }) =>
    $shouldLeaveSpaceForAppBar ? `calc(100% - ${appBarHeight})` : "100vh"};
  width: 100vw;
`;

const LoadingPage = ({ shouldLeaveSpaceForAppBar }) => (
  <Wrapper
    data-testid={SKELETON_LOADER_TEST_ID}
    $shouldLeaveSpaceForAppBar={shouldLeaveSpaceForAppBar}
  >
    <CircularProgress size={100} />
  </Wrapper>
);

LoadingPage.propTypes = {
  shouldLeaveSpaceForAppBar: PropTypes.bool.isRequired,
};

export default LoadingPage;
