import { useState } from "react";

const useDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [info, setInfo] = useState({});

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  return {
    info,
    isOpen,
    handleClose,
    handleOpen,
    setInfo,
  };
};

export default useDialog;
