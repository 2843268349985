/* eslint-disable react/prop-types */
import styled from "styled-components";
import { ManageAccounts, MoreVert } from "@mui/icons-material";
import { Chip, Link, Typography } from "@mui/material";
import React from "react";
import {
  formatInitials,
  formatPhoneNumber,
} from "../../shared/utils/formatting";
import { formatDate, formatDateDistance } from "../../shared/utils/date";
import AccessLevelBadge from "../../shared/components/user/Access-Level-Badge";
import usePopover from "../../shared/hooks/use-popover";
import UserPopover from "../../shared/components/user/User-Popover";
import { InitialsAvatar } from "../../shared/components/user/shared";
import { action } from "../../shared/theme";
import UserActionsMenu from "../../shared/components/user/User-Actions-Menu";
import {
  DISABLED_OPACITY_CONTAINER_TEST_ID,
  USER_MANAGEMENT_ACTIONS_CELL_TEST_ID,
  checkIsUserNotActive,
} from "./constants";
import useIsUserActionAvailable from "../../shared/components/user/use-is-user-action-available";
import TooltipOrFragment from "../../shared/components/Tooltip-Or-Fragment";
import { atLeastAdminAccessLevels } from "../../shared/constants/user";
import useHasAccess from "../../shared/hooks/use-has-access";
import IconButton from "../../shared/components/Icon-Button";
import UserStatus from "./User-Status";
import { ellipsis } from "../../shared/utils/styles";

export const USER_NAME_COLUMN_ID = "fullName";
export const USER_NAME_COLUMN_LABEL = "Name";
export const USER_NAME_COLUMN_TOOLTIP = "Full Name";

const DisabledOpacityContainer = styled.div.attrs(() => ({
  "data-testid": DISABLED_OPACITY_CONTAINER_TEST_ID,
}))`
  ${({ $opacity = 0.38, $profile }) =>
    checkIsUserNotActive($profile) && `opacity: ${$opacity};`}
`;

const InitialsTypography = styled(Typography).attrs(() => ({
  variant: "caption",
}))`
  line-height: unset;
`;

export const NameAndAvatarChip = (profile) => {
  const { avatarColor, fullName } = profile;

  const {
    handleClickOpenPopover,
    handleClosePopover,
    isPopoverOpen,
    popoverAnchor,
  } = usePopover();

  const avatarSize = 24;

  return (
    <>
      <DisabledOpacityContainer $profile={profile}>
        <Chip
          $disabled={checkIsUserNotActive(profile)}
          icon={
            <InitialsAvatar
              avatarColorKey={avatarColor}
              sx={{
                height: avatarSize,
                width: avatarSize,
              }}
            >
              <InitialsTypography>
                {formatInitials(fullName)}
              </InitialsTypography>
            </InitialsAvatar>
          }
          label={fullName}
          onClick={handleClickOpenPopover}
          size="medium"
          variant="filled"
        />
      </DisabledOpacityContainer>
      <UserPopover
        anchorEl={popoverAnchor}
        onClose={handleClosePopover}
        open={isPopoverOpen}
        profile={profile}
        shouldHideDirectoryLink
      />
    </>
  );
};

export const nameColumn = {
  align: "left",
  BodyCell: NameAndAvatarChip,
  id: USER_NAME_COLUMN_ID,
  isLeftPinned: true,
  label: USER_NAME_COLUMN_LABEL,
  padding: "avatarChip",
  sortCompare: "string",
  tooltip: USER_NAME_COLUMN_TOOLTIP,
  width: 200,
};

const USER_ACTIONS_COLUMN_ID = "user-actions";
const USER_ACTIONS_COLUMN_TOOLTIP = "Account management actions";

export const ActionsCell = (profile) => {
  const {
    handleClickOpenPopover,
    handleClosePopover,
    isPopoverOpen,
    popoverAnchor,
  } = usePopover();

  const { email, status } = profile;

  const isUserActionAvailable = useIsUserActionAvailable(status);

  const accessLevelsRequired = atLeastAdminAccessLevels;

  const { hasAccess, noAccess } = useHasAccess(atLeastAdminAccessLevels);

  const shouldShowNoActionsMessage = !isUserActionAvailable && hasAccess;

  return (
    <>
      <TooltipOrFragment
        disableInteractive
        title={
          shouldShowNoActionsMessage
            ? "No actions available for this user"
            : undefined
        }
      >
        <div>
          <IconButton
            accessLevelsRequired={accessLevelsRequired}
            data-testid={`${USER_MANAGEMENT_ACTIONS_CELL_TEST_ID}-${email}`}
            disabled={!isUserActionAvailable || noAccess}
            onClick={handleClickOpenPopover}
          >
            <MoreVert />
          </IconButton>
        </div>
      </TooltipOrFragment>
      <UserActionsMenu
        anchorEl={popoverAnchor}
        handleClose={handleClosePopover}
        isOpen={isPopoverOpen}
        profile={profile}
      />
    </>
  );
};

export const actionsColumn = {
  align: "center",
  BodyCell: ActionsCell,
  id: USER_ACTIONS_COLUMN_ID,
  isLeftPinned: true,
  label: "User Actions",
  padding: "icon",
  renderHeader: () => <ManageAccounts sx={{ color: action.active }} />,
  tooltip: USER_ACTIONS_COLUMN_TOOLTIP,
  width: 77,
};

export const USER_ACCESS_LEVEL_COLUMN_ID = "accessLevel";
export const USER_ACCESS_LEVEL_COLUMN_LABEL = "Access Level";
export const USER_ACCESS_LEVEL_COLUMN_TOOLTIP =
  "Specifies what each user can edit by role";

export const AccessLevelCell = (profile) => {
  const { accessLevel } = profile;

  return (
    <DisabledOpacityContainer $profile={profile}>
      <AccessLevelBadge accessLevel={accessLevel} />
    </DisabledOpacityContainer>
  );
};

export const accessLevelColumn = {
  align: "left",
  id: USER_ACCESS_LEVEL_COLUMN_ID,
  label: USER_ACCESS_LEVEL_COLUMN_LABEL,
  BodyCell: AccessLevelCell,
  sortCompare: "string",
  tooltip: USER_ACCESS_LEVEL_COLUMN_TOOLTIP,
  width: 133,
};

export const USER_EMAIL_COLUMN_ID = "email";
export const USER_EMAIL_COLUMN_LABEL = "Email";
export const USER_EMAIL_COLUMN_TOOLTIP =
  "Email address associated with their account";

const EmailLink = styled(Link)`
  display: block;

  ${ellipsis}
`;

export const EmailCell = (profile) => {
  const { email } = profile;
  return (
    <DisabledOpacityContainer $opacity={0.5} $profile={profile}>
      <EmailLink href={`mailto:${email}`} underline="hover">
        {email}
      </EmailLink>
    </DisabledOpacityContainer>
  );
};

export const emailColumn = {
  align: "left",
  id: USER_EMAIL_COLUMN_ID,
  label: USER_EMAIL_COLUMN_LABEL,
  BodyCell: EmailCell,
  renderTooltip: ({ email }) => email,
  sortCompare: "string",
  tooltip: USER_EMAIL_COLUMN_TOOLTIP,
  width: 200,
};

export const USER_JOB_TITLE_COLUMN_ID = "jobTitle";
export const USER_JOB_TITLE_COLUMN_LABEL = "Job Title";
export const USER_JOB_TITLE_COLUMN_TOOLTIP = "Function in organization";

export const jobTitleColumn = {
  align: "left",
  id: USER_JOB_TITLE_COLUMN_ID,
  label: USER_JOB_TITLE_COLUMN_LABEL,
  BodyCell: ({ jobTitle }) => jobTitle || null,
  renderTooltip: ({ jobTitle }) => jobTitle,
  sortCompare: "string",
  tooltip: USER_JOB_TITLE_COLUMN_TOOLTIP,
  width: 200,
};

export const USER_PHONE_NUMBER_COLUMN_ID = "phoneNumber";
export const USER_PHONE_NUMBER_COLUMN_LABEL = "Phone";
export const USER_PHONE_NUMBER_COLUMN_TOOLTIP = "Phone Number";

export const phoneNumberColumn = {
  align: "left",
  id: USER_PHONE_NUMBER_COLUMN_ID,
  label: USER_PHONE_NUMBER_COLUMN_LABEL,
  BodyCell: ({ phoneNumber }) =>
    phoneNumber ? formatPhoneNumber(phoneNumber) : null,
  sortCompare: "string",
  tooltip: USER_PHONE_NUMBER_COLUMN_TOOLTIP,
  width: 134,
};

export const USER_MEMBER_SINCE_COLUMN_ID = "memberSince";
export const USER_MEMBER_SINCE_COLUMN_LABEL = "Member Since";
export const USER_MEMBER_SINCE_COLUMN_TOOLTIP = "Account activation date";

export const memberSinceColumn = {
  align: "left",
  id: USER_MEMBER_SINCE_COLUMN_ID,
  label: USER_MEMBER_SINCE_COLUMN_LABEL,
  BodyCell: ({ memberSince }) => (memberSince ? formatDate(memberSince) : null),
  sortCompare: "date",
  tooltip: USER_MEMBER_SINCE_COLUMN_TOOLTIP,
  width: 170,
};

export const USER_LAST_LOGIN_COLUMN_ID = "lastLogin";
export const USER_LAST_LOGIN_COLUMN_LABEL = "Last Active";
export const USER_LAST_LOGIN_COLUMN_TOOLTIP = "Last sign in";

export const lastLoginColumn = {
  align: "left",
  id: USER_LAST_LOGIN_COLUMN_ID,
  label: USER_LAST_LOGIN_COLUMN_LABEL,
  BodyCell: ({ lastLogin }) =>
    lastLogin ? formatDateDistance(lastLogin) : null,
  sortCompare: "date",
  tooltip: USER_LAST_LOGIN_COLUMN_TOOLTIP,
  width: 140,
};

export const USER_STATUS_COLUMN_ID = "status";
export const USER_STATUS_COLUMN_LABEL = "Status";
export const USER_STATUS_COLUMN_TOOLTIP =
  "Shows account status and corresponding actions";

export const userStatusColumn = {
  align: "left",
  BodyCell: (profile) => <UserStatus profile={profile} />,
  id: USER_STATUS_COLUMN_ID,
  label: USER_STATUS_COLUMN_LABEL,
  padding: "button",
  sortCompare: "string",
  tooltip: USER_STATUS_COLUMN_TOOLTIP,
  width: 171,
};
