import { purple } from "@mui/material/colors";
import { aqua, chillLime, lightMagenta, materialTheme } from "utils";

export const headerHeight = "80px";
export const navigationWidth = "260px";

export {
  actionActivePdf,
  baseFontSizeMap,
  grey,
  materialTheme,
  outlinedBorderPdf,
  textDisabledPdf,
  textPrimaryPdf,
  textSecondaryPdf,
} from "utils";

const {
  palette: {
    action,
    common,
    error,
    grey,
    primary,
    secondary,
    success,
    info,
    text,
    warning,
  },
  transitions,
  typography,
} = materialTheme;

export const primaryHover = `${primary.main}0A`;

export const stackedBarColor = info.main;
export const stackedBarUnselectedColor = `${stackedBarColor}4D`;
export const gantBarSelectedStroke = `#00000099`;
export const disabledGanttColor = grey[300];
export const disabledUnselectedGanttColor = `${disabledGanttColor}4D`;
export const cipHoverColor = info.dark;

export const stackedBarBucketColor = `${stackedBarColor}66`;
export const stackedBarBucketHoverColor = `${info.dark}66`;
export const stackedBarBucketHoverStrokeColor = info.dark;
export const stackedBarBucketSelectedColor = stackedBarBucketColor;
export const stackedBarBucketSelectedStrokeColor = stackedBarColor;
export const stackedBarBucketUnselectedColor = `${stackedBarColor}1F`;
export const stackedBarBucketDragStroke = gantBarSelectedStroke;

export const maxEndFillColor = `${stackedBarColor}14`;
export const maxEndStrokeColor = `${stackedBarColor}80`;
export const maxEndDisabledFillColor = `${disabledGanttColor}14`;
export const maxEndDisabledStrokeColor = `${disabledGanttColor}80`;

export const greyChartLightestColor = "#EDEBEB";
export const greyChartDarkestColor = "#535252";

export const maxContainerWidth = "1920px";
const scrollBarWidthGuess = 20;

export const minContainerWidth = `${768 - scrollBarWidthGuess}px`;

export const appBarHeight = "56px";

export const appBarPaddingNumber = 16;
export const appBarPadding = `${appBarPaddingNumber}px`;
export const bodyPaperMarginTop = "80px";
export const footerHeight = "64px";

export const dividerColor = "rgba(0,0,0,.12)";

export const paperBorder = `1px solid ${dividerColor}`;
export const tableBorder = `1px solid ${dividerColor}`;
export const outlinedBorderColor = "rgba(0,0,0,.23)";
export const outlinedBorder = `1px solid ${outlinedBorderColor}`;

export const inactiveFilterButtonBorder = `solid 2px ${outlinedBorderColor}`;
export const activeFilterButtonBorder = `solid 2px ${primary.main}`;

export const actionSelectedSolid = "#D8D8D8";
export const actionHoverSolid = "#F6F6F6";
export const actionFocusSolid = "#BCBCBC";

export const iconColors = {
  green: success.dark,
  darkBlue: primary.main,
  darkRed: "#AB2F26",
  lightBlue: "#1769AA",
  lightRed: secondary.dark,
  orange: warning.main,
  yellow: warning.light,
};

export const backgroundColor = "#fafafa";

export const snackbarAutoHideDuration = 7000;

export const tooltipDelayAmount = 500;

export const tooltipDelayProps = {
  enterDelay: tooltipDelayAmount,
  enterNextDelay: tooltipDelayAmount,
};

export const tooltipInstantProps = {
  enterDelay: 0,
  enterNextDelay: 0,
};

export const centerPopoverProps = {
  anchorOrigin: {
    vertical: "center",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "center",
    horizontal: "center",
  },
};

export const avatarColorsMap = {
  purple: {
    backgroundColor: purple[500],
    textColor: common.white,
  },
  pink: {
    backgroundColor: lightMagenta,
    textColor: common.black,
  },
  lightBlue: {
    backgroundColor: aqua,
    textColor: common.black,
  },
  lightGreen: {
    backgroundColor: chillLime,
    textColor: common.black,
  },
};

export const defaultAvatarColor = Object.entries(avatarColorsMap)[0][0];

export {
  action,
  common,
  error,
  primary,
  secondary,
  success,
  info,
  text,
  transitions,
  typography,
};
