import { useScenarioQuery } from "./scenarios";

const useScenario = ({ scenarioId }) => {
  const result = useScenarioQuery(
    {
      scenarioId,
    },
    {
      skip: !scenarioId,
    }
  );

  return result;
};

export default useScenario;
