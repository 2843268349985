import { createSelector, createSlice } from "@reduxjs/toolkit";
import createVirtualizedTableSlice from "../../shared/components/table/virtualized-table-slice";
import { ASCENDING_KEY } from "../../shared/constants/sorting";
import { accessLevelLabelMap } from "../../shared/constants/user";
import { USER_NAME_COLUMN_ID } from "./user-columns";
import { doValuesMatchSearch } from "../../shared/utils/filtering";
import { userProfilesSelector } from "./api";

/* eslint-disable no-param-reassign */

const initialState = { search: "" };

const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    resetUserManagementSlice: () => initialState,
    setSearch: (state, action) => {
      state.search = action.payload;
    },
  },
});

export const { setSearch, resetUserManagementSlice } =
  userManagementSlice.actions;

export default userManagementSlice;

/* eslint-enable no-param-reassign */

const getUserManagementSlice = createSelector(
  (state) => state,
  (state) => state[userManagementSlice.name]
);

export const getSearch = createSelector(
  getUserManagementSlice,
  (slice) => slice.search
);

const getUsers = createSelector(
  (state) => state,
  (state) => userProfilesSelector()(state)?.data
);

export const getFilteredUsers = createSelector(
  getSearch,
  getUsers,
  (search, sortedUsers) =>
    sortedUsers?.filter(({ accessLevel, email, fullName, jobTitle }) =>
      doValuesMatchSearch(search, [
        accessLevelLabelMap[accessLevel],
        email,
        fullName,
        jobTitle,
      ])
    )
);

export const didFilteringMakeResultsEmpty = createSelector(
  getFilteredUsers,
  getUsers,
  (filteredUsers, users) => !!(users?.length && !filteredUsers?.length)
);

const {
  getSortedItems,
  tableSlice: userManagementTableSlice,
  virtualizedTableProps,
} = createVirtualizedTableSlice({
  defaultSort: { id: USER_NAME_COLUMN_ID, order: ASCENDING_KEY },
  name: "userManagementTableSlice",
  unsortedItemsSelector: getFilteredUsers,
});

export { getSortedItems, userManagementTableSlice, virtualizedTableProps };
