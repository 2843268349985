import React from "react";
import PropTypes from "prop-types";
import { Avatar, Stack, Typography } from "@mui/material";
import styled from "styled-components";
import {
  avatarColorsMap,
  defaultAvatarColor,
  text,
  typography,
} from "../../theme";
import { formatNumericDate } from "../../utils/date";
import { formatInitials } from "../../utils/formatting";

export const TimestampTypography = styled(Typography).attrs(() => ({
  variant: "caption",
}))`
  color: ${text.secondary};
`;

export const ProfileDate = ({ label, timestamp }) =>
  timestamp ? (
    <TimestampTypography>{`${label}: ${formatNumericDate(
      timestamp
    )}`}</TimestampTypography>
  ) : null;

ProfileDate.propTypes = {
  label: PropTypes.string.isRequired,
  timestamp: PropTypes.string,
};

ProfileDate.defaultProps = { timestamp: undefined };

export const MemberSince = styled(ProfileDate).attrs(() => ({
  label: "Member Since",
}))``;

export const LastLogin = styled(ProfileDate).attrs(() => ({
  label: "Last Login",
}))``;

export const InvitationSent = styled(ProfileDate).attrs(() => ({
  label: "Invitation Sent",
}))``;

export const TimestampStack = styled(Stack).attrs(() => ({
  spacing: 0.25,
}))``;

export const InitialsAvatar = ({
  avatarColorKey,
  children,
  className,
  fullName,
  style,
  ...rest
}) => {
  const { backgroundColor, textColor } =
    avatarColorsMap[avatarColorKey || defaultAvatarColor] || {};

  return (
    <Avatar
      className={className}
      style={{
        backgroundColor,
        color: textColor,
        ...style,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children || formatInitials(fullName)}
    </Avatar>
  );
};

InitialsAvatar.propTypes = {
  avatarColorKey: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  fullName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

InitialsAvatar.defaultProps = {
  avatarColorKey: undefined,
  children: undefined,
  className: "",
  fullName: "",
  style: {},
};

const avatarSize = 112;

export const BigAvatar = ({ avatarColorKey, fullName, ...rest }) => {
  const initials = formatInitials(fullName);

  return (
    <InitialsAvatar
      avatarColorKey={avatarColorKey}
      fullName={fullName}
      style={{
        height: avatarSize,
        width: avatarSize,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {initials ? <Typography variant="h2">{initials}</Typography> : undefined}
    </InitialsAvatar>
  );
};

BigAvatar.propTypes = {
  avatarColorKey: PropTypes.string.isRequired,
  fullName: PropTypes.string,
};

BigAvatar.defaultProps = {
  fullName: "",
};

export const HeavyText = styled.span`
  font-weight: ${typography.fontWeightBold};
`;

export const NoteText = styled.div`
  color: ${text.secondary};
  font-style: italic;
  margin-top: 8px;
`;

export const NoteTextStack = styled(Stack).attrs(() => ({ spacing: 1 }))``;

export const UserActionText = styled(Typography).attrs(() => ({
  component: "div",
  variant: "body1",
}))``;
