import {
  Dialog,
  DialogActions,
  DialogContent,
  Snackbar,
  Typography,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import React from "react";
import DialogTitle from "../shared/components/Dialog-Title";
import Button from "../shared/components/Button";
import { useResetPasswordMutation } from "./api";
import useSuccessSnackbar from "../shared/hooks/use-success-snackbar";
import FormSubmissionErrorAlert from "../shared/components/forms/Form-Submission-Error-Alert";
import {
  RESET_PASSWORD_BUTTON_TEXT,
  RESET_PASSWORD_CLOSE_ICON_TEST_ID,
  RESET_PASSWORD_DIALOG_TITLE,
  RESET_PASSWORD_ERROR_TEXT,
} from "./constants";

const formId = "reset-password";

const ResetPassword = ({ handleClose, isOpen }) => {
  const { user } = useAuth0();

  const { handleOpenSuccessSnackbarWithMessage, successSnackbarProps } =
    useSuccessSnackbar();

  const [mutate, { isError, isLoading, reset }] = useResetPasswordMutation();

  const handleResetPassword = (event) => {
    event.preventDefault();

    mutate()
      .unwrap()
      .then(() => {
        handleOpenSuccessSnackbarWithMessage(
          `Success! Your password reset email has been sent to ${user?.email}.`
        );
        handleClose();
      })
      .catch(() => {});
  };

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Snackbar {...successSnackbarProps} />
      <Dialog onClose={handleClose} open={isOpen}>
        <DialogTitle
          closeTestId={RESET_PASSWORD_CLOSE_ICON_TEST_ID}
          handleClose={handleClose}
          title={RESET_PASSWORD_DIALOG_TITLE}
        />
        {isError && (
          <FormSubmissionErrorAlert
            errorText={RESET_PASSWORD_ERROR_TEXT}
            formId={formId}
            resetMutate={reset}
          />
        )}
        <DialogContent>
          <Typography component="div" variant="body1">
            Reset your password in a few easy steps:
            <ol>
              <li>Click &apos;Request Reset&apos; below.</li>
              <li>We&apos;ll send an email to {user?.email}</li>
              <li>Open the email and click the reset link inside.</li>
              <li>
                You&apos;ll be redirected to a secure page to create a new
                password.
              </li>
            </ol>
            Make sure to choose a strong, unique password. If you don&apos;t
            receive the email within a few minutes, check your spam folder.
          </Typography>
        </DialogContent>
        <DialogActions>
          <form id={formId} onSubmit={handleResetPassword}>
            <Button isLoading={isLoading} type="submit" variant="contained">
              {RESET_PASSWORD_BUTTON_TEXT}
            </Button>
          </form>
        </DialogActions>
      </Dialog>
    </>
  );
};

ResetPassword.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ResetPassword;
