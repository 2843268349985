import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { IconButton as MuiIconButton } from "@mui/material";
import styled from "styled-components";
import useHasAccess from "../hooks/use-has-access";
import AccessTooltip from "./Access-Tooltip";

const Div = styled.div``;

const IconButton = ({ accessLevelsRequired, ...rest }) => {
  const { noAccess, noAccessMessage } = useHasAccess(accessLevelsRequired);

  const DivOrFragment = noAccessMessage ? Div : Fragment;

  return (
    <AccessTooltip title={noAccessMessage}>
      <DivOrFragment>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <MuiIconButton disabled={noAccess} {...rest} />
      </DivOrFragment>
    </AccessTooltip>
  );
};

IconButton.propTypes = {
  accessLevelsRequired: PropTypes.arrayOf(PropTypes.string),
};

IconButton.defaultProps = {
  accessLevelsRequired: undefined,
};

export default IconButton;
