import React, {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import intersection from "lodash.intersection";
import { useDispatch, useSelector } from "react-redux";
import useResizeObserver from "use-resize-observer";
import PropTypes from "prop-types";
import { VariableSizeList } from "react-window";
import { Button, Stack, TableSortLabel } from "@mui/material";
import styled from "styled-components";
import { ellipsis, widthCss } from "../../utils/styles";
import {
  actionFocusSolid,
  actionHoverSolid,
  actionSelectedSolid,
  backgroundColor,
  common,
  tableBorder,
  text,
  transitions,
  typography,
} from "../../theme";
import TooltipOrFragment from "../Tooltip-Or-Fragment";
import { ASCENDING_KEY, DESCENDING_KEY } from "../../constants/sorting";
import ColumnConfiguration from "./Column-Configuration";
import { Skeleton } from "../Skeleton";
import { VIRTUALIZED_TABLE_HEAD_CELL_TEST_ID } from "./constants";
import FetchError from "../Fetch-Error";
import CombinedColumnConfiguration, {
  actionsColumnWidth,
  ActionsIconButton,
  TableRowActionsMenu,
} from "./Combined-Column-Configuration";
import usePopover from "../../hooks/use-popover";
import ColumnConfigurationButton from "./Column-Configuration-Button";
import refPropType from "../../prop-types/ref";
import usePrevious from "../../hooks/use-previous";
import NoResults from "./No-Results";

const VirtualizedTableContext = createContext("virtualized-table");

const { body2 } = typography;

export const itemSize = 53;

const TableRow = styled(Stack).attrs(() => ({ direction: "row" }))`
  height: ${itemSize}px;

  background-color: ${common.white};

  :not(:hover, :focus-visible, :focus-within) {
    ${ActionsIconButton} {
      opacity: 0;
    }
  }

  ${({ $hovered, $selected }) =>
    $hovered &&
    !$selected &&
    `background-color: ${actionHoverSolid};`} /* stylelint-disable-line */

  :hover {
    ${({ $shouldDisableHoverTableRow }) =>
      !$shouldDisableHoverTableRow && `background-color: ${actionHoverSolid};`}

    ${({ $selected }) =>
      $selected && `background-color: ${actionSelectedSolid};`}
  }

  ${({ $selected }) =>
    $selected &&
    `background-color: ${actionSelectedSolid};`} /* stylelint-disable-line */

  ${({ $highlighted }) =>
    $highlighted &&
    `
    background-color: ${actionSelectedSolid};
  `}

  ${({ $shouldTransitionBackground }) =>
    $shouldTransitionBackground &&
    `transition: background-color 8s ease-out;`} /* stylelint-disable-line */

  :focus-visible {
    background-color: ${actionFocusSolid};
    outline: none;
  }
`;

const paddingMap = {
  avatarChip: "padding-bottom: 10.5px; padding-top: 10.5px;",
  button: "padding-bottom: 8.25px; padding-top: 8.25px;",
  checkbox:
    "padding-left: 4px; padding-right: 0; padding-bottom: 6.5px; padding-top: 6.5px;",
  costYear: "padding-top: 12px; padding-bottom: 7px;",
  iconAndString: "padding-bottom: 0; padding-left: 8px; padding-top: 0;",
  icon: "padding-top: 6.5px; padding-bottom: 6.5px;",
};

const alignMap = {
  center: "center",
  left: "left",
  right: "right",
};

const BaseTableCell = styled.div`
  border-bottom: ${tableBorder};
  padding: 16px;
  padding-right: 20px;

  color: ${text.primary};

  font-size: ${body2.fontSize};
  letter-spacing: ${body2.letterSpacing};
  line-height: ${body2.lineHeight};

  ${({ $align }) => `text-align: ${alignMap[$align] || alignMap.left};`}

  ${({ $padding }) => paddingMap[$padding] || ""}

  ${({ $shouldRenderRightBorder }) =>
    $shouldRenderRightBorder && `border-right: ${tableBorder};`}

  ${widthCss}

  ${({ $isLeftPinned, $left }) =>
    $isLeftPinned && `position: sticky; left: ${$left}; z-index: 3;`}
`;

const TableCell = styled(BaseTableCell)`
  background-color: inherit;

  font-weight: ${body2.fontWeight};

  ${({ $disabled }) => $disabled && `color: ${text.disabled};`}

  ${ellipsis}
`;

const UnsortableLabelWrapper = styled.div`
  ${({ $shouldEllipsis }) => $shouldEllipsis && ellipsis}

  width: 100%;
`;

const MoreTableCell = styled(TableCell)`
  color: ${text.disabled};
`;

const filterHeaderHeightValue = 64;
const filterHeaderHeight = `${filterHeaderHeightValue}px`;

const TableHeadRow = styled(Stack).attrs(() => ({ direction: "row" }))`
  background-color: ${({ $shouldUseWhiteTableHeadRowBackground }) =>
    $shouldUseWhiteTableHeadRowBackground ? common.white : backgroundColor};
  height: ${itemSize}px;
  position: sticky;
  top: 0;
  width: fit-content;
  z-index: 4;

  ${({ $isFilterHeaderVisible }) =>
    `top: ${$isFilterHeaderVisible ? filterHeaderHeight : 0};`}
  transition: top ${transitions.duration.standard / 1000}s ease-in-out;
`;

const TableHeadCell = styled(BaseTableCell)`
  background-color: inherit;

  font-weight: ${typography.fontWeightMedium};

  white-space: nowrap;

  ${({ $shouldRemoveRightPadding }) =>
    $shouldRemoveRightPadding && "padding-right: 0;"}

  ${({ $shouldEllipsis }) => $shouldEllipsis && ellipsis}
`;

const MoreButtonHeadCell = styled(TableHeadCell)`
  display: flex;
  align-items: center;
`;

/* stylelint-disable value-no-vendor-prefix */

const StyledTableSortLabel = styled(TableSortLabel)`
  .MuiTableSortLabel-icon {
    margin-right: 0;
  }
`;

/* stylelint-enable value-no-vendor-prefix */

const MoreButton = styled(Button)`
  color: ${text.disabled};
  text-transform: unset;
  white-space: nowrap;
`;

const FilterHeader = styled(Stack).attrs(() => ({
  alignItems: "flex-start",
  direction: "row",
  justifyContent: "space-between",
  spacing: 3,
}))`
  background-color: ${backgroundColor};
  box-sizing: border-box;
  height: ${filterHeaderHeight};
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  position: sticky;
  left: 0;
  transition: top ${transitions.duration.standard / 1000}s ease-in-out;
  top: ${({ $isFilterHeaderVisible }) =>
    $isFilterHeaderVisible ? 0 : `-${filterHeaderHeight}`};
  z-index: 5;
`;

const moreColumnWidth = 94;

const TableContainer = styled.div``;

const RenderRow = ({ index, style }) => {
  if (index === 0) {
    return null;
  }

  const { width: styleWidth, ...styleWithoutWidth } = style;

  const {
    isItemHoveredSelector,
    isItemSelectedSelector,
    checkIsItemDisabled,
    checkIsItemHighlighted,
    columns,
    createRowBlurHandler,
    createRowClickHandler,
    createRowFocusHandler,
    createRowKeyDownHandler,
    createRowMouseEnterHandler,
    createRowMouseLeaveHandler,
    createRowTestId,
    extraPixels,
    getLeftForPinnedColumn,
    items,
    rightBorderIndex,
    rowActionsMenuProps,
    RowActionsMenu,
    rowActionsTooltip,
    shouldCombineColumnConfiguration,
    shouldDisableHoverTableRow,
    shouldRenderColumn,
    shouldRenderExpansionColumn,
    widthScalingColumnId,
  } = useContext(VirtualizedTableContext);

  const item = items?.[index - 1];

  const isItemSelected = useSelector((state) =>
    isItemSelectedSelector?.(state, item)
  );
  const isItemHovered = useSelector((state) =>
    isItemHoveredSelector?.(state, item)
  );
  const isItemDisabled = checkIsItemDisabled?.(item);
  const isItemHighlighted = checkIsItemHighlighted?.(item);

  return (
    <TableRow
      $hovered={isItemHovered}
      $selected={isItemSelected}
      $highlighted={isItemHighlighted}
      $shouldTransitionBackground={checkIsItemHighlighted}
      $shouldDisableHoverTableRow={shouldDisableHoverTableRow}
      data-testid={createRowTestId(item)}
      onBlur={createRowBlurHandler?.(item)}
      onClick={createRowClickHandler?.(item)}
      onFocus={createRowFocusHandler?.(item)}
      onKeyDown={createRowKeyDownHandler?.(item)}
      onMouseEnter={createRowMouseEnterHandler?.(item)}
      onMouseLeave={createRowMouseLeaveHandler?.()}
      style={styleWithoutWidth}
      tabIndex={createRowKeyDownHandler ? 0 : undefined}
    >
      {columns?.map(
        (
          { align, id, isLeftPinned, padding, BodyCell, renderTooltip, width },
          columnIndex
        ) =>
          shouldRenderColumn(columnIndex) && (
            <TooltipOrFragment
              disableInteractive
              key={id}
              title={renderTooltip && renderTooltip(item)}
            >
              <TableCell
                $align={align}
                $disabled={isItemDisabled}
                $isLeftPinned={isLeftPinned}
                $left={isLeftPinned && getLeftForPinnedColumn(columnIndex)}
                $padding={padding}
                $shouldRenderRightBorder={rightBorderIndex === columnIndex}
                $width={
                  id === widthScalingColumnId
                    ? `${width + extraPixels}px`
                    : `${width}px`
                }
              >
                {BodyCell ? (
                  <BodyCell
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...item}
                  />
                ) : (
                  item[id]
                )}
              </TableCell>
            </TooltipOrFragment>
          )
      )}
      {shouldCombineColumnConfiguration && (
        <TableRowActionsMenu
          actionsMenuProps={{
            ...rowActionsMenuProps,
            item,
          }}
          ActionsMenu={RowActionsMenu}
          rowActionsTooltip={rowActionsTooltip}
        />
      )}
      {!shouldCombineColumnConfiguration && shouldRenderExpansionColumn && (
        <MoreTableCell $align="center" $width={`${moreColumnWidth}px`}>
          ...
        </MoreTableCell>
      )}
    </TableRow>
  );
};

RenderRow.propTypes = {
  index: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
};

const innerElementType = forwardRef(({ children, ...rest }, ref) => {
  const {
    allColumns,
    columnConfiguration,
    columnConfigurationTitle,
    columns,
    createSortHandler,
    extraPixels,
    filterContent,
    getLeftForPinnedColumn,
    innerContainerWidth,
    isFilterHeaderVisible,
    isLoading,
    isNoResultsFromSearch,
    numberOfHiddenColumns,
    rightBorderIndex,
    setShouldExpandColumns,
    shouldCombineColumnConfiguration,
    shouldRenderColumn,
    shouldRenderExpansionColumn,
    shouldRenderHiddenColumns,
    shouldUseWhiteTableHeadRowBackground,
    sort,
    updateColumnConfiguration,
    widthScalingColumnId,
  } = useContext(VirtualizedTableContext);

  const {
    handleClickOpenPopover: handleClickOpenColumnConfiguration,
    handleClosePopover: handleCloseColumnConfiguration,
    handleOpenPopoverWithRef: handleOpenColumnConfigurationWithRef,
    isPopoverOpen: isColumnConfigurationOpen,
    popoverAnchor: columnConfigurationAnchor,
  } = usePopover();

  const handleExpandColumns = () => setShouldExpandColumns(true);

  return (
    <>
      <ColumnConfiguration
        columnConfiguration={columnConfiguration}
        columns={allColumns}
        containerWidth={innerContainerWidth}
        extraColumnWidth={shouldRenderExpansionColumn ? moreColumnWidth : 0}
        handleClose={handleCloseColumnConfiguration}
        popoverAnchor={columnConfigurationAnchor}
        isOpen={isColumnConfigurationOpen}
        shouldRenderHiddenColumns={shouldRenderHiddenColumns}
        title={columnConfigurationTitle}
        updateColumnConfiguration={updateColumnConfiguration}
      />
      {!shouldCombineColumnConfiguration && (
        <FilterHeader
          $isFilterHeaderVisible={isFilterHeaderVisible}
          $width={`${innerContainerWidth}px`}
        >
          {filterContent}
          <ColumnConfigurationButton
            isLoading={isLoading}
            handleOpenColumnConfiguration={handleClickOpenColumnConfiguration}
          />
        </FilterHeader>
      )}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <div ref={ref} {...rest}>
        <TableHeadRow
          $isFilterHeaderVisible={isFilterHeaderVisible}
          $shouldUseWhiteTableHeadRowBackground={
            shouldUseWhiteTableHeadRowBackground
          }
        >
          {isLoading && (
            <TableHeadCell $width={`${innerContainerWidth}px`}>
              <Skeleton width="100%" />
            </TableHeadCell>
          )}
          {!isLoading &&
            columns?.map(
              (
                {
                  align,
                  headerPadding,
                  id,
                  isLeftPinned,
                  isSortDisabled,
                  label,
                  renderHeader,
                  renderLabel,
                  shouldEllipsisHeader,
                  tooltip,
                  width,
                },
                index
              ) => {
                const isSortActive = sort.id === id;

                let content;
                let shouldRemoveRightPadding;

                if (!shouldRenderColumn(index)) {
                  return null;
                }

                if (renderHeader) {
                  content = renderHeader();
                } else if (isSortDisabled) {
                  content = (
                    <UnsortableLabelWrapper
                      $shouldEllipsis={shouldEllipsisHeader}
                    >
                      {label}
                    </UnsortableLabelWrapper>
                  );
                } else {
                  shouldRemoveRightPadding = true;
                  content = (
                    <StyledTableSortLabel
                      active={isSortActive}
                      direction={isSortActive ? sort.order : ASCENDING_KEY}
                      onClick={createSortHandler(id)}
                    >
                      {renderLabel ? renderLabel() : label}
                    </StyledTableSortLabel>
                  );
                }

                return (
                  <TableHeadCell
                    $align={align}
                    $isLeftPinned={isLeftPinned}
                    $left={isLeftPinned && getLeftForPinnedColumn(index)}
                    $padding={headerPadding}
                    $shouldRemoveRightPadding={shouldRemoveRightPadding}
                    $shouldRenderRightBorder={rightBorderIndex === index}
                    $shouldEllipsis={shouldEllipsisHeader}
                    $width={
                      id === widthScalingColumnId
                        ? `${width + extraPixels}px`
                        : `${width}px`
                    }
                    data-testid={VIRTUALIZED_TABLE_HEAD_CELL_TEST_ID}
                    key={id}
                  >
                    <TooltipOrFragment
                      disableInteractive
                      placement="top"
                      title={tooltip}
                    >
                      {content}
                    </TooltipOrFragment>
                  </TableHeadCell>
                );
              }
            )}
          {!isLoading && shouldCombineColumnConfiguration && (
            <CombinedColumnConfiguration
              handleExpandColumns={handleExpandColumns}
              handleOpenColumnConfiguration={
                handleOpenColumnConfigurationWithRef
              }
              numberOfHiddenColumns={numberOfHiddenColumns}
              shouldAllowColumnExpansion={shouldRenderExpansionColumn}
            />
          )}
          {!isLoading &&
          !shouldCombineColumnConfiguration &&
          shouldRenderExpansionColumn ? (
            <MoreButtonHeadCell
              $padding="button"
              $width={`${moreColumnWidth}px`}
            >
              <MoreButton
                onClick={handleExpandColumns}
              >{`+${numberOfHiddenColumns} More`}</MoreButton>
            </MoreButtonHeadCell>
          ) : null}
        </TableHeadRow>
        {isNoResultsFromSearch && (
          <NoResults>No results from applied filters</NoResults>
        )}
        {isLoading
          ? new Array(5).fill().map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={index}>
                <TableCell $width={`${innerContainerWidth}px`}>
                  <Skeleton width="100%" />
                </TableCell>
              </TableRow>
            ))
          : children}
      </div>
    </>
  );
});

innerElementType.propTypes = {
  children: PropTypes.node.isRequired,
};

const VirtualizedTable = (props) => {
  const {
    isItemHoveredSelector,
    isItemSelectedSelector,
    checkIsItemDisabled,
    checkIsItemHighlighted,
    columnConfigurationLocalStorageKey,
    columnConfigurationTitle,
    columns: allColumns,
    containerHeight,
    containerWidth,
    createRowBlurHandler,
    createRowClickHandler,
    createRowFocusHandler,
    createRowKeyDownHandler,
    createRowMouseEnterHandler,
    createRowMouseLeaveHandler,
    createRowTestId,
    errorMessage,
    filterContent,
    isError,
    itemKey,
    isLoading,
    isNoResultsFromSearch,
    onScroll,
    refetch,
    resetTableSlice,
    rowActionsMenuProps,
    RowActionsMenu,
    rowActionsTooltip,
    setIdToSortMap,
    setSort,
    shouldCombineColumnConfiguration,
    shouldDisableHoverTableRow,
    shouldUseWhiteTableHeadRowBackground,
    sortedItemsSelector,
    sortSelector,
    tableRef,
    widthScalingColumnId,
  } = props;

  const dispatch = useDispatch();

  const previousAllColumns = usePrevious(allColumns);

  useEffect(() => () => dispatch(resetTableSlice()), []);

  useEffect(() => {
    if (!previousAllColumns && allColumns) {
      dispatch(
        setIdToSortMap(
          allColumns?.reduce(
            (acc, { id, sortCompare }) => ({
              ...acc,
              [id]: sortCompare,
            }),
            {}
          )
        )
      );
    }
  }, [allColumns]);

  const {
    ref: innerContainerRef,
    width: innerContainerWidth = containerWidth,
  } = useResizeObserver();

  const isCompactMode = innerContainerWidth < 650;

  const scrollPositionRef = useRef(0);

  const [isFilterHeaderVisible, setIsFilterHeaderVisible] = useState(
    !shouldCombineColumnConfiguration
  );
  const [rawColumnConfiguration, setColumnConfiguration] = useState(null);

  const columnConfiguration = useMemo(() => {
    const intersectingIds = intersection(
      rawColumnConfiguration?.map(({ id }) => id),
      allColumns?.map(({ id }) => id)
    );

    const areColumnsMissing =
      intersectingIds.length !== allColumns?.length ||
      intersectingIds.length !== rawColumnConfiguration.length;

    return areColumnsMissing ? undefined : rawColumnConfiguration;
  }, [allColumns, rawColumnConfiguration]);

  const getLeftForPinnedColumn = (index) =>
    `${allColumns
      .slice(0, index)
      .reduce((acc, { width }) => acc + width, 0)}px`;

  const updateColumnConfiguration = (newColumnConfiguration) => {
    setColumnConfiguration(newColumnConfiguration);
    localStorage.setItem(
      columnConfigurationLocalStorageKey,
      JSON.stringify(newColumnConfiguration)
    );
  };

  const filterByColumnConfiguration = (configuration) =>
    configuration
      ?.filter(({ shouldShow }) => !!shouldShow)
      .map(({ id }) => allColumns.find((column) => column.id === id));

  useEffect(() => {
    const localStorageColumnConfiguration = localStorage.getItem(
      columnConfigurationLocalStorageKey
    );

    if (localStorageColumnConfiguration && allColumns?.length) {
      const parsedColumnConfiguration = JSON.parse(
        localStorageColumnConfiguration
      );

      setColumnConfiguration(parsedColumnConfiguration);
    }
  }, [allColumns]);

  const columns = columnConfiguration
    ? filterByColumnConfiguration(columnConfiguration)
    : allColumns;

  const handleScroll = (event) => {
    onScroll?.(event);

    if (shouldCombineColumnConfiguration) {
      return;
    }

    const { scrollDirection, scrollOffset } = event;
    const isScrollingDown = scrollDirection === "forward";
    const newIsFilterHeaderVisible =
      scrollOffset < filterHeaderHeightValue || !isScrollingDown;

    if (isFilterHeaderVisible !== newIsFilterHeaderVisible) {
      setIsFilterHeaderVisible(newIsFilterHeaderVisible);
    }
    scrollPositionRef.current = scrollOffset;
  };

  const [shouldExpandColumns, setShouldExpandColumns] = useState(false);

  const minimumColumnWidth = columns?.reduce(
    (acc, { width }) => acc + width,
    0
  );

  const canRenderAllColumns = minimumColumnWidth < innerContainerWidth;

  const shouldRenderExpansionColumn =
    !canRenderAllColumns && !shouldExpandColumns && !isCompactMode;

  let startingWidth = 0;

  if (shouldCombineColumnConfiguration) {
    startingWidth = actionsColumnWidth;
  } else if (shouldRenderExpansionColumn) {
    startingWidth = moreColumnWidth;
  }

  let accumulatedWidth = startingWidth;

  const shouldRenderArray = canRenderAllColumns
    ? columns?.map(() => true)
    : columns?.map((column) => {
        const { width } = column;

        accumulatedWidth += width;

        return accumulatedWidth < innerContainerWidth;
      });

  const totalRenderedWidth = columns?.reduce(
    (acc, { width }, index) => {
      if (shouldRenderArray[index] || (shouldExpandColumns && !isCompactMode)) {
        return acc + width;
      }

      return acc;
    },
    canRenderAllColumns ? 0 : startingWidth
  );

  const extraPixels =
    totalRenderedWidth < innerContainerWidth
      ? innerContainerWidth - totalRenderedWidth
      : 0;

  const numberOfHiddenColumns = shouldRenderArray?.filter(
    (current) => !current
  )?.length;

  const shouldRenderHiddenColumns =
    !!(numberOfHiddenColumns && !shouldExpandColumns) || isCompactMode;

  const shouldRenderRightBorders =
    !canRenderAllColumns && shouldExpandColumns && !isCompactMode;

  const rightBorderIndex =
    shouldRenderRightBorders &&
    columns.map(({ isLeftPinned }) => !!isLeftPinned).lastIndexOf(true);

  const shouldRenderColumn = (index) =>
    !shouldRenderHiddenColumns || shouldRenderArray[index];

  const sort = useSelector(sortSelector);

  const createSortHandler = (id) => () => {
    dispatch(
      setSort({
        id,
        order:
          sort.order === ASCENDING_KEY && sort.id === id
            ? DESCENDING_KEY
            : ASCENDING_KEY,
      })
    );
  };

  const items = useSelector(sortedItemsSelector);

  if (isError) {
    return <FetchError errorMessage={errorMessage} refetch={refetch} />;
  }

  const getItemSize = (index) => {
    const additionalFilterHeaderHeight = shouldCombineColumnConfiguration
      ? 0
      : filterHeaderHeightValue;

    return index === 0 ? itemSize + additionalFilterHeaderHeight : itemSize;
  };

  return (
    <VirtualizedTableContext.Provider
      value={{
        allColumns,
        isItemHoveredSelector,
        isItemSelectedSelector,
        checkIsItemDisabled,
        checkIsItemHighlighted,
        columns,
        columnConfiguration,
        columnConfigurationTitle,
        containerHeight,
        createRowBlurHandler,
        createRowClickHandler,
        createRowFocusHandler,
        createRowKeyDownHandler,
        createRowMouseEnterHandler,
        createRowMouseLeaveHandler,
        createRowTestId,
        createSortHandler,
        extraPixels,
        filterContent,
        getLeftForPinnedColumn,
        isLoading,
        innerContainerWidth,
        isFilterHeaderVisible,
        items,
        isNoResultsFromSearch,
        numberOfHiddenColumns,
        rightBorderIndex,
        rowActionsMenuProps,
        RowActionsMenu,
        rowActionsTooltip,
        setShouldExpandColumns,
        shouldCombineColumnConfiguration,
        shouldDisableHoverTableRow,
        shouldUseWhiteTableHeadRowBackground,
        shouldRenderColumn,
        shouldRenderHiddenColumns,
        shouldRenderExpansionColumn,
        sort,
        updateColumnConfiguration,
        widthScalingColumnId,
      }}
    >
      <TableContainer>
        <VariableSizeList
          height={containerHeight || 0}
          innerElementType={innerElementType}
          itemCount={(items?.length || 0) + 1}
          itemKey={(index) => items?.[index]?.[itemKey] || 0}
          itemSize={getItemSize}
          onScroll={handleScroll}
          innerRef={innerContainerRef}
          ref={tableRef}
          style={{ overflowX: shouldRenderHiddenColumns ? "hidden" : "auto" }}
          width={containerWidth}
        >
          {RenderRow}
        </VariableSizeList>
      </TableContainer>
    </VirtualizedTableContext.Provider>
  );
};

VirtualizedTable.propTypes = {
  isItemHoveredSelector: PropTypes.func,
  isItemSelectedSelector: PropTypes.func,
  checkIsItemDisabled: PropTypes.func,
  checkIsItemHighlighted: PropTypes.func,
  columnConfigurationLocalStorageKey: PropTypes.string.isRequired,
  columnConfigurationTitle: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      align: PropTypes.string,
      headerPadding: PropTypes.string,
      id: PropTypes.string.isRequired,
      isLeftPinned: PropTypes.bool,
      isSortDisabled: PropTypes.bool,
      label: PropTypes.string,
      left: PropTypes.string,
      padding: PropTypes.string,
      BodyCell: PropTypes.func,
      renderHeader: PropTypes.func,
      renderTooltip: PropTypes.func,
      shouldEllipsisHeader: PropTypes.bool,
      sortCompare: PropTypes.string,
      tooltip: PropTypes.string,
      width: PropTypes.number.isRequired,
    })
  ),
  containerHeight: PropTypes.number.isRequired,
  containerWidth: PropTypes.number.isRequired,
  createRowBlurHandler: PropTypes.func,
  createRowClickHandler: PropTypes.func,
  createRowFocusHandler: PropTypes.func,
  createRowKeyDownHandler: PropTypes.func,
  createRowMouseEnterHandler: PropTypes.func,
  createRowMouseLeaveHandler: PropTypes.func,
  createRowTestId: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  filterContent: PropTypes.node,
  isError: PropTypes.bool.isRequired,
  itemKey: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isNoResultsFromSearch: PropTypes.bool.isRequired,
  onScroll: PropTypes.func,
  refetch: PropTypes.func.isRequired,
  resetTableSlice: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rowActionsMenuProps: PropTypes.object,
  RowActionsMenu: PropTypes.func,
  rowActionsTooltip: PropTypes.string,
  setIdToSortMap: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  shouldCombineColumnConfiguration: PropTypes.bool,
  shouldDisableHoverTableRow: PropTypes.bool,
  shouldUseWhiteTableHeadRowBackground: PropTypes.bool,
  sortedItemsSelector: PropTypes.func.isRequired,
  sortSelector: PropTypes.func.isRequired,
  tableRef: refPropType,
  widthScalingColumnId: PropTypes.string.isRequired,
};

VirtualizedTable.defaultProps = {
  isItemHoveredSelector: undefined,
  isItemSelectedSelector: undefined,
  checkIsItemDisabled: undefined,
  checkIsItemHighlighted: undefined,
  createRowBlurHandler: undefined,
  createRowClickHandler: undefined,
  createRowFocusHandler: undefined,
  createRowKeyDownHandler: undefined,
  createRowMouseEnterHandler: undefined,
  createRowMouseLeaveHandler: undefined,
  columns: undefined,
  filterContent: undefined,
  onScroll: undefined,
  rowActionsMenuProps: undefined,
  RowActionsMenu: undefined,
  rowActionsTooltip: undefined,
  shouldCombineColumnConfiguration: false,
  shouldDisableHoverTableRow: false,
  shouldUseWhiteTableHeadRowBackground: false,
  tableRef: undefined,
};

export default VirtualizedTable;
