import { createSelector, createSlice } from "@reduxjs/toolkit";

/* eslint-disable no-param-reassign */

const baseSlice = createSlice({
  name: "base",
  initialState: {},
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
  },
});

export const { setAccessToken } = baseSlice.actions;

export default baseSlice;

/* eslint-enable no-param-reassign */

const getBaseSlice = createSelector(
  (state) => state,
  (state) => state[baseSlice.name]
);

export const getAccessToken = createSelector(
  getBaseSlice,
  (slice) => slice.accessToken
);
