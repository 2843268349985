export const PROJECTS_TABLE_NAME_LABEL = "Name";
export const PROJECTS_TABLE_NAME_TOOLTIP = "Project name";

export const PROJECTS_TABLE_KEY_LABEL = "Project ID";
export const PROJECTS_TABLE_KEY_TOOLTIP =
  "Unique identifier to reference and differentiate projects";

export const PROJECTS_TABLE_TOTAL_COST_LABEL = "Cost at Year";
export const PROJECTS_TABLE_TOTAL_COST_TOOLTIP =
  "Total non-escalated project cost at the year it was valued";

export const PROJECTS_TABLE_PROJECTED_COST_LABEL = "Escalated Cost";
export const PROJECTS_TABLE_PROJECTED_COST_TOOLTIP =
  "Total escalated cost includes escalations from each year of project schedule";

export const PROJECTS_TABLE_DURATION_LABEL = "Duration";
export const PROJECTS_TABLE_DURATION_TOOLTIP = "Project duration in years";

export const PROJECTS_TABLE_START_LABEL = "Start";
export const PROJECTS_TABLE_START_TOOLTIP =
  "FYE when project is scheduled to start";

export const PROJECTS_TABLE_END_LABEL = "End";
export const PROJECTS_TABLE_END_TOOLTIP =
  "FYE when project is scheduled to end";

export const PROJECTS_TABLE_MAX_END_LABEL = "Max End";
export const PROJECTS_TABLE_MAX_END_TOOLTIP =
  "The latest completion date to fulfill regulatory and engineering requirements";

export const PROJECTS_TABLE_UTILITY_LABEL = "Utility";
export const PROJECTS_TABLE_UTILITY_TOOLTIP = "Utility associated with project";

export const PROJECTS_TABLE_APPROVAL_LABEL = "Approval";
export const PROJECTS_TABLE_APPROVAL_TOOLTIP =
  "Approved projects are part of the master plan";

export const PROJECTS_TABLE_INSTANCES_LABEL = "Instances";
export const PROJECTS_TABLE_INSTANCES_TOOLTIP =
  "Count of scenario project instances where a version of this project is in use";

export const PROJECTS_TABLE_LATEST_EDIT_LABEL = "Latest Edit";
export const PROJECTS_TABLE_LATEST_EDIT_TOOLTIP =
  "The last time the project was edited";

export const PROJECTS_TABLE_NOTES_LABEL = "Notes";
export const PROJECTS_TABLE_NOTES_TOOLTIP =
  "Include any additional information here that you might want to reference later";

export const PROJECTS_TABLE_TODAYS_COST_LABEL = "Today’s Cost";
export const PROJECTS_TABLE_TODAYS_COST_TOOLTIP =
  "Total project cost escalated into the value of today’s dollars";

export const PROJECTS_TABLE_ENABLED_LABEL = "On/Off";
export const PROJECTS_TABLE_ENABLED_TOOLTIP =
  "Hides projects from CIP. Toggle off to see scenario without a project.";

export const PROJECTS_VISIBILITY_OFF_ICON_TEST_ID = "visibility-off";
export const PROJECTS_VISIBILITY_ON_ICON_TEST_ID = "visibility-on";

export const PROJECTS_TABLE_SYNC_STATUS_LABEL = "Sync";
export const PROJECTS_TABLE_SYNC_STATUS_TOOLTIP =
  "Indicates changes have been made to a global project that is included in this scenario";

export const PROJECTS_TABLE_SYNC_PROJECT_BUTTON_TEXT = "UPDATE PROJECT";

export const PROJECTS_TABLE_SYNC_PROJECT_NOTIFICATION_BUTTON_TEXT =
  "UPDATE PROJECTS";

export const PROJECTS_SYNC_STATUS_UP_TO_DATE_ICON_TEST_ID = "sync-up-to-date";
export const PROJECTS_SYNC_STATUS_OUT_OF_DATE_ICON_TEST_ID = "sync-out-of-date";

export const PROJECTS_SYNC_PRESERVE_END_DATES_CHECKBOX_TEST_ID =
  "preserve-end-dates";

export const SCENARIO_PROJECTS_CONFIRM_SYNC_PROJECTS_BUTTON_TEST_ID =
  "confirm-sync-projects";
