import React, { Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { TextField as MuiTextField } from "@mui/material";
import useHasAccess from "../../hooks/use-has-access";
import AccessTooltip from "../Access-Tooltip";

const Div = styled.div``;

const TextField = React.forwardRef(({ accessLevelsRequired, ...rest }, ref) => {
  const { noAccess, noAccessMessage } = useHasAccess(accessLevelsRequired);

  const DivOrFragment = noAccessMessage ? Div : Fragment;

  return (
    <AccessTooltip title={noAccessMessage}>
      <DivOrFragment>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <MuiTextField disabled={noAccess} {...rest} ref={ref} />
      </DivOrFragment>
    </AccessTooltip>
  );
});

TextField.propTypes = {
  accessLevelsRequired: PropTypes.arrayOf(PropTypes.string),
};

TextField.defaultProps = {
  accessLevelsRequired: undefined,
};

export default TextField;
