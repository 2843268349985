import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Restore } from "@mui/icons-material";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import DialogTitle from "../Dialog-Title";
import Button from "../Button";
import { RESTORE_USER_BUTTON_TEXT, RESTORE_USER_ERROR_TEXT } from "./constants";
import FormSubmissionErrorAlert from "../forms/Form-Submission-Error-Alert";
import { displaySnackbarMessage } from "../../../layout/layout-slice";
import AccessLevelSelect from "../forms/Access-Level-Select";
import { USER_MANAGEMENT_ACCESS_LEVEL_LABEL_TEXT } from "../../../settings/users/constants";
import { createRequiredError } from "../../constants/validation";
import { useRestoreUserMutation } from "./api";
import { formatNumericDate } from "../../utils/date";
import { HeavyText } from "./shared";
import { getOrganizationName } from "../../api/admin";

const formId = "restore-user";

const RestoreUser = ({
  accessLevel,
  disabledAt,
  email,
  handleClose,
  name,
  isOpen,
  onActionSuccess,
  userId,
}) => {
  const dispatch = useDispatch();

  const organizationName = useSelector(getOrganizationName);

  const defaultValues = {
    accessLevel,
  };

  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm({
    defaultValues: {
      accessLevel,
    },
    mode: "onTouched",
  });

  useEffect(() => {
    if (isOpen) {
      resetForm(defaultValues);
    }
  }, [isOpen]);

  const [unwrappedMutate, { isError, isLoading, reset }] =
    useRestoreUserMutation();

  const restoreUser = (values) =>
    unwrappedMutate({ userId, ...values })
      .unwrap()
      .then(() => {
        handleClose();
        onActionSuccess?.();
        dispatch(
          displaySnackbarMessage(
            `Success. The account for ${name} at ${email} has been restored.`
          )
        );
      })
      .catch(() => {});

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <DialogTitle handleClose={handleClose} title="Restore Account" />
      {isError && (
        <FormSubmissionErrorAlert
          errorText={RESTORE_USER_ERROR_TEXT}
          formId={formId}
          resetMutate={reset}
        />
      )}
      <DialogContent>
        <Stack spacing={4}>
          <Typography variant="body1">
            <HeavyText>{name}</HeavyText>
            {`'s account was disabled on `}
            <HeavyText>{formatNumericDate(disabledAt)}</HeavyText>
            {`. If you restore their account, they will be able to sign into `}
            <HeavyText>{organizationName}</HeavyText>
            {` again. If needed, you may edit their access level below.`}
          </Typography>
          <Controller
            control={control}
            name="accessLevel"
            render={({
              field: { onBlur, onChange, value },
              fieldState: { error },
            }) => (
              <AccessLevelSelect
                error={!!error}
                fullWidth
                helperText={error?.message}
                label={USER_MANAGEMENT_ACCESS_LEVEL_LABEL_TEXT}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{
              required: createRequiredError(
                USER_MANAGEMENT_ACCESS_LEVEL_LABEL_TEXT
              ),
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <form id={formId} onSubmit={handleSubmit(restoreUser)}>
          <Button
            isLoading={isLoading}
            startIcon={<Restore />}
            type="submit"
            variant="contained"
          >
            {RESTORE_USER_BUTTON_TEXT}
          </Button>
        </form>
      </DialogActions>
    </Dialog>
  );
};

RestoreUser.propTypes = {
  accessLevel: PropTypes.string.isRequired,
  disabledAt: PropTypes.string,
  email: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onActionSuccess: PropTypes.func,
  userId: PropTypes.string.isRequired,
};

RestoreUser.defaultProps = {
  disabledAt: undefined,
  onActionSuccess: undefined,
};

export default RestoreUser;
