import React from "react";
import { useDispatch } from "react-redux";
import { CancelScheduleSend } from "@mui/icons-material";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import DialogTitle from "../Dialog-Title";
import Button from "../Button";
import {
  CANCEL_INVITATION_BUTTON_TEXT,
  CANCEL_INVITATION_ERROR_TEXT,
} from "./constants";
import { useCancelInviteMutation } from "./api";
import FormSubmissionErrorAlert from "../forms/Form-Submission-Error-Alert";
import { displaySnackbarMessage } from "../../../layout/layout-slice";
import { HeavyText } from "./shared";

const formId = "cancel-invitation";

const CancelInvitation = ({
  handleClose,
  email,
  invitationId,
  name,
  isOpen,
  onActionSuccess,
}) => {
  const dispatch = useDispatch();

  const [unwrappedMutate, { isError, isLoading, reset }] =
    useCancelInviteMutation();

  const cancelInvite = () =>
    unwrappedMutate({ invitationId })
      .unwrap()
      .then(() => {
        handleClose();
        onActionSuccess?.();
        dispatch(
          displaySnackbarMessage(
            `Success. The invitation to ${name} at ${email} has been cancelled.`
          )
        );
      })
      .catch(() => {});

  const handleSubmit = (event) => {
    event.preventDefault();

    cancelInvite();
  };

  return (
    <Dialog onClose={handleClose} open={isOpen}>
      <DialogTitle
        handleClose={handleClose}
        title="Cancel Pending Invitation"
      />
      {isError && (
        <FormSubmissionErrorAlert
          errorText={CANCEL_INVITATION_ERROR_TEXT}
          formId={formId}
          resetMutate={reset}
        />
      )}
      <DialogContent>
        <Typography variant="body1">
          <HeavyText>{name}</HeavyText>
          &nbsp;at&nbsp;
          <HeavyText>{email}</HeavyText>
          &nbsp;will no longer be able to use the link in their invitation email
          to activate their account.
        </Typography>
      </DialogContent>
      <DialogActions>
        <form id={formId} onSubmit={handleSubmit}>
          <Button
            color="error"
            isLoading={isLoading}
            startIcon={<CancelScheduleSend />}
            type="submit"
            variant="contained"
          >
            {CANCEL_INVITATION_BUTTON_TEXT}
          </Button>
        </form>
      </DialogActions>
    </Dialog>
  );
};

CancelInvitation.propTypes = {
  email: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  invitationId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onActionSuccess: PropTypes.func,
};

CancelInvitation.defaultProps = {
  invitationId: undefined,
  onActionSuccess: undefined,
};

export default CancelInvitation;
