import PropTypes from "prop-types";

const refPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.elementType }),
  // eslint-disable-next-line react/forbid-prop-types
  PropTypes.shape({ current: PropTypes.any }),
]);

export default refPropType;
