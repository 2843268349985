export const getCurrentYear = () => new Date().getFullYear();

export const calculateCurrentFYEYear = (fiscalYearStartMonth) => {
  const currentMonth = new Date().getMonth() + 1;

  const currentYear = getCurrentYear();

  let currentFYE;

  if (fiscalYearStartMonth === undefined) {
    // do nothing
  } else if (fiscalYearStartMonth === 1) {
    currentFYE = currentYear;
  } else if (currentMonth >= fiscalYearStartMonth) {
    currentFYE = currentYear + 1;
  } else {
    currentFYE = currentYear;
  }

  return currentFYE;
};
